import { createGlobalStyle } from "styled-components";
import { Colors } from "./Colors";
import "./../../res/fonts/fonts.css";

export default createGlobalStyle`
    * {
        box-sizing: border-box;
    }
    html {
        height: 100%;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeLegibility;
    }
    body {
        background-color: ${Colors.background};
        color: ${Colors.text};
        margin: 0;
        padding: 0;
        height: 100%;
        display: flex;
    }
    body, button, input, select, textarea {
        font-family: 'Lato';
    }
    input, button, textarea, :focus {
        outline: none;
    }
    p {
        line-height: 1.5;
    }
    #root {
        display: flex;
        flex: 1;
    }
    .ct-toast {
        border-radius: 12px;
    }
    .reverse {
        unicode-bidi: bidi-override;
        direction: rtl;
    }
    .mobile-only {
        display: none;
    }
    .non-mobile-only {
        display: inherit;
    }

    @media (max-width: 600px) {
        html, body {
            height: auto;
        }
        .mobile-only {
            display: inherit;
        }
        .non-mobile-only {
            display: none;
        }
    }
`;
