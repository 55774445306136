import React from "react";
import styled from "styled-components";
import { BaseSubscriptionHandlerComponent } from "../BaseSubscriptionHandlerComponent";
import DI from "../../di/DI";
import { TYPES } from "../../di/Types";
import { AmsterdamPharmacy, fullNameOf, User, ValidationPattern } from "../../domain/model";
import { RouteComponentProps } from "react-router-dom";
import { LoadingPanel } from "../appearance/LoadingPanel";
import { Colors } from "../appearance/Colors";
import * as RxOperators from "rxjs/operators";
import { PrepOrderStartViewModel } from "./PrepOrderStartViewModel";
import i18n from "i18next";
import { SegmentedControl } from "../appearance/SegmentedControl";
import { OrderPickupPlace, OrderReceivingType } from "../../infrastructure/api/models";
import { RoundButton } from "../appearance/RoundButton";
import { GroupRadioButton } from "../appearance/GroupRadioButton";
import { RoundInput } from "../appearance/RoundInput";
import { showError } from "../../utils/MessageUtils";
import { Path } from "../App";
import { PREP_DELIVERY_PRICE, PREP_PILLS_PRICE } from "../../domain/repositories";

const Container = styled.div`
    display: block;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    width: 97%;
    max-width: 480px;
    margin-top: 10px;
`;
const ContentContainer = styled.form`
    display: flex;
    background-color: ${Colors.lightBackground};
    border-radius: 12px;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px;
    margin-top: 16px;
    margin-bottom: 100px;
    > h3 {
        font-size: 20px;
        margin-top: 0px;
        margin-bottom: 24px;
    }
    > h4 {
        font-size: 16px;
        margin: 24px 0 12px 0;
    }
    > button {
        margin-top: 16px;
    }
    > p {
        margin: 0;
    }
`;
const PriceContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${Colors.homeBackground};
    border-radius: 12px;
    padding: 14px;
    width: 100%;
    h4 {
        margin: 0;
    }
    > span {
        display: block;
        margin: 13px 0;
        width: 100%;
        height: 1px;
        background-color: ${Colors.lightBackground};
    }
    > div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
`;
const AddressContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 16px 0 32px 0;
    > div {
        margin-bottom: 16px;
    }
`;
const StreetInputsContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    div:first-child {
        flex: 2;
        margin-right: 12px;
    }
    div:last-child {
        flex: 1;
    }
`;
const DeliveryPriceNote = styled.p`
    background-color: #ffc670;
    border-radius: 12px;
    padding: 8px;
    margin: 16px 0 10px 0 !important;
`;

interface Props extends RouteComponentProps<{ accessToken: string; eventId: string }> {}
interface State {
    user: User | null;
    receivingType: OrderReceivingType;
    pickupPlace: OrderPickupPlace | null;
    numberOfPills: number | null;
    price: number | null;
    deliveryPrice: number | null;
    fieldErrors: (keyof State)[];
    recipientName: string;
    streetName: string;
    houseNumber: string;
    postalCode: string;
    town: string;
    isSubmitting: boolean;
    isLoading: boolean;
}

export class PrepOrderStart extends BaseSubscriptionHandlerComponent<Props, State> {
    // Properties

    private readonly viewModel: PrepOrderStartViewModel = DI.get(TYPES.PrepOrderStartViewModel);

    private get isPaymentOnline(): boolean {
        return (
            this.state.receivingType == OrderReceivingType.DELIVERY ||
            this.state.pickupPlace == OrderPickupPlace.PHARMACY
        );
    }

    // Public functions

    public constructor(props: Props) {
        super(props);
        this.state = {
            user: null,
            receivingType: OrderReceivingType.PICKUP,
            pickupPlace: null,
            numberOfPills: null,
            price: null,
            deliveryPrice: null,
            fieldErrors: [],
            recipientName: "",
            streetName: "",
            houseNumber: "",
            postalCode: "",
            town: "",
            isSubmitting: false,
            isLoading: false,
        };
    }
    public componentDidMount(): void {
        const fetchUserAndEvent = this.viewModel
            .fetchUserAndEvent(this.props.match.params.accessToken, this.props.match.params.eventId)
            .pipe(RxOperators.tap({ error: () => this.setState({ isLoading: false }) }));

        this.assignToState(fetchUserAndEvent, ([user, event]) => ({
            isLoading: false,

            user,
            pickupPlace: null,
            recipientName: fullNameOf(user),
            streetName: user.street || "",
            houseNumber: user.streetNumber || "",
            postalCode: user.postalCode || "",
            town: user.city || "",

            numberOfPills: event.numberOfPills,
            price: event.numberOfPills && event.numberOfPills * PREP_PILLS_PRICE,
            deliveryPrice: PREP_DELIVERY_PRICE,
        }));
    }

    public render(): React.ReactNode {
        return (
            <Container>
                {this.state.isLoading && <LoadingPanel />}
                {this.state.user && (
                    <ContentContainer>
                        <h3>{i18n.t("prepOrder.title") as string}</h3>
                        <PriceContainer>
                            <h4>{i18n.t("prepOrder.description") as string}</h4>
                            <span />
                            <div>
                                <label>
                                    {i18n.t("prepOrder.numberOfPills", { pills: this.state.numberOfPills }) as string}
                                </label>
                                <strong>
                                    {
                                        i18n.t("prepOrder.price", {
                                            price: this.formatPrice(this.state.price!),
                                        }) as string
                                    }
                                </strong>
                            </div>
                        </PriceContainer>
                        <h4>{i18n.t("prepOrder.receiveTypeTitle") as string}</h4>
                        <SegmentedControl
                            selectedOption={this.state.receivingType}
                            displayValues={[i18n.t("prepOrder.pickingUp"), i18n.t("prepOrder.delivering")]}
                            onChange={(receivingType) => this.setState({ receivingType })}
                            options={[OrderReceivingType.PICKUP, OrderReceivingType.DELIVERY]}
                        />
                        {this.state.receivingType == OrderReceivingType.DELIVERY && (
                            <DeliveryPriceNote
                                dangerouslySetInnerHTML={{
                                    __html: i18n.t("prepOrder.deliveryPriceNote", {
                                        price: this.formatPrice(this.state.deliveryPrice!),
                                    }),
                                }}
                            />
                        )}
                        {this.state.receivingType == OrderReceivingType.DELIVERY
                            ? this.renderAddress()
                            : this.renderPickupLocation()}
                        <p
                            dangerouslySetInnerHTML={{
                                __html: i18n.t(
                                    this.isPaymentOnline
                                        ? "prepOrder.orderDescriptionOnline"
                                        : "prepOrder.orderDescriptionOffline",
                                    {
                                        price: this.formatPrice(
                                            this.state.price! +
                                                (this.state.receivingType == OrderReceivingType.DELIVERY
                                                    ? this.state.deliveryPrice!
                                                    : 0),
                                        ),
                                    },
                                ),
                            }}
                        />
                        <RoundButton
                            fillWidth={true}
                            isLoading={this.state.isSubmitting}
                            onClick={this.submit.bind(this)}
                            text={i18n.t(this.isPaymentOnline ? "prepOrder.toPayment" : "prepOrder.placeOrder")}
                        />
                    </ContentContainer>
                )}
            </Container>
        );
    }

    // Private functions

    private renderPickupLocation(): React.ReactNode {
        return (
            <div>
                <h4>{i18n.t("prepOrder.locationChooseTitle") as string}</h4>
                <GroupRadioButton
                    direction={"column"}
                    selectedOption={this.state.pickupPlace}
                    displayValues={["GGD " + this.state.user!.region!.name, AmsterdamPharmacy.name]}
                    displayValueSubtitles={[this.state.user!.region!.address, AmsterdamPharmacy.address]}
                    onChange={(pickupPlace) => this.setState({ pickupPlace })}
                    options={[OrderPickupPlace.GGD, OrderPickupPlace.PHARMACY]}
                />
            </div>
        );
    }
    private renderAddress(): React.ReactNode {
        return (
            <AddressContainer>
                {this.renderInput(i18n.t("prepOrder.fullName"), "recipientName")}
                <p>{i18n.t("prepOrder.addressTitle") as string}</p>
                <StreetInputsContainer>
                    {this.renderInput(i18n.t("prepOrder.streetAndHouseNumber"), "streetName")}
                    {this.renderInput("", "houseNumber")}
                </StreetInputsContainer>
                {this.renderInput(i18n.t("prepOrder.postalCode"), "postalCode", false, "1111 AA")}
                {this.renderInput(i18n.t("prepOrder.town"), "town")}
            </AddressContainer>
        );
    }

    private submit(e: React.MouseEvent): void {
        e.preventDefault();
        if (!this.validateForm()) {
            if (this.state.fieldErrors.length > 0) {
                showError(new Error(i18n.t("common.validationFailed")));
            }
            return;
        }
        this.setState({ isSubmitting: true });
        const subscription = this.viewModel
            .placeOrder(
                this.props.match.params.eventId,
                this.state.receivingType,
                this.state.pickupPlace,
                this.state.recipientName,
                this.state.streetName,
                this.state.houseNumber,
                this.state.postalCode,
                this.state.town,
            )
            .subscribe({
                next: (url) => {
                    if (url != null) {
                        window.location.href = url;
                    } else {
                        this.props.history.push(Path.public.order.successResult);
                    }
                },
                error: (error) => {
                    showError(error);
                    this.setState({ isSubmitting: false });
                },
            });
        this.collectSubscription(subscription);
    }

    private renderInput<K extends keyof State>(
        title: string,
        stateKey: K,
        fillWidth = true,
        placeholder = "",
    ): React.ReactNode {
        return (
            <RoundInput
                placeholder={placeholder}
                errorOccurred={this.state.fieldErrors.includes(stateKey)}
                text={this.state[stateKey] as string | null}
                onTextChange={(value) => this.updateFields({ [stateKey]: value } as Pick<State, K>)}
                fillWidth={fillWidth}
                title={title}
            />
        );
    }

    private updateFields<K extends keyof State>(update: Pick<State, K>): void {
        this.setState({ fieldErrors: this.state.fieldErrors.filter((key) => !Object.keys(update).includes(key)) });
        this.setState(update);
    }

    private validateForm(): boolean {
        const fieldErrors: (keyof State)[] = [];
        if (this.state.receivingType == OrderReceivingType.DELIVERY) {
            if (!ValidationPattern.notBlank.test(this.state.recipientName || "")) {
                fieldErrors.push("recipientName");
            }
            if (!ValidationPattern.notBlank.test(this.state.streetName || "")) {
                fieldErrors.push("streetName");
            }
            if (!ValidationPattern.notBlank.test(this.state.houseNumber || "")) {
                fieldErrors.push("houseNumber");
            }
            if (!ValidationPattern.postalCode.test(this.state.postalCode || "")) {
                fieldErrors.push("postalCode");
            }
            if (!ValidationPattern.notBlank.test(this.state.town || "")) {
                fieldErrors.push("town");
            }
        } else {
            if (this.state.pickupPlace == null) {
                showError(Error(i18n.t("prepOrder.selectPickupPlaceError")));
                return false;
            }
        }
        this.setState({ fieldErrors });
        return fieldErrors.length === 0;
    }

    private formatPrice(price: number): string {
        return price.toFixed(2).replace(".", ",") || "";
    }
}
