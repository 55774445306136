import * as Rx from "rxjs";
import { InitiateOrderRequest, InitiateOrderResponse } from "../../infrastructure/api/models";
import { API } from "../API";

export interface OrderRepository {
    initiateOrder(order: InitiateOrderRequest): Rx.Observable<InitiateOrderResponse>;
}

export class DefaultOrderRepository implements OrderRepository {
    // Properties

    private readonly api: API;

    // Public functions

    public constructor(api: API) {
        this.api = api;
    }

    public initiateOrder(order: InitiateOrderRequest): Rx.Observable<InitiateOrderResponse> {
        return this.api.initiateOrder(order);
    }
}
