import React from "react";
import styled from "styled-components";
import { Colors } from "../../appearance/Colors";
import { EventIcon } from "../../eventlist/EventIcon";
import { Event, EventStatus, User } from "../../../domain/model";
import { DateFormatter } from "../../../infrastructure/DateFormatter";
import DI from "../../../di/DI";
import { TYPES } from "../../../di/Types";
import { BaseSubscriptionHandlerComponent } from "../../BaseSubscriptionHandlerComponent";
import { RoundButton } from "../../appearance/RoundButton";
import { Link } from "../../appearance/Link";
import { Path } from "../../App";

const Container = styled.div<{ disabled: boolean }>`
    background-color: ${Colors.lightBackground};
    padding: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin: 10px 0px;
    border-radius: 16px;
    position: relative;
    justify-content: space-between;
    opacity: ${(props) => (props.disabled ? 0.65 : 1)};
`;
const LeadingContent = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const DescContainer = styled.div`
    margin-left: 12px;
    h4 {
        font-size: 15px;
        margin: 0;
    }
    p {
        margin: 0;
        font-size: 15px;
    }
`;

const StatusAndActionContainer = styled.div`
    display: flex;
    flex-direction: row;

    > * {
        margin: auto;

        :not(:last-child) {
            margin-right: 8px;
        }
    }
`;

const ActionLink = styled(Link)`
    display: flex;
`;

interface Props {
    event: Event;
    canBePlanned: boolean;
    canBeChanged: boolean;
    disabled: boolean;
    isPast: boolean;
    user: User;
}

interface State {}

export class ParticipantProfileEventItem extends BaseSubscriptionHandlerComponent<Props, State> {
    // Public functions

    private readonly dateFormatter: DateFormatter = DI.get(TYPES.DateFormatter);

    public constructor(props: Props) {
        super(props);
        this.state = {};
    }

    public render(): React.ReactNode {
        return (
            <Container disabled={this.props.disabled}>
                <LeadingContent>
                    <EventIcon event={this.props.event} />
                    <DescContainer>
                        <h4>{this.props.event.description}</h4>
                        {this.props.event.plannedDate && (
                            <p>{this.dateFormatter.formatFullDate(this.props.event.plannedDate)}</p>
                        )}
                    </DescContainer>
                </LeadingContent>
                <StatusAndActionContainer>
                    {this.props.event.status == EventStatus.CANCELLED && <p>Geannuleerd</p>}
                    <ActionLink to={Path.admin.event.edit(this.props.user.id, this.props.event.id)}>
                        {this.props.canBePlanned && <RoundButton text={"Inplannen"} />}
                        {this.props.canBeChanged && (
                            <RoundButton background={Colors.darkAccent} textColor={Colors.background} text={"Wijzig"} />
                        )}
                        {Event.isPast(this.props.event) && (
                            <RoundButton
                                background={Colors.darkAccent}
                                textColor={Colors.background}
                                text={"Details"}
                            />
                        )}
                        {this.props.event.status == EventStatus.COMPLETED && <span>Voltooid</span>}
                    </ActionLink>
                </StatusAndActionContainer>
            </Container>
        );
    }
}
