import React from "react";
import * as Rx from "rxjs";
import { showError } from "../utils/MessageUtils";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export abstract class BaseSubscriptionHandlerComponent<P = any, S = any, SS = any> extends React.Component<P, S, SS> {
    protected subscriptions: Rx.Subscription = new Rx.Subscription();

    public componentWillUnmount(): void {
        this.subscriptions.unsubscribe();
    }

    public collectSubscription(subscription: Rx.Subscription): void {
        this.subscriptions.add(subscription);
    }

    public assignToState<T, K extends keyof S>(
        observable: Rx.Observable<T>,
        update: (newValue: T) => Pick<S, K> | null,
        onlyLogError = false,
    ): void {
        const subscription = observable.subscribe(
            (newValue) => this.setState(update(newValue)),
            (error) => {
                if (!onlyLogError) {
                    showError(error);
                } else {
                    console.log(error);
                }
            },
        );
        this.collectSubscription(subscription);
    }

    public updateStateOnComplete<K extends keyof S>(
        observable: Rx.Observable<void>,
        update: () => Pick<S, K> | null,
        onlyLogError = false,
    ): void {
        const subscription = observable.subscribe({
            error: (error) => {
                if (!onlyLogError) {
                    showError(error);
                } else {
                    console.log(error);
                }
            },
            complete: () => this.setState(update()),
        });
        this.collectSubscription(subscription);
    }
}
