import React from "react";
import styled from "styled-components";
import { BaseSubscriptionHandlerComponent } from "../BaseSubscriptionHandlerComponent";
import { BackLink } from "../appearance/BackLink";
import DI from "../../di/DI";
import { TYPES } from "../../di/Types";
import { Colors } from "../appearance/Colors";
import { RouteComponentProps } from "react-router-dom";
import { MessageSource, ValidationPattern } from "../../domain/model";
import { RoundInput } from "../appearance/RoundInput";
import { RoundButton } from "../appearance/RoundButton";
import { showError, showSuccess } from "../../utils/MessageUtils";
import { Path } from "../App";
import { NewMessageViewModel } from "./NewMessageViewModel";
import { SegmentedControl } from "../appearance/SegmentedControl";
import i18n from "i18next";

const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    width: 90%;
    max-width: 480px;
`;
const Form = styled.form`
    display: flex;
    background-color: ${Colors.lightBackground};
    border-radius: 12px;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 40px;
    margin-top: 16px;
    margin-bottom: 100px;
    > h4 {
        font-size: 20px;
        margin-bottom: 16px;
    }
    > div {
        margin-top: 16px;
    }
    > button {
        margin-top: 16px;
    }
    textarea {
        min-height: 250px;
        min-width: 100%;
        max-width: 100%;
    }
`;
const Title = styled.h2`
    font-size: 28px;
    font-weight: bold;
    margin: 0;
`;

interface Props extends RouteComponentProps<{ participantId?: string }> {}
interface State {
    source: MessageSource;
    subject: string;
    message: string;
    isSubmitting: boolean;
    fieldErrors: (keyof State)[];
}

export class NewMessage extends BaseSubscriptionHandlerComponent<Props, State> {
    // Properties

    private readonly viewModel: NewMessageViewModel = DI.get(TYPES.NewMessageViewModel);

    private get participantId(): string | null {
        return this.props.match.params.participantId || null;
    }

    // Public functions

    public constructor(props: Props) {
        super(props);
        this.state = { source: MessageSource.EZIPREP, subject: "", message: "", fieldErrors: [], isSubmitting: false };
    }

    public render(): React.ReactNode {
        return (
            <Container>
                <BackLink title={this.getBackRouteName()} to={this.getBackRoute()} />
                <Form action={"#"}>
                    <Title>Bericht versturen</Title>
                    <SegmentedControl
                        title={"Van"}
                        background={Colors.darkAccent}
                        selectedBackground={"#ffffff"}
                        textColor={Colors.background}
                        selectedTextColor={Colors.background}
                        adaptTextColor={false}
                        selectedOption={this.state.source}
                        displayValues={["EZI-PrEP", "GGD"]}
                        onChange={(source) => this.setState({ source })}
                        options={[MessageSource.EZIPREP, MessageSource.GGD]}
                    />
                    {this.renderInput("Titel", "subject", false)}
                    {this.renderInput("Inhoud bericht", "message")}
                    <RoundButton
                        isLoading={this.state.isSubmitting}
                        onClick={this.submit.bind(this)}
                        fillWidth={true}
                        text={"Verstuur bericht"}
                    />
                </Form>
            </Container>
        );
    }

    private renderInput<K extends keyof State>(title: string, stateKey: K, multiline = true): React.ReactNode {
        return (
            <RoundInput
                errorOccurred={this.state.fieldErrors.includes(stateKey)}
                text={this.state[stateKey] as string | null}
                onTextChange={(value) => this.updateFields({ [stateKey]: value } as Pick<State, K>)}
                fillWidth={true}
                multiline={multiline}
                title={title}
            />
        );
    }

    private updateFields<K extends keyof State>(update: Pick<State, K>): void {
        this.setState({ fieldErrors: this.state.fieldErrors.filter((key) => !Object.keys(update).includes(key)) });
        this.setState(update);
    }

    private submit(e: React.MouseEvent): void {
        e.preventDefault();
        if (!this.validateForm()) {
            showError(new Error(i18n.t("common.validationFailed")));
            return;
        }
        this.setState({ isSubmitting: true });
        this.viewModel
            .sendMessage(this.state.source, this.state.subject, this.state.message, this.participantId || undefined)
            .subscribe({
                complete: () => {
                    showSuccess("Succesvol ingediend");
                    this.props.history.push(this.getBackRoute());
                },
                error: (error) => {
                    showError(error);
                    this.setState({ isSubmitting: false });
                },
            });
    }

    private validateForm(): boolean {
        const fieldErrors: (keyof State)[] = [];
        if (!ValidationPattern.notBlank.test(this.state.subject || "")) {
            fieldErrors.push("subject");
        }
        if (!ValidationPattern.notBlank.test(this.state.message || "")) {
            fieldErrors.push("message");
        }
        this.setState({ fieldErrors });
        return fieldErrors.length === 0;
    }

    private getBackRouteName(): string {
        if (this.participantId == null) {
            return "Dashboard";
        } else {
            return "Terug";
        }
    }

    private getBackRoute(): string {
        if (this.participantId == null) {
            return Path.admin.root;
        }
        return Path.admin.participant.profile(this.participantId);
    }
}
