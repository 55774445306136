import { BaseViewModel } from "../BaseViewModel";
import { UserRepository } from "../../domain/repositories";
import * as Rx from "rxjs";
import { User } from "../../domain/model";

export class UserProfileViewModel extends BaseViewModel {
    public constructor(private readonly userRepository: UserRepository) {
        super();
    }

    public fetchUser(id: string): Rx.Observable<User> {
        return this.userRepository.getUser(id);
    }

    public deactivateUser(id: string): Rx.Observable<void> {
        return this.userRepository.setUserActivate(id, false);
    }

    public activateUser(id: string): Rx.Observable<void> {
        return this.userRepository.setUserActivate(id, true);
    }
}
