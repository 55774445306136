import {
    fullNameOf,
    getGenderTranslationKey,
    StudyArmPrepServiceType,
    StudyArmVisitFrequency,
    User,
} from "../../../domain/model";
import { BaseSubscriptionHandlerComponent } from "../../BaseSubscriptionHandlerComponent";
import { Link } from "../../appearance/Link";
import { RoundButton } from "../../appearance/RoundButton";
import { Colors } from "../../appearance/Colors";
import React from "react";
import styled from "styled-components";
import { Path } from "../../App";
import { DateFormatter } from "../../../infrastructure/DateFormatter";
import DI from "../../../di/DI";
import { TYPES } from "../../../di/Types";
import i18n from "i18next";

const HeaderRow = styled.div`
    marigin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;
const ContentContainer = styled.div`
    background-color: ${Colors.lightBackground};
    padding: 16px;
    display: flex;
    flex-direction: row;
    border-radius: 16px;
`;
const ContentSection = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    h5 {
        font-size: 15px;
        font-weight: bold;
        margin: 0;
    }
`;
const ContentRow = styled.div`
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    label {
        font-size: 14;
        opacity: 0.75;
    }
    span {
        margin-top: 2px;
        font-size: 15;
    }
`;

interface Props {
    user: User;
}
interface State {}

export class ParticipantProfileInfo extends BaseSubscriptionHandlerComponent<Props, State> {
    // Properties

    private readonly dateFormatter: DateFormatter = DI.get(TYPES.DateFormatter);

    // Public functions

    public render(): React.ReactNode {
        const user = this.props.user;
        type Row = { label: string; value: string };
        type Section = { label: string; rows: Row[] };
        const personalData: Section[] = [
            {
                label: "Persoonlijke gegevens",
                rows: [
                    { label: "Naam", value: fullNameOf(user) },
                    { label: "Gender", value: user.gender ? i18n.t(getGenderTranslationKey(user.gender)) : "-" },
                    {
                        label: "Geboortedatum",
                        value: (user.birthDate && this.dateFormatter.formatFullDate(user.birthDate)) || "-",
                    },
                    { label: "E-mailadres", value: user.email },
                    { label: "Telefoonnummer", value: user.phone || "-" },
                ],
            },
            {
                label: "Medische gegevens",
                rows: [
                    { label: "Syfilis gehad", value: user.hadSyfilis ? "Ja" : "Nee" },
                    { label: "HCV gehad", value: user.hadHCV ? "Ja" : "Nee" },
                ],
            },
            {
                label: "Studiegegevens",
                rows: [
                    { label: "Studienummer", value: user.studyNumber || "-" },
                    {
                        label: "Online of offline PrEP service",
                        value: user.prepServiceType == StudyArmPrepServiceType.ONLINE ? "Online" : "Offline",
                    },
                    {
                        label: "Frequentie controlebezoeken",
                        value: `Om de ${user.visitFrequency == StudyArmVisitFrequency.THREE_MONTH ? "3" : "6"} maanden`,
                    },
                    {
                        label: "Ervaring met PrEP",
                        value: user.starter ? "Geen ervaring" : "Ervaring",
                    },
                ],
            },
        ];
        return (
            <div>
                <HeaderRow>
                    <h4>Gegevens</h4>
                    <Link to={Path.admin.participant.edit(user.id)}>
                        <RoundButton
                            background={Colors.homeBackground}
                            textColor={Colors.background}
                            text={"Wijzig"}
                            horizontalPadding={0}
                        />
                    </Link>
                </HeaderRow>

                <ContentContainer>
                    {personalData.map((section) => (
                        <ContentSection key={section.label}>
                            <h5>{section.label}</h5>
                            {section.rows.map((item) => (
                                <ContentRow key={item.label}>
                                    <label>{item.label}</label>
                                    <span>{item.value}</span>
                                </ContentRow>
                            ))}
                        </ContentSection>
                    ))}
                </ContentContainer>
            </div>
        );
    }
}
