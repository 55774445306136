import React from "react";
import { createRoot } from "react-dom/client";
import { App } from "./components/App";
import * as serviceWorker from "./utils/serviceWorker";
import { DefaultDependencyProvider } from "./di/DependencyProvider";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import translationEN from "./res/locales/en.json";
import translationNL from "./res/locales/nl.json";
import "./i18nextPatches";
import * as Sentry from "@sentry/react";

console.log(`EZI-PrEP`);
console.log(`Backend is: ${process.env.REACT_APP_API_BASE_URL}`);
console.log(`Version: ${process.env.REACT_APP_BUILD_SHA}`);

new DefaultDependencyProvider().setup();
if (process.env.REACT_APP_SENTRY_DSN) {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        environment: process.env.REACT_APP_ENVIRONMENT ?? "development",
        tracesSampleRate: 0.5,
    });
}

i18n.use(LanguageDetector)
    .init({
        lng: localStorage.i18nextLng || navigator.language.replace("-", "_").split("_")[0],
        fallbackLng: "en",
        debug: process.env.NODE_ENV === "development",
        resources: {
            en: {
                translation: translationEN,
            },
            nl: {
                translation: translationNL,
            },
        },
    })
    .then(() => {
        const root = createRoot(document.getElementById("root")!);
        root.render(<App />);

        // If you want your app to work offline and load faster, you can change
        // unregister() to register() below. Note this comes with some pitfalls.
        // Learn more about service workers: https://bit.ly/CRA-PWA
        serviceWorker.unregister();
    });
