import React from "react";
import styled from "styled-components";
import { BaseSubscriptionHandlerComponent } from "../BaseSubscriptionHandlerComponent";
import { ScreenDimmer } from "./ScreenDimmer";
import { Colors } from "./Colors";

const FADE_ANIMATION_DURATION_MILLIS = 300;

const Container = styled.div`
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    z-index: 1000;
`;

const Center = styled.div`
    height: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const ContentRoot = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
`;

const ModalHeaderText = styled.span`
    font-size: 32px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.2px;
    text-align: left;
    color: ${Colors.text};
`;

const ClearBackground = styled.span`
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
`;
const OverlayViewGroup = styled.div`
    background-color: ${Colors.lightBackground};
    border-radius: 14px;
    padding: 20px;
    position: relative;
    margin-top: 20px;
    pointer-events: all;
`;

const AnimatingWrapper = styled.div<{ isVisible?: boolean; animationDurationMillis?: number }>`
    opacity: ${(props) => (props.isVisible ? 1 : 0)};
    transition: opacity ${(props) => props.animationDurationMillis || FADE_ANIMATION_DURATION_MILLIS}ms ease-out;
`;

export const ModalBodyText = styled.span`
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: 0.01px;
    color: ${Colors.text};
`;

export const ModalBodyMessageText = styled(ModalBodyText)`
    margin-top: 14px;
    max-width: 300px;
`;

export const ModalSection = styled.div`
    margin-top: 40px;
    display: flex;
    flex-direction: column;
`;

export interface ModalProps {
    title?: string;
    message?: string;
    animationDurationMillis?: number;
    onClosed?: () => void;
}

export interface ModalState {
    showing: boolean;
}

export abstract class Modal<
    P extends ModalProps = ModalProps,
    S extends ModalState = ModalState,
> extends BaseSubscriptionHandlerComponent<P, S> {
    // Properties

    private readonly screenDimmer = React.createRef<ScreenDimmer>();
    protected title?: string = this.props.title || "";
    protected message?: string = this.props.message || "";
    protected animationDurationMillis?: number = this.props.animationDurationMillis || FADE_ANIMATION_DURATION_MILLIS;
    protected showScreenDimmer = true;

    // Public functions

    public abstract renderContent(): React.ReactNode;

    public componentDidMount(): void {
        setTimeout(() => {
            this.setState({ showing: true });
        });
    }

    public render(): React.ReactNode {
        return (
            <Container>
                {this.showScreenDimmer && (
                    <ScreenDimmer
                        ref={this.screenDimmer}
                        onClick={() => this.close()}
                        animationDurationMillis={this.animationDurationMillis}
                    />
                )}
                {!this.showScreenDimmer && <ClearBackground onClick={() => this.close()} />}
                <Center>
                    <AnimatingWrapper
                        isVisible={this.state.showing}
                        animationDurationMillis={this.animationDurationMillis}
                    >
                        <OverlayViewGroup>
                            <ContentRoot>
                                {this.title && <ModalHeaderText>{this.title}</ModalHeaderText>}
                                {this.message && <ModalBodyMessageText>{this.message}</ModalBodyMessageText>}
                                {this.renderContent()}
                            </ContentRoot>
                        </OverlayViewGroup>
                    </AnimatingWrapper>
                </Center>
            </Container>
        );
    }

    // Private functions

    protected close(callback?: () => void): void {
        if (this.screenDimmer.current) {
            this.screenDimmer.current.hide();
        }
        this.setState({ showing: false });
        setTimeout(
            () => (callback && callback()) || (this.props.onClosed && this.props.onClosed()),
            this.animationDurationMillis || FADE_ANIMATION_DURATION_MILLIS,
        );
    }
}
