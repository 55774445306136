import React from "react";
import { ContentPageContainer } from "../content/ContentPageContainer";
import i18n from "i18next";
import styled from "styled-components";
import {
    BulletPointContainer,
    Colors,
    HeadlineContainer,
    Link,
    RoundButton,
    SegmentedControl,
    translatedParagraph,
} from "../../appearance";
import { Path } from "../../App";
import { joinStudyRegions } from "../Utils";
import DI from "../../../di/DI";
import { TYPES } from "../../../di/Types";
import { ParticipationInfoViewModel } from "./ParticipationInfoViewModel";

enum Tab {
    STUDY = "study",
    PARTICIPATING = "participating",
    REGISTRATION = "registration",
    APP = "app",
}

const FAQContainer = styled.div`
    background-color: ${Colors.homeBackground};
    padding: 16px 24px 56px 24px;
    border-radius: 24px;
    justify-content: center;
    margin-left: -24px;
    margin-right: -24px;

    @media (max-width: 768px) {
        margin-right: -18px;
    }
`;

interface State {
    selectedTab: Tab;
}

export class ParticipationInfo extends React.Component<unknown, State> {
    // Properties

    private readonly viewModel: ParticipationInfoViewModel = DI.get(TYPES.ParticipationInfoViewModel);

    // Public functions

    public constructor(props: Readonly<unknown>) {
        super(props);

        this.state = {
            selectedTab: Tab.STUDY,
        };
    }

    public render(): React.ReactNode {
        return (
            <ContentPageContainer>
                <HeadlineContainer>
                    <h1>{i18n.t("content.participationInfo.title") as string}</h1>
                    {translatedParagraph(
                        this.viewModel.isRegistrationEnabled()
                            ? "content.participationInfo.description"
                            : "content.participationInfo.descriptionWithoutRegistration",
                    )}
                </HeadlineContainer>
                {this.viewModel.isRegistrationEnabled() && (
                    <Link to={Path.public.apply}>
                        <RoundButton text={i18n.t("common.register")} />
                    </Link>
                )}
                <br />
                <FAQContainer>
                    <h2>{i18n.t("content.participationInfo.questionsAndAnswersTitle") as string}</h2>
                    <SegmentedControl
                        selectedOption={this.state.selectedTab}
                        displayValues={
                            this.viewModel.isRegistrationEnabled()
                                ? [
                                      i18n.t("content.participationInfo.sectionEziPrepTitle"),
                                      i18n.t("content.participationInfo.sectionRegistrationTitle"),
                                      i18n.t("content.participationInfo.sectionParticipatingInStudyTitle"),
                                      i18n.t("content.participationInfo.sectionEziPrepAppTitle"),
                                  ]
                                : [
                                      i18n.t("content.participationInfo.sectionEziPrepTitle"),
                                      i18n.t("content.participationInfo.sectionParticipatingInStudyTitle"),
                                      i18n.t("content.participationInfo.sectionEziPrepAppTitle"),
                                  ]
                        }
                        onChange={(selectedTab) => this.setState({ selectedTab })}
                        options={
                            this.viewModel.isRegistrationEnabled()
                                ? [Tab.STUDY, Tab.REGISTRATION, Tab.PARTICIPATING, Tab.APP]
                                : [Tab.STUDY, Tab.PARTICIPATING, Tab.APP]
                        }
                    />
                    {this.state.selectedTab === Tab.STUDY && this.renderStudyTab()}
                    {this.state.selectedTab === Tab.REGISTRATION && this.renderRegistrationTab()}
                    {this.state.selectedTab === Tab.PARTICIPATING && this.renderParticipatingTab()}
                    {this.state.selectedTab === Tab.APP && this.renderAppTab()}

                    {this.viewModel.isRegistrationEnabled() && (
                        <>
                            <h2>{i18n.t("content.participationInfo.footerTitle") as string}</h2>
                            <Link to={Path.public.apply}>
                                <RoundButton text={i18n.t("common.register")} />
                            </Link>
                        </>
                    )}
                </FAQContainer>
            </ContentPageContainer>
        );
    }

    private renderStudyTab(): React.ReactNode {
        return (
            <div>
                <HeadlineContainer>
                    <h3>{i18n.t("content.participationInfo.whatDoesEziPrepWantToAchieveTitle") as string}</h3>
                    {translatedParagraph("content.participationInfo.whatDoesEziPrepWantToAchieveDesc")}
                </HeadlineContainer>
                <HeadlineContainer>
                    <h3>{i18n.t("content.participationInfo.whatDoesEziPrepStudyEntailTitle") as string}</h3>
                    {translatedParagraph("content.participationInfo.whatDoesEziPrepStudyEntailDesc")}
                    <BulletPointContainer bulletType="numbers">
                        <ul>
                            {i18n
                                .array("content.participationInfo.whatDoesEziPrepStudyEntailBulletPoints")
                                .map((bp, index) => (
                                    <li key={index} dangerouslySetInnerHTML={{ __html: bp }}></li>
                                ))}
                        </ul>
                    </BulletPointContainer>
                </HeadlineContainer>
                <HeadlineContainer>
                    <h3>{i18n.t("content.participationInfo.whatIsNormalPrepTitle") as string}</h3>
                    {translatedParagraph("content.participationInfo.whatIsNormalPrepDesc")}
                </HeadlineContainer>
                <HeadlineContainer>
                    <h3>{i18n.t("content.participationInfo.whatIsOnlinePrepTitle") as string}</h3>
                    {translatedParagraph(
                        "content.participationInfo.whatIsOnlinePrepDesc",
                        Path.public.aboutOnlinePrEPService,
                    )}
                </HeadlineContainer>
                <HeadlineContainer>
                    <h3>{i18n.t("content.participationInfo.canEveryoneUseOnlinePrepTitle") as string}</h3>
                    {translatedParagraph("content.participationInfo.canEveryoneUseOnlinePrepDesc")}
                </HeadlineContainer>
                <HeadlineContainer>
                    <h3>{i18n.t("content.participationInfo.whatDoesItCostToParticipateTitle") as string}</h3>
                    {translatedParagraph("content.participationInfo.whatDoesItCostToParticipateDesc")}
                </HeadlineContainer>
                <HeadlineContainer>
                    <h3>{i18n.t("content.participationInfo.howLongWillStudyLastTitle") as string}</h3>
                    {translatedParagraph("content.participationInfo.howLongWillStudyLastDesc")}
                </HeadlineContainer>
                <HeadlineContainer>
                    <h3>{i18n.t("content.participationInfo.whatIsTestlabTitle") as string}</h3>
                    {translatedParagraph(
                        "content.participationInfo.whatIsTestlabDesc",
                        joinStudyRegions({ lastSeparator: i18n.t("common.and"), addPrefix: true }),
                    )}
                </HeadlineContainer>
                <HeadlineContainer>
                    <h3>{i18n.t("content.participationInfo.whatIsNationalPrepPilotProgramTitle") as string}</h3>
                    {translatedParagraph("content.participationInfo.whatIsNationalPrepPilotProgramDesc")}
                </HeadlineContainer>
                <HeadlineContainer>
                    <h3>{i18n.t("content.participationInfo.whoConductsTheStudyTitle") as string}</h3>
                    {translatedParagraph(
                        "content.participationInfo.whoConductsTheStudyDesc",
                        joinStudyRegions({ addPrefix: true, lastSeparator: "," }),
                    )}
                </HeadlineContainer>
            </div>
        );
    }

    private renderRegistrationTab(): React.ReactNode {
        return (
            <div>
                <HeadlineContainer>
                    <h3>{i18n.t("content.participationInfo.howCanIParticipateTitle") as string}</h3>
                    {this.viewModel.isRegistrationEnabled()
                        ? translatedParagraph(
                              "content.participationInfo.howCanIParticipateDesc",
                              joinStudyRegions({ lastSeparator: i18n.t("common.or") }),
                              Path.public.apply,
                          )
                        : translatedParagraph(
                              "content.participationInfo.howCanIParticipateDescWithoutRegistration",
                              joinStudyRegions({ lastSeparator: i18n.t("common.or") }),
                          )}
                </HeadlineContainer>
                <HeadlineContainer>
                    <h3>{i18n.t("content.participationInfo.howDoesRegistrationGoTitle") as string}</h3>
                    {translatedParagraph(
                        "content.participationInfo.howDoesRegistrationGoDesc",
                        Path.public.applicationForm,
                    )}
                </HeadlineContainer>
                <HeadlineContainer>
                    <h3>{i18n.t("content.participationInfo.whatIsPatientInformationFolderTitle") as string}</h3>
                    {translatedParagraph("content.participationInfo.whatIsPatientInformationFolderDesc")}
                </HeadlineContainer>
                <HeadlineContainer>
                    <h3>{i18n.t("content.participationInfo.whatHappensDuringIntakeInterviewTitle") as string}</h3>
                    {translatedParagraph("content.participationInfo.whatHappensDuringIntakeInterviewDesc")}
                </HeadlineContainer>
                <HeadlineContainer>
                    <h3>{i18n.t("content.participationInfo.whatIsInformedConsentTitle") as string}</h3>
                    {translatedParagraph("content.participationInfo.whatIsInformedConsentDesc")}
                </HeadlineContainer>
                <HeadlineContainer>
                    <h3>
                        {
                            i18n.t(
                                "content.participationInfo.whereCanIFindInformationOnRightsOfParticipantsTitle",
                            ) as string
                        }
                    </h3>
                    {translatedParagraph(
                        "content.participationInfo.whereCanIFindInformationOnRightsOfParticipantsDesc",
                    )}
                </HeadlineContainer>
            </div>
        );
    }

    private renderParticipatingTab(): React.ReactNode {
        return (
            <div>
                <HeadlineContainer>
                    <h3>{i18n.t("content.participationInfo.whatIsExpectedOfParticipantsTitle") as string}</h3>
                    {translatedParagraph("content.participationInfo.whatIsExpectedOfParticipantsDesc")}
                </HeadlineContainer>
                <HeadlineContainer>
                    <h3>{i18n.t("content.participationInfo.whatOtherInformationAboutMeIsCollectedTitle") as string}</h3>
                    {translatedParagraph(
                        "content.participationInfo.whatOtherInformationAboutMeIsCollectedDesc",
                        Path.public.privacy,
                    )}
                </HeadlineContainer>
                <HeadlineContainer>
                    <h3>{i18n.t("content.participationInfo.howDoYouTakeCareOfMyPrivacyTitle") as string}</h3>
                    {translatedParagraph(
                        "content.participationInfo.howDoYouTakeCareOfMyPrivacyDesc",
                        Path.public.privacy,
                    )}
                </HeadlineContainer>
                <HeadlineContainer>
                    <h3>{i18n.t("content.participationInfo.canIStopWithParticipatingTitle") as string}</h3>
                    {translatedParagraph("content.participationInfo.canIStopWithParticipatingDesc")}
                </HeadlineContainer>
                <HeadlineContainer>
                    <h3>{i18n.t("content.participationInfo.howWillIBeInformedOfProgressTitle") as string}</h3>
                    {translatedParagraph("content.participationInfo.howWillIBeInformedOfProgressDesc")}
                </HeadlineContainer>
            </div>
        );
    }

    private renderAppTab(): React.ReactNode {
        return (
            <div>
                <HeadlineContainer>
                    <h3>{i18n.t("content.participationInfo.iCannotLogInTitle") as string}</h3>
                    {translatedParagraph("content.participationInfo.iCannotLogInDesc", Path.public.contact)}
                </HeadlineContainer>
                <HeadlineContainer>
                    <h3>{i18n.t("content.participationInfo.willAllParticipantsHaveAccessTitle") as string}</h3>
                    {translatedParagraph("content.participationInfo.willAllParticipantsHaveAccessDesc")}
                </HeadlineContainer>
                <HeadlineContainer>
                    <h3>{i18n.t("content.participationInfo.canITestForStisInBetweenCheckUpsTitle") as string}</h3>
                    {translatedParagraph("content.participationInfo.canITestForStisInBetweenCheckUpsDesc")}
                </HeadlineContainer>
            </div>
        );
    }
}
