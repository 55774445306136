import React from "react";
import { Event, EventType } from "../../domain/model";
import { ReactComponent as PrepIcon } from "../../../src/res/images/prep.svg";
import { ReactComponent as QuestionnaireIcon } from "../../../src/res/images/questionnaire.svg";
import { ReactComponent as TestlabIcon } from "../../../src/res/images/testlab.svg";
import { ReactComponent as VideoIcon } from "../../../src/res/images/video.svg";
import { ReactComponent as MeetingIcon } from "../../../src/res/images/meeting.svg";

interface Props {
    event: Event;
}

interface State {}

export class EventIcon extends React.Component<Props, State> {
    public render(): React.ReactNode {
        switch (this.props.event.type) {
            case EventType.QUESTIONNAIRE:
                return <QuestionnaireIcon />;
            case EventType.TESTLAB:
                return <TestlabIcon />;
            case EventType.ONLINE_MEETING:
                return <VideoIcon />;
            case EventType.OFFLINE_MEETING:
                return <MeetingIcon />;
            case EventType.ORDER_PILLS:
                return <PrepIcon />;
        }
        return "";
    }
}
