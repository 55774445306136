import React from "react";
import { ContentPageContainer } from "./content/ContentPageContainer";
import i18n from "i18next";
import {
    HeadlineContainer,
    translatedBulletPoints,
    translated,
    translatedParagraph,
    BulletPointContainer,
    Mailto,
    ReverseText,
    ExternalLink,
} from "../appearance";
import { StudyRegions } from "../../domain/model";
import { reverseString } from "../../utils/StringUtils";

export class Privacy extends React.Component {
    public render(): React.ReactNode {
        return (
            <ContentPageContainer>
                <HeadlineContainer>
                    {translated("h1", "content.privacy.title")}
                    {translatedParagraph("content.privacy.description")}
                </HeadlineContainer>
                {this.renderSections()}
            </ContentPageContainer>
        );
    }

    private renderSections(): React.ReactNode {
        return <>{i18n.array("content.privacy.sections").map((o, index) => this.renderSection(index))}</>;
    }

    private renderSection(index: number): React.ReactNode {
        const sectionKey = `content.privacy.sections.${index}`;
        return (
            <HeadlineContainer key={index}>
                {translated("h2", `${sectionKey}.title`)}
                {this.renderSubSections(sectionKey)}
            </HeadlineContainer>
        );
    }

    private renderSubSections(sectionKey: string): React.ReactNode {
        return (
            <>{i18n.array(`${sectionKey}.subSections`).map((o, index) => this.renderSubSection(sectionKey, index))}</>
        );
    }

    private renderSubSection(sectionKey: string, index: number): React.ReactNode {
        const subSectionKey = `${sectionKey}.subSections.${index}`;
        return (
            <div key={index}>
                {translatedParagraph(`${subSectionKey}.description`)}
                {translatedBulletPoints(`${subSectionKey}.bulletPoints`)}
                {this.renderEmailAddressBullets(`${subSectionKey}.privacyEmails`)}
                {this.renderComplaintsDetailsBullets(`${subSectionKey}.complaintsDetails`)}
            </div>
        );
    }

    private renderEmailAddressBullets(translationKey: string): React.ReactNode | undefined {
        if (!i18n.exists(translationKey)) {
            return undefined;
        }
        return (
            <BulletPointContainer>
                <ul>
                    {Object.values(StudyRegions).map((r, index) => (
                        <li key={index}>
                            <span>{`GGD ${r.name}: `}</span>
                            <Mailto email={r.privacyEmailAddress} obfuscate>
                                <ReverseText>{reverseString(r.privacyEmailAddress)}</ReverseText>
                            </Mailto>
                        </li>
                    ))}
                </ul>
            </BulletPointContainer>
        );
    }

    private renderComplaintsDetailsBullets(translationKey: string): React.ReactNode | undefined {
        if (!i18n.exists(translationKey)) {
            return undefined;
        }
        return (
            <BulletPointContainer>
                <ul>
                    {Object.values(StudyRegions).map((r, index) => (
                        <li key={index}>
                            <span>{`GGD ${r.name}`}</span>
                            <br />
                            {r.complaintsDetails.website && (
                                <>
                                    <span>
                                        {i18n.t("common.website") as string}:{" "}
                                        <ExternalLink href={r.complaintsDetails.website} defaultStyling>
                                            {r.complaintsDetails.website}
                                        </ExternalLink>
                                    </span>
                                    <br />
                                </>
                            )}
                            {r.complaintsDetails.email && (
                                <>
                                    <span>
                                        {i18n.t("common.byEmail") as string}:{" "}
                                        <Mailto email={r.complaintsDetails.email} obfuscate>
                                            <ReverseText>{reverseString(r.complaintsDetails.email)}</ReverseText>
                                        </Mailto>
                                    </span>
                                    <br />
                                </>
                            )}
                            {r.complaintsDetails.telephone && (
                                <>
                                    <span>
                                        {i18n.t("common.byTelephone") as string}: {r.complaintsDetails.telephone}
                                    </span>
                                    <br />
                                </>
                            )}
                            {r.complaintsDetails.address && (
                                <>
                                    <span>
                                        {i18n.t("common.byPost") as string}: {r.complaintsDetails.address}
                                    </span>
                                    <br />
                                </>
                            )}
                        </li>
                    ))}
                </ul>
            </BulletPointContainer>
        );
    }
}
