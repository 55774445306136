import { AddressResponse } from "./AddressResponse";

export interface OrderRequest {
    event_id: string;
    receiving_type: OrderReceivingType;
    recipient_name?: string;
    pickup_place?: OrderPickupPlace;
    delivery_type: OrderReceivingType;
    address?: AddressResponse;
}

export type InitiateOrderRequest = Pick<OrderRequest, "event_id" | "delivery_type" | "address">;

export enum OrderReceivingType {
    DELIVERY = "deliver",
    PICKUP = "pickup",
}

export enum OrderPickupPlace {
    GGD = "delivery",
    PHARMACY = "pharmacy",
}
