import React from "react";
import styled from "styled-components";
import { BaseSubscriptionHandlerComponent } from "../BaseSubscriptionHandlerComponent";
import { BackLink } from "../appearance/BackLink";
import DI from "../../di/DI";
import { TYPES } from "../../di/Types";
import { Colors } from "../appearance/Colors";
import { NewUserViewModel } from "./NewUserViewModel";
import { RouteComponentProps } from "react-router-dom";
import { RoleId, StudyRegion, StudyRegions, ValidationPattern } from "../../domain/model";
import { RoundInput } from "../appearance/RoundInput";
import { RoundButton } from "../appearance/RoundButton";
import { showError, showSuccess } from "../../utils/MessageUtils";
import { Path } from "../App";
import i18n from "i18next";

const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    width: 90%;
    max-width: 480px;
`;
const Form = styled.form`
    display: flex;
    background-color: ${Colors.lightBackground};
    border-radius: 12px;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 40px;
    margin-top: 16px;
    margin-bottom: 100px;
    > h4 {
        font-size: 20px;
        margin-bottom: 16px;
    }
    > div {
        margin-top: 16px;
    }
    > button {
        margin-top: 16px;
    }
`;
const Title = styled.h2`
    font-size: 28px;
    font-weight: bold;
    margin: 0;
`;

interface Props extends RouteComponentProps<{ region?: string }> {}
interface State {
    firstName: string;
    lastName: string;
    email: string;
    isSubmitting: boolean;
    fieldErrors: (keyof State)[];
}

export class NewUser extends BaseSubscriptionHandlerComponent<Props, State> {
    // Properties

    private readonly viewModel: NewUserViewModel = DI.get(TYPES.NewUserViewModel);

    private get region(): StudyRegion | null {
        return Object.values(StudyRegions).find((sr) => sr.id == this.props.match.params.region) || null;
    }
    private get roleId(): RoleId {
        if (this.props.match.path == Path.admin.user.newAdmin) {
            return RoleId.ADMIN;
        }
        if (this.props.match.path == Path.admin.user.newCaretaker) {
            return RoleId.CARETAKER;
        }
        return RoleId.REGION_MANAGER;
    }

    // Public functions

    public constructor(props: Props) {
        super(props);
        this.state = {
            firstName: "",
            lastName: "",
            email: "",
            fieldErrors: [],
            isSubmitting: false,
        };
    }

    public render(): React.ReactNode {
        return (
            <Container>
                <BackLink title={"Dashboard"} to={Path.admin.root} />
                <Form action={"#"}>
                    {this.roleId == RoleId.REGION_MANAGER && (
                        <Title>
                            Nieuwe superuser <br /> voor GGD {this.props.match.params.region}
                        </Title>
                    )}
                    {this.roleId == RoleId.CARETAKER && <Title>Nieuwe zorgverlener</Title>}
                    {this.roleId == RoleId.ADMIN && <Title>Nieuwe admin</Title>}
                    {this.renderInput("Voornaam", "firstName")}
                    {this.renderInput("Achternaam", "lastName")}
                    {this.renderInput("E-mailadres", "email")}
                    <RoundButton
                        isLoading={this.state.isSubmitting}
                        onClick={this.submit.bind(this)}
                        fillWidth={true}
                        text={"Voeg toe"}
                    />
                </Form>
            </Container>
        );
    }

    private renderInput<K extends keyof State>(title: string, stateKey: K, fillWidth = true): React.ReactNode {
        return (
            <RoundInput
                errorOccurred={this.state.fieldErrors.includes(stateKey)}
                text={this.state[stateKey] as string | null}
                onTextChange={(value) => this.updateFields({ [stateKey]: value } as Pick<State, K>)}
                fillWidth={fillWidth}
                title={title}
            />
        );
    }

    private updateFields<K extends keyof State>(update: Pick<State, K>): void {
        this.setState({ fieldErrors: this.state.fieldErrors.filter((key) => !Object.keys(update).includes(key)) });
        this.setState(update);
    }

    private submit(e: React.MouseEvent): void {
        e.preventDefault();
        if (!this.validateForm()) {
            showError(new Error(i18n.t("common.validationFailed")));
            return;
        }
        this.setState({ isSubmitting: true });
        this.viewModel
            .createUser(this.state.email, this.state.firstName, this.state.lastName, this.roleId, this.region)
            .subscribe({
                complete: () => {
                    showSuccess("Succesvol ingediend");
                    this.props.history.push(Path.admin.root);
                },
                error: (error) => {
                    showError(error);
                    this.setState({ isSubmitting: false });
                },
            });
    }

    private validateForm(): boolean {
        const fieldErrors: (keyof State)[] = [];
        if (!ValidationPattern.notBlank.test(this.state.firstName || "")) {
            fieldErrors.push("firstName");
        }
        if (!ValidationPattern.notBlank.test(this.state.lastName || "")) {
            fieldErrors.push("lastName");
        }
        if (!ValidationPattern.email.test(this.state.email || "")) {
            fieldErrors.push("email");
        }
        this.setState({ fieldErrors });
        return fieldErrors.length === 0;
    }
}
