import React from "react";
import styled from "styled-components";
import { TYPES } from "../../di/Types";
import DI from "../../di/DI";
import { BaseSubscriptionHandlerComponent } from "../BaseSubscriptionHandlerComponent";
import { Colors } from "../appearance/Colors";
import { ToolbarViewModel } from "./ToolbarViewModel";
import { ReactComponent as Logo } from "../../../src/res/images/logo.svg";
import { RoundButton } from "../appearance/RoundButton";
import { showError } from "../../utils/MessageUtils";
import { Link } from "../appearance/Link";
import { Path } from "../App";
import i18n from "i18next";
import packageJson from "./../../../package.json";

const Container = styled.div`
    background-color: ${Colors.background};
    display: flex;
    height: 68px;
    position: fixed;
    top: 0;
    width: 100%;
    color: white;
    flex-direction: row;
    padding: 0 20px;
    justify-content: space-between;
    z-index: 1000;
    padding-top: 12px;
    padding-bottom: 12px;
    a {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-weight: bold;
        color: white;
    }
    svg {
        width: 23.3px;
        height: 42px;
        margin-right: 13px;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-weight: bold;
    }
    > div {
        display: flex;
        flex-direction: row;
    }
    > div > button {
        padding: 9px 10px 11px 40px;
    }
`;
const LanguageSwitchContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    > span {
        width: 1px;
        height: 20px;
        background-color: ${Colors.lightBackground};
    }
`;
const LanguageButton = styled(RoundButton)``;
LanguageButton.defaultProps = {
    background: null,
    textColor: Colors.lightBackground,
    horizontalPadding: 10,
    verticalPadding: 2,
};

interface Props {}
interface State {
    isLoggedIn: boolean;
}

export class Toolbar extends BaseSubscriptionHandlerComponent<Props, State> {
    // Properties

    private readonly viewModel: ToolbarViewModel = DI.get(TYPES.ToolbarViewModel);

    private get isAdminPath(): boolean {
        return location.pathname.startsWith(Path.admin.root);
    }

    // Lifecycle

    public constructor(props: Props) {
        super(props);
        this.state = { isLoggedIn: false };
    }

    // Public functions

    public componentDidMount(): void {
        this.assignToState(this.viewModel.isLoggedIn, (isLoggedIn) => ({ isLoggedIn }));
    }

    public render(): React.ReactNode {
        return (
            <Container>
                <Link to={this.state.isLoggedIn ? Path.admin.root : Path.public.home}>
                    <Logo />
                    {this.isAdminPath ? packageJson.version : ""}
                </Link>
                <div>
                    {!this.isAdminPath && (
                        <LanguageSwitchContainer>
                            <LanguageButton
                                disabled={i18n.language === "nl"}
                                text={"NL"}
                                onClick={() => this.viewModel.changeLanguage("nl")}
                            />
                            <span />
                            <LanguageButton
                                disabled={i18n.language === "en"}
                                text={"EN"}
                                onClick={() => this.viewModel.changeLanguage("en")}
                            />
                        </LanguageSwitchContainer>
                    )}
                    {this.state.isLoggedIn && this.isAdminPath && (
                        <RoundButton
                            background={Colors.background}
                            text={"Uitloggen"}
                            onClick={this.logout.bind(this)}
                        />
                    )}
                </div>
            </Container>
        );
    }

    // Private functions

    private logout(): void {
        const subscription = this.viewModel.logout().subscribe({
            complete: () => {
                window.location.reload();
            },
            error: (error) => showError(error),
        });
        this.collectSubscription(subscription);
    }
}
