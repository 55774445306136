import React from "react";
import styled from "styled-components";
import { BaseSubscriptionHandlerComponent } from "../BaseSubscriptionHandlerComponent";
import { Message } from "../../domain/model";
import Loading from "../appearance/Loading";
import { hexColorWithAlpha } from "../../utils/ColorUtils";
import { Colors } from "../appearance/Colors";
import { DateFormatter } from "../../infrastructure/DateFormatter";
import { TYPES } from "../../di/Types";
import DI from "../../di/DI";
import { MessagePopup } from "./MessagePopup";
import { Link } from "../appearance/Link";
import { Path } from "../App";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100px;
`;

const LoadingContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: absolute;
    flex: 1;
    background-color: ${hexColorWithAlpha(Colors.darkAccent, 0.8)};
    border-radius: 16px;
    top: 10px;
    bottom: 10px;
`;
const MessageListContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
`;

const MessageContainer = styled.div`
    background-color: ${Colors.lightBackground};
    padding: 20px 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin: 10px 0px;
    border-radius: 16px;
    position: relative;
    justify-content: space-between;
    cursor: pointer;
`;

const TimeContainer = styled.div`
    color: ${Colors.text};
    p {
        white-space: nowrap;
        text-align: right;
        margin: 0;
        font-size: 15px;
    }
`;

const DescContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    h4 {
        font-size: 15px;
        margin: 0 0 10px 0;
    }
    p {
        margin: 0 0 5px 0;
        font-size: 15px;
        overflow: hidden;
        width: 100%;
        height: 22px;
    }
    a {
        color: #1f3dd8;
    }
    a:hover {
        text-decoration: underline;
    }
`;

interface Props {
    showTo: boolean;
    isLoading: boolean;
    messages: Message[];
}

interface State {
    selectedMessage: Message | null;
}

export class MessageList extends BaseSubscriptionHandlerComponent<Props, State> {
    // Static properties

    public static defaultProps = {
        showTo: false,
    };

    // Public functions

    private readonly dateFormatter: DateFormatter = DI.get(TYPES.DateFormatter);

    public constructor(props: Props) {
        super(props);
        this.state = { selectedMessage: null };
    }

    public render(): React.ReactNode {
        return (
            <Container>
                <MessageListContainer>
                    {this.props.messages.map((message) => (
                        <MessageContainer onClick={() => this.setState({ selectedMessage: message })} key={message.id}>
                            <DescContainer>
                                <h4>{message.subject}</h4>
                                <p>
                                    {message.sender && (
                                        <Link to={Path.admin.user.profile(message.sender.id)}>
                                            <strong>van:</strong>{" "}
                                            {`${message.sender.firstName} ${message.sender.lastName}`}
                                            &nbsp;&nbsp;&nbsp;
                                        </Link>
                                    )}

                                    {this.props.showTo && message.to && (
                                        <Link to={Path.admin.participant.profile(message.to.id)}>
                                            <strong>naar:</strong> {`${message.to.firstName} ${message.to.lastName}`}
                                        </Link>
                                    )}
                                </p>
                                <p>{message.message}</p>
                            </DescContainer>
                            <TimeContainer>
                                <p>
                                    {this.dateFormatter.formatDateDay(message.date, true, true)}
                                    <br />
                                    {this.dateFormatter.formatTime(message.date)}
                                </p>
                            </TimeContainer>
                        </MessageContainer>
                    ))}
                    {this.props.isLoading && (
                        <LoadingContainer>
                            <Loading />
                        </LoadingContainer>
                    )}
                </MessageListContainer>
                {this.state.selectedMessage && (
                    <MessagePopup
                        messageObject={this.state.selectedMessage}
                        onClosed={() => this.setState({ selectedMessage: null })}
                    />
                )}
            </Container>
        );
    }
}
