import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import { API } from "../API";
import {
    Gender,
    RoleId,
    StudyArmPrepServiceType,
    StudyArmVisitFrequency,
    StudyRegion,
    StudyRegions,
    User,
    UserRole,
    UserStatus,
    ValidationPattern,
} from "../model";
import {
    AddressResponse,
    APIError,
    APIErrorCode,
    TestlabResponse,
    UserInfoResponse,
    UserProfileResponse,
} from "../../infrastructure/api/models";
import { StudyRegionResponse } from "../../infrastructure/api/models";
import { DateFormatter } from "../../infrastructure/DateFormatter";
import { ObservableInput } from "rxjs";

export interface UserRepository {
    readonly currentUser: User | null;
    getCurrentUserInfo(): Rx.Observable<User>;
    queryUsers(roles: RoleId[], query: string | null, regions?: (StudyRegion | null)[]): Rx.Observable<User[]>;
    getParticipantByStudyNumber(studyNumber: string): Rx.Observable<User | null>;
    getUser(id: string, role?: RoleId): Rx.Observable<User>;
    setUserActivate(id: string, isActive: boolean): Rx.Observable<void>;
    updateUser(user: User): Rx.Observable<void>;
    createUser(user: User): Rx.Observable<void>;
    requestPasswordReset(email: string): Rx.Observable<void>;
    confirmPasswordReset(userId: string, token: string, password: string): Rx.Observable<void>;
    getStudyRegionWithPostalCode(postalCode: string): Rx.Observable<StudyRegion | null>;
    downloadAllParticipantInfo(): Rx.Observable<Blob>;
    getTestlabInfo(): Rx.Observable<TestlabResponse>;
    participate(
        region: StudyRegion,
        firstName: string,
        lastName: string,
        phoneNumber: string,
        email: string,
    ): Rx.Observable<void>;
}

export class DefaultUserRepository implements UserRepository {
    // Properties

    private readonly api: API;
    private readonly dateFormatter: DateFormatter;
    public currentUser: User | null = null;

    // Public functions

    public constructor(api: API, dateFormatter: DateFormatter) {
        this.api = api;
        this.dateFormatter = dateFormatter;
    }

    public getCurrentUserInfo(): Rx.Observable<User> {
        if (this.currentUser != null) {
            return Rx.of(this.currentUser);
        }
        return this.api.getCurrentUserInfo().pipe(
            RxOperators.map(this.getUserFromResponse.bind(this)),
            RxOperators.tap((user) => {
                this.currentUser = user;
            }),
        );
    }

    public getUser(id: string, role?: RoleId): Rx.Observable<User> {
        let call: Rx.Observable<UserInfoResponse[]>;
        if (role == RoleId.PARTICIPANT) {
            call = this.api.queryParticipants(`id==${id}`);
        } else {
            call = this.api.queryUsers(`id==${id}`);
        }
        return call.pipe(
            RxOperators.map((list) => {
                const users = list.map(this.getUserFromResponse.bind(this));
                if (users.length < 1) {
                    throw { message: "User not found!" };
                } else {
                    return users[0];
                }
            }),
        );
    }

    public queryUsers(roles: RoleId[], query: string | null, regions?: (StudyRegion | null)[]): Rx.Observable<User[]> {
        // Remove reserved characters
        // https://gitlab.innovattic.com/innovattic/innolib-backend/-/blob/develop/jooq/src/main/kotlin/com/innovattic/common/database/fiql/README.md
        [`'`, `"`, `(`, `)`, `;`, `,`, `=`, `!`, `~`, `<`, `>`, ` `].forEach((char) => {
            if (query != null) {
                query = query.replaceAll(char, "");
            }
        });
        if (query != null && query.length < 1 && roles.includes(RoleId.PARTICIPANT)) {
            return Rx.of([]);
        }
        let call: Rx.Observable<UserInfoResponse[]>;
        if (roles.includes(RoleId.PARTICIPANT)) {
            call = this.api.queryParticipants(this.getFIQLQuery(query, undefined, regions));
            if (roles.length > 1) {
                const userRoles = roles.filter((role) => role != RoleId.PARTICIPANT);
                call = call.pipe(
                    RxOperators.mergeMapTo((() =>
                        this.api.queryUsers(
                            this.getFIQLQuery(query, userRoles, regions),
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        )) as unknown as ObservableInput<any>),
                );
            }
        } else {
            call = this.api.queryUsers(this.getFIQLQuery(query, roles, regions));
        }

        return call.pipe(RxOperators.map((list) => list.map(this.getUserFromResponse.bind(this))));
    }

    public getParticipantByStudyNumber(studyNumber: string): Rx.Observable<User | null> {
        return this.api.queryParticipants(`profile.study_number==${studyNumber}`).pipe(
            RxOperators.flatMap((users) => Rx.of(users.length > 0 ? users[0] : null)),
            RxOperators.map((u) => u && this.getUserFromResponse(u)),
        );
    }

    public setUserActivate(id: string, isActive: boolean): Rx.Observable<void> {
        if (isActive) {
            return this.api.activateUser(id).pipe(RxOperators.ignoreElements());
        } else {
            return this.api.deactivateUser(id).pipe(RxOperators.ignoreElements());
        }
    }

    public updateUser(user: User): Rx.Observable<void> {
        return this.api.updateUser(this.getRequestBodyFromUser(user)).pipe(RxOperators.ignoreElements());
    }

    public createUser(user: User): Rx.Observable<void> {
        return this.api.createUser(this.getRequestBodyFromUser(user)).pipe(RxOperators.ignoreElements());
    }

    public requestPasswordReset(email: string): Rx.Observable<void> {
        return this.api.requestPasswordReset(email).pipe(RxOperators.ignoreElements());
    }

    public confirmPasswordReset(userId: string, token: string, password: string): Rx.Observable<void> {
        return this.api.confirmPasswordReset({ user_id: userId, token, password }).pipe(RxOperators.ignoreElements());
    }

    public getStudyRegionWithPostalCode(postalCode: string): Rx.Observable<StudyRegion | null> {
        return this.api.getRegion(postalCode.substr(0, 4)).pipe(
            RxOperators.catchError((error) => {
                const errorCode = (error as APIError).error_code;
                if (errorCode === APIErrorCode.REGION_NOT_SUPPORTED) {
                    return Rx.of(null);
                }
                throw error;
            }),
            RxOperators.map((response) => {
                return Object.values(StudyRegions).find((r) => r.id == response?.identifier) || null;
            }),
        );
    }

    public downloadAllParticipantInfo(): Rx.Observable<Blob> {
        return this.api.downloadAllParticipantData();
    }

    public getTestlabInfo(): Rx.Observable<TestlabResponse> {
        return this.api.getTestlabInfo();
    }

    public participate(
        region: StudyRegion,
        firstName: string,
        lastName: string,
        phoneNumber: string,
        email: string,
    ): Rx.Observable<void> {
        return this.api.participate({
            email: email,
            first_name: firstName,
            last_name: lastName,
            phone: phoneNumber,
            region: region.id,
        });
    }

    // Private functions

    private getFIQLQuery(query: string | null, roles?: RoleId[], regions?: (StudyRegion | null)[]): string {
        const fiqlQueries = [];
        if (query) {
            fiqlQueries.push(`(first_name=contains=${query},last_name=contains=${query},email=contains=${query})`);
        }
        if (regions) {
            const regionsQueries = [];
            const regionIds = regions.filter((r) => r != null).map((r) => `'${r!.id}'`);
            if (regionIds.length > 0) {
                regionsQueries.push(`region.identifier=in=(${regionIds.join(",")})`);
            }
            if (regions.includes(null)) {
                regionsQueries.push("region.identifier==null");
            }
            if (regionsQueries.length > 0) {
                fiqlQueries.push(`(${regionsQueries.join(",")})`);
            }
        }
        if (roles && roles.length > 0) {
            fiqlQueries.push(`role=in=(${roles.map((r) => `'${r}'`).join(",")})`);
        }
        return fiqlQueries.join(";");
    }

    private getUserFromResponse(model: UserInfoResponse): User {
        let prepServiceType = null;
        let visitFrequency = null;
        if (model.profile?.study_arm) {
            prepServiceType = model.profile?.study_arm.includes("online")
                ? StudyArmPrepServiceType.ONLINE
                : StudyArmPrepServiceType.OFFLINE;
            visitFrequency = model.profile?.study_arm.includes("4_visits")
                ? StudyArmVisitFrequency.THREE_MONTH
                : StudyArmVisitFrequency.SIX_MONTH;
        }

        return new User(
            model.id,
            model.email,
            model.first_name,
            model.last_name,
            model.locale,
            UserRole.fromJson(model),
            model.status as UserStatus,
            this.getStudyRegionFromIdentifier(model.region?.identifier),
            prepServiceType,
            visitFrequency,
            model.profile?.starter,
            (model.profile?.birth_date && new Date(model.profile.birth_date)) || null,
            model.profile?.phone_number || null,
            (model.profile?.gender && (model.profile.gender as Gender)) || null,
            model.profile?.hcv != undefined ? model.profile?.hcv : null,
            model.profile?.syfillis != undefined ? model.profile?.syfillis : null,
            model.profile?.study_number || null,
            model.profile?.address?.city || null,
            model.profile?.address?.postal_code || null,
            model.profile?.address?.street || null,
            model.profile?.address?.street_number || null,
            model.region_postal_code || null,
        );
    }

    private getRequestBodyFromUser(user: User): UserInfoResponse {
        let profile: UserProfileResponse | undefined;
        if (user.role.id == RoleId.PARTICIPANT) {
            let study_arm = user.prepServiceType == StudyArmPrepServiceType.ONLINE ? "online_" : "offline_";
            study_arm += user.visitFrequency == StudyArmVisitFrequency.THREE_MONTH ? "4_visits" : "2_visits";
            let address: AddressResponse | undefined = undefined;
            if (
                ValidationPattern.notBlank.test(user.city || "") &&
                ValidationPattern.notBlank.test(user.streetNumber || "") &&
                ValidationPattern.notBlank.test(user.street || "") &&
                ValidationPattern.postalCode.test(user.postalCode || "")
            ) {
                address = {
                    city: user.city!,
                    postal_code: user.postalCode!,
                    street: user.street!,
                    street_number: user.streetNumber!,
                };
            }
            profile = {
                birth_date: this.dateFormatter.formatYearMonthDay(user.birthDate!),
                gender: user.gender!,
                phone_number: user.phone!,
                study_arm: study_arm,
                study_number: user.studyNumber!,
                starter: user.starter!,
                hcv: user.hadHCV!,
                syfillis: user.hadSyfilis!,
                address: address,
            };
        }
        let region: StudyRegionResponse | undefined;
        if (user.region) {
            region = { identifier: user.region.id, name: user.region.name };
        }
        return {
            id: user.id,
            role: user.role.id,
            first_name: user.firstName,
            last_name: user.lastName,
            locale: user.locale,
            email: user.email,
            status: user.status,
            profile: profile,
            region: region,
            region_postal_code: user.regionPostalCode || undefined,
        };
    }

    private getStudyRegionFromIdentifier(id: string | undefined): StudyRegion | null {
        return Object.values(StudyRegions).find((sr) => sr.id == id) || null;
    }
}
