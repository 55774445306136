import React from "react";
import { Path } from "../App";

interface Props {}

export class DownloadApp extends React.Component<Props> {
    // Public functions

    constructor(props: Props) {
        super(props);
        this.redirectToDownloadLink();
    }

    public render(): React.ReactNode {
        return null;
    }

    // Private functions

    private redirectToDownloadLink(): void {
        const link = this.getDownloadLink();
        if (link) {
            window.location.href = link;
        } else {
            window.location.pathname = Path.public.downloadLinks;
        }
    }

    private getDownloadLink(): string | null {
        const userAgent = navigator.userAgent || navigator.vendor;
        if (userAgent.toLowerCase().includes("android")) {
            return process.env.REACT_APP_ANDROID_APP_DOWNLOAD_LINK!;
        }
        if (userAgent.includes("iPhone")) {
            return process.env.REACT_APP_iOS_APP_DOWNLOAD_LINK!;
        }
        return null;
    }
}
