import { Message, User } from "../../../domain/model";
import { BaseSubscriptionHandlerComponent } from "../../BaseSubscriptionHandlerComponent";
import React from "react";
import styled from "styled-components";
import { RoundButton } from "../../appearance/RoundButton";
import { Link } from "../../appearance/Link";
import { Path } from "../../App";
import { MessageList } from "../../message/MessageList";

const HeaderRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    > div {
        display: flex;
        flex-direction: row;
        align-items: center;

        > label {
            margin-right: 8px;
        }
    }
`;

interface Props {
    messages: Message[];
    user: User;
}

interface State {
    selectedMessage: Message | null;
}

export class ParticipantProfileMessages extends BaseSubscriptionHandlerComponent<Props, State> {
    // Public functions

    constructor(props: Props) {
        super(props);
        this.state = { selectedMessage: null };
    }

    public render(): React.ReactNode {
        return (
            <div>
                <HeaderRow>
                    <h4>Berichten</h4>
                    <Link to={Path.admin.participant.newMessage(this.props.user.id)}>
                        <RoundButton text={"Nieuw +"} />
                    </Link>
                </HeaderRow>
                <MessageList isLoading={false} messages={this.props.messages} />
            </div>
        );
    }
}
