import React from "react";
import styled from "styled-components";
import { BaseSubscriptionHandlerComponent } from "../../BaseSubscriptionHandlerComponent";
import { BackLink } from "../../appearance/BackLink";
import DI from "../../../di/DI";
import { TYPES } from "../../../di/Types";
import {
    fullNameOf,
    Gender,
    getGenderTranslationKey,
    StudyArmPrepServiceType,
    StudyArmVisitFrequency,
    User,
    UserPermission,
    UserStatus,
    ValidationPattern,
} from "../../../domain/model";
import { RouteComponentProps } from "react-router-dom";
import { LoadingPanel } from "../../appearance/LoadingPanel";
import { ParticipantEditViewModel } from "./ParticipantEditViewModel";
import { Colors } from "../../appearance/Colors";
import { InputFormatter, RoundInput } from "../../appearance/RoundInput";
import { SegmentedControl } from "../../appearance/SegmentedControl";
import { GroupRadioButton } from "../../appearance/GroupRadioButton";
import { RoundButton } from "../../appearance/RoundButton";
import { showError, showSuccess } from "../../../utils/MessageUtils";
import * as Rx from "rxjs";
import { Path } from "../../App";
import { AlertModal } from "../../appearance/AlertModal";
import { DateFormatter } from "../../../infrastructure/DateFormatter";
import i18n from "i18next";

const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    width: 90%;
    max-width: 550px;
`;
const Title = styled.h2`
    font-size: 28px;
    font-weight: bold;
    margin: 0;
`;
const Form = styled.form`
    background-color: ${Colors.lightBackground};
    border-radius: 12px;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 40px;
    margin-top: 16px;
    margin-bottom: 100px;
    > h4 {
        font-size: 20px;
        margin-bottom: 16px;
    }
    > div {
        margin-top: 16px;
    }
    > button {
        margin-top: 16px;
    }
`;

const AddressContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 16px 0 32px 0;
    > div {
        margin-bottom: 16px;
    }
`;
const StreetInputsContainer = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: flex-end;
    div:first-child {
        flex: 2;
        margin-right: 12px;
    }
    div:last-child {
        flex: 1;
    }
`;

const Divider = styled.span`
    width: 100%;
    height: 1px;
    background-color: ${Colors.homeBackground};
    margin-top: 32px;
`;

interface Props extends RouteComponentProps<{ id: string }> {}
interface State {
    currentUser: User | null;
    user: User | null;
    firstName: string | null;
    lastName: string | null;
    email: string | null;
    phone: string | null;
    birthDate: string | null;
    gender: Gender | null;
    hadHCV: boolean | null;
    hadSyfilis: boolean | null;
    prepServiceType: StudyArmPrepServiceType | null;
    visitFrequency: StudyArmVisitFrequency | null;
    starter: boolean | null;
    studyNumber: string | null;
    isSubmitting: boolean;
    isChangingStatus: boolean;
    streetName: string;
    houseNumber: string;
    postalCode: string;
    town: string;
    showSaveConfirm: boolean;
    fieldErrors: (keyof State)[];
}

export class ParticipantEdit extends BaseSubscriptionHandlerComponent<Props, State> {
    // Properties

    private readonly dateFormatter: DateFormatter = DI.get(TYPES.DateFormatter);
    private readonly viewModel: ParticipantEditViewModel = DI.get(TYPES.ParticipantEditViewModel);

    private get canChangeStudyData(): boolean {
        return this.state.user?.status == UserStatus.REGISTERED;
    }
    private get canChangeEmail(): boolean {
        return (
            this.state.user?.status == UserStatus.REGISTERED ||
            User.can(this.state.currentUser, UserPermission.CHANGE_PARTICIPANT_EMAIL)
        );
    }

    // Public functions

    public constructor(props: Props) {
        super(props);
        this.state = {
            currentUser: null,
            user: null,
            firstName: null,
            lastName: null,
            email: null,
            phone: null,
            birthDate: null,
            gender: null,
            hadHCV: null,
            hadSyfilis: null,
            prepServiceType: null,
            visitFrequency: null,
            starter: null,
            studyNumber: null,
            isSubmitting: false,
            isChangingStatus: false,
            streetName: "",
            houseNumber: "",
            postalCode: "",
            town: "",
            showSaveConfirm: false,
            fieldErrors: [],
        };
    }

    public componentDidMount(): void {
        this.assignToState(this.viewModel.fetchUser(this.props.match.params.id), (user) => ({
            user,
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            phone: user.phone,
            birthDate: (user.birthDate && this.dateFormatter.formatDayMonthYear(user.birthDate)) || null,
            gender: user.gender,
            hadHCV: user.hadHCV,
            hadSyfilis: user.hadSyfilis,
            prepServiceType: user.prepServiceType,
            visitFrequency: user.visitFrequency,
            starter: user.starter,
            studyNumber: user.studyNumber,
            streetName: user.street || "",
            houseNumber: user.streetNumber || "",
            postalCode: user.postalCode || "",
            town: user.city || "",
        }));
        this.assignToState(this.viewModel.getCurrentUserInfo(), (currentUser) => ({ currentUser }));
    }

    public render(): React.ReactNode {
        const allGenders = Object.values(Gender);
        return (
            <Container>
                <BackLink title={this.getBackRouteName()} to={this.getBackRoute()} />
                {this.state.user == null && <LoadingPanel />}
                {this.state.user && (
                    <Form action={"#"}>
                        <Title>{this.getPageTitle()}</Title>
                        <p>
                            Vul onderstaande gegevens aan om de deelname te activeren. Onderaan de pagina kan een
                            participant ook gedeactiveerd worden.
                        </p>
                        <h4>Persoonlijke gegevens</h4>
                        {this.renderInput("Voornaam", "firstName")}
                        {this.renderInput("Achternaam", "lastName")}
                        <SegmentedControl
                            oneItemPerLine
                            selectedBackground={"#ffffff"}
                            background={Colors.darkAccent}
                            textColor={Colors.background}
                            selectedTextColor={Colors.background}
                            adaptTextColor={false}
                            title={"Gender"}
                            selectedOption={this.state.gender}
                            onChange={(gender) => this.setState({ gender })}
                            options={allGenders}
                            displayValues={allGenders.map((gender) => i18n.t(getGenderTranslationKey(gender)))}
                        />
                        {this.renderInput("Geboortedatum (dd-mm-jjjj)", "birthDate", false, false, InputFormatter.DATE)}
                        {this.renderInput("E-mailadres", "email", true, !this.canChangeEmail)}
                        {this.renderInput("Telefoonnummer", "phone")}
                        {this.state.prepServiceType == StudyArmPrepServiceType.ONLINE && (
                            <AddressContainer>
                                <StreetInputsContainer>
                                    {this.renderInput("Straat en huisnummer", "streetName")}
                                    {this.renderInput("", "houseNumber")}
                                </StreetInputsContainer>
                                {this.renderInput("Postcode", "postalCode", false)}
                                {this.renderInput("Plaats", "town")}
                            </AddressContainer>
                        )}
                        <Divider />
                        <h4>Medische gegevens</h4>
                        <GroupRadioButton
                            title={"HCV gehad?"}
                            selectedOption={this.state.hadHCV}
                            onChange={(hadHCV) => this.setState({ hadHCV })}
                            options={[true, false]}
                            displayValues={["Ja", "Nee"]}
                        />
                        <GroupRadioButton
                            title={"Syfilis gehad?"}
                            selectedOption={this.state.hadSyfilis}
                            onChange={(hadSyfilis) => this.setState({ hadSyfilis })}
                            options={[true, false]}
                            displayValues={["Ja", "Nee"]}
                        />
                        <Divider />
                        <h4>Studiegegevens</h4>
                        {!this.canChangeStudyData && (
                            <p>Neem contact op met de beheerder van het onderzoek om de studiegegevens te wijzigen</p>
                        )}
                        <GroupRadioButton
                            isDisabled={!this.canChangeStudyData}
                            title={"Online of offline PrEP service"}
                            selectedOption={this.state.prepServiceType}
                            options={[StudyArmPrepServiceType.ONLINE, StudyArmPrepServiceType.OFFLINE]}
                            displayValues={["Online", "Offline"]}
                            onChange={(prepServiceType) => this.setState({ prepServiceType })}
                        />
                        <GroupRadioButton
                            isDisabled={!this.canChangeStudyData}
                            title={"Frequentie controlebezoeken"}
                            selectedOption={this.state.visitFrequency}
                            options={[StudyArmVisitFrequency.THREE_MONTH, StudyArmVisitFrequency.SIX_MONTH]}
                            displayValues={["Om de 3 maanden", "Om de 6 maanden"]}
                            onChange={(visitFrequency) => this.setState({ visitFrequency })}
                        />
                        <GroupRadioButton
                            isDisabled={!this.canChangeStudyData}
                            title={"Ervaring met PrEP"}
                            selectedOption={this.state.starter}
                            options={[false, true]}
                            displayValues={["Ervaring", "Geen ervaring"]}
                            onChange={(starter) => this.setState({ starter })}
                        />
                        {this.renderInput("Studienummer (ezi#)", "studyNumber", true, !this.canChangeStudyData)}
                        <RoundButton
                            type={"button"}
                            disabled={this.state.isSubmitting}
                            isLoading={this.state.isChangingStatus}
                            background={Colors.darkAccent}
                            textColor={Colors.background}
                            text={this.getChangeStatusButtonTitle()}
                            onClick={this.onChangeStatusButtonClick.bind(this)}
                        />
                        <RoundButton
                            disabled={this.state.isChangingStatus}
                            isLoading={this.state.isSubmitting}
                            onClick={this.onMainButtonClick.bind(this)}
                            fillWidth={true}
                            text={this.getMainButtonTitle()}
                        />
                    </Form>
                )}
                {this.state.showSaveConfirm && (
                    <AlertModal
                        title={"Controleer studiegegevens"}
                        onClosed={(confirmed) => {
                            this.setState({ showSaveConfirm: false });
                            if (confirmed) {
                                this.submitData(true);
                            }
                        }}
                    >
                        <p>
                            Zorg ervoor dat de studiegegevens correct zijn.
                            <br />
                            <strong>Deze actie kan niet ongedaan gemaakt worden.</strong>
                            <br />
                            <br />
                            <label>
                                Online of offline PrEP service:
                                <strong>
                                    {this.state.prepServiceType == StudyArmPrepServiceType.ONLINE
                                        ? " Online"
                                        : " Offline"}
                                </strong>
                            </label>
                            <br />
                            <label>
                                Frequentie controlebezoeken:
                                <strong>
                                    {this.state.visitFrequency == StudyArmVisitFrequency.THREE_MONTH
                                        ? " Om de 3 maanden"
                                        : " Om de 6 maanden"}
                                </strong>
                            </label>
                            <br />
                            <label>
                                Ervaring met PrEP: <strong>{this.state.starter ? "Geen ervaring" : "Ervaring"}</strong>
                            </label>
                            <br />
                            <label>
                                Studienummer: <strong>{this.state.studyNumber}</strong>
                            </label>
                            <br />
                            <br />
                        </p>
                    </AlertModal>
                )}
            </Container>
        );
    }

    private renderInput<K extends keyof State>(
        title: string,
        stateKey: K,
        fillWidth = true,
        disabled = false,
        formatter?: InputFormatter,
    ): React.ReactNode {
        return (
            <RoundInput
                formatter={formatter}
                disabled={disabled}
                errorOccurred={this.state.fieldErrors.includes(stateKey)}
                text={this.state[stateKey] as string | null}
                onTextChange={(value) => this.updateFields({ [stateKey]: value } as Pick<State, K>)}
                fillWidth={fillWidth}
                title={title}
            />
        );
    }

    private updateFields<K extends keyof State>(update: Pick<State, K>): void {
        this.setState({ fieldErrors: this.state.fieldErrors.filter((key) => !Object.keys(update).includes(key)) });
        this.setState(update);
    }

    private onChangeStatusButtonClick(e: React.MouseEvent): void {
        e.preventDefault();
        let call: Rx.Observable<void>;
        if (this.state.user?.status == UserStatus.INACTIVE) {
            call = Rx.concat(
                this.viewModel.assertNoOtherUserWithSameStudyNumberExists(
                    this.state.user!.id,
                    this.state.user!.studyNumber!,
                ),
                this.viewModel.activateUser(this.state.user!.id),
            );
        } else {
            call = this.viewModel.deactivateUser(this.state.user!.id);
        }
        this.setState({ isChangingStatus: true });
        call.subscribe({
            complete: () => {
                showSuccess("Succesvol ingediend");
                this.props.history.push(Path.admin.root);
            },
            error: (error) => {
                showError(error);
                this.setState({ isChangingStatus: false });
            },
        });
    }

    private onMainButtonClick(e: React.MouseEvent): void {
        e.preventDefault();
        const validationResult = this.validateForm();
        if (!validationResult.result) {
            showError(new Error(validationResult.message || i18n.t("common.validationFailed")));
            return;
        }
        this.submitData();
    }

    private submitData(saveConfirmed = false): void {
        if (!saveConfirmed && this.state.user?.status == UserStatus.REGISTERED) {
            this.setState({ showSaveConfirm: true });
            return;
        }
        const user = this.getUpdatedUser();
        let call: Rx.Observable<void>;
        if (this.state.user?.status == UserStatus.REGISTERED) {
            call = this.viewModel.activateRegisteredUser(user);
        } else {
            call = this.viewModel.editUser(user);
        }
        this.setState({ isSubmitting: true });
        const subscription = call.subscribe({
            complete: () => {
                showSuccess("Succesvol ingediend");
                this.props.history.push(this.getBackRoute());
            },
            error: (error) => {
                showError(error);
                this.setState({ isSubmitting: false });
            },
        });
        this.collectSubscription(subscription);
    }

    private validateForm(): { result: boolean; message?: string } {
        const fieldErrors: (keyof State)[] = [];
        let errorMessage: string | undefined = undefined;
        if (this.state.starter == null || this.state.visitFrequency == null || this.state.prepServiceType == null) {
            return { result: false, message: "Het invoeren van de studiegegevens is verplicht" };
        }
        if (!ValidationPattern.notBlank.test(this.state.firstName || "")) {
            fieldErrors.push("firstName");
        }
        if (!ValidationPattern.notBlank.test(this.state.lastName || "")) {
            fieldErrors.push("lastName");
        }
        if (!ValidationPattern.email.test(this.state.email || "")) {
            fieldErrors.push("email");
        }
        if (!ValidationPattern.date.test(this.state.birthDate || "")) {
            fieldErrors.push("birthDate");
        }
        if (!ValidationPattern.studyNumber.test(this.state.studyNumber || "")) {
            fieldErrors.push("studyNumber");
            errorMessage = "Het studienummer is onjuist";
        }
        if (this.state.prepServiceType == StudyArmPrepServiceType.ONLINE) {
            if (!ValidationPattern.notBlank.test(this.state.streetName)) {
                fieldErrors.push("streetName");
                errorMessage = "Het adres is onjuist";
            }
            if (!ValidationPattern.notBlank.test(this.state.houseNumber)) {
                fieldErrors.push("houseNumber");
                errorMessage = "Het adres is onjuist";
            }
            if (!ValidationPattern.postalCode.test(this.state.postalCode)) {
                fieldErrors.push("postalCode");
                errorMessage = "De postcode is onjuist (formaat: 1234 AA)";
            }
            if (!ValidationPattern.notBlank.test(this.state.town)) {
                fieldErrors.push("town");
                errorMessage = "Het adres is onjuist";
            }
        }
        this.setState({ fieldErrors });
        return { result: fieldErrors.length === 0, message: errorMessage };
    }

    private getBackRouteName(): string {
        if (this.state.user == null) {
            return "";
        }
        if (this.state.user.status == UserStatus.REGISTERED) {
            return `Dashboard`;
        } else {
            return fullNameOf(this.state.user);
        }
    }

    private getBackRoute(): string {
        if (this.state.user == null || this.state.user.status == UserStatus.REGISTERED) {
            return Path.admin.root;
        }

        return Path.admin.participant.profile(this.state.user.id);
    }

    private getChangeStatusButtonTitle(): string {
        if (this.state.user?.status == UserStatus.REGISTERED) {
            return "Verwijderen";
        } else if (this.state.user?.status == UserStatus.INACTIVE) {
            return "Activeren";
        } else {
            return "Participant deactiveren";
        }
    }

    private getMainButtonTitle(): string {
        if (this.state.user?.status == UserStatus.REGISTERED) {
            return "Participant activeren";
        } else {
            return "Gegevens wijzigen";
        }
    }

    private getPageTitle(): string {
        if (this.state.user?.status == UserStatus.REGISTERED) {
            return "Participant (de)activieren";
        } else {
            return "Gegevens wijzigen";
        }
    }

    private getUpdatedUser(): User {
        const overrideParams: Partial<User> = {
            firstName: this.state.firstName!,
            lastName: this.state.lastName!,
            email: this.state.email!,
            phone: this.state.phone!,
            birthDate: this.dateFormatter.getDateFromDayMonthYear(this.state.birthDate!),
            gender: this.state.gender!,
            hadHCV: this.state.hadHCV!,
            hadSyfilis: this.state.hadSyfilis!,
            street: this.state.streetName!,
            streetNumber: this.state.houseNumber!,
            postalCode: this.state.postalCode!,
            city: this.state.town!,
        };
        if (this.canChangeStudyData) {
            Object.assign(overrideParams, {
                prepServiceType: this.state.prepServiceType!,
                visitFrequency: this.state.visitFrequency!,
                starter: this.state.starter!,
                studyNumber: this.state.studyNumber,
            });
        }
        return { ...this.state.user!, ...overrideParams };
    }
}
