import styled, { keyframes, css, DefaultTheme, FlattenInterpolation } from "styled-components";
import PropTypes from "prop-types";
import React from "react";

interface Props {
    size: number;
    duration: number;
    color: string;
}

const loading = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const animation = (props: Props): FlattenInterpolation<DefaultTheme> => css`
    ${loading} ${props.duration}s infinite linear;
`;

const RotateSpin = styled.div`
    animation: ${animation};
    border: ${(props) => `3px solid ${props.color}55`};
    border-left: ${(props) => `3px solid ${props.color}`};
    border-radius: 50%;
    font-size: ${(props) => `${props.size}px`};
    height: 20px !important;
    width: 20px !important;
    position: relative;
    transform: translateZ(0);
    margin: 0 !important;
`;

const Loading = (props: Props): JSX.Element => <RotateSpin {...props} />;

Loading.propTypes = {
    color: PropTypes.string,
    duration: PropTypes.number,
    size: PropTypes.number,
};

Loading.defaultProps = {
    color: "#ffffff",
    duration: 1.1,
    size: 1.5,
};

export default Loading;
