import React from "react";
import { ContentPageContainer } from "../content/ContentPageContainer";
import i18n from "i18next";
import { BulletPointContainer, HeadlineContainer, translatedParagraph } from "../../appearance";
import { Path } from "../../App";
import { joinStudyRegions } from "../Utils";
import DI from "../../../di/DI";
import { TYPES } from "../../../di/Types";
import { AboutStudyViewModel } from "./AboutStudyViewModel";

export class AboutStudy extends React.Component {
    // Properties

    private readonly viewModel: AboutStudyViewModel = DI.get(TYPES.AboutStudyViewModel);

    // Public functions

    public render(): React.ReactNode {
        return (
            <ContentPageContainer>
                <HeadlineContainer>
                    <h1>{i18n.t("content.aboutStudy.title") as string}</h1>
                    <i>{translatedParagraph("content.aboutStudy.description", Path.public.participationInfo)}</i>
                </HeadlineContainer>
                <HeadlineContainer>
                    <h2>{i18n.t("content.aboutStudy.whatIsStudyTitle") as string}</h2>
                    {translatedParagraph(
                        "content.aboutStudy.whatIsStudyDesc",
                        joinStudyRegions({ lastSeparator: ",", addPrefix: true }),
                        "https://aidsfonds.nl/nieuws/deze-6-projecten-helpen-nederland-naar-0-nieuwe-hiv-infecties/",
                    )}
                </HeadlineContainer>
                <HeadlineContainer>
                    <h2>{i18n.t("content.aboutStudy.howIsResearchSetupTitle") as string}</h2>
                    {translatedParagraph("content.aboutStudy.howIsResearchSetupDesc1")}
                    <BulletPointContainer bulletType="numbers">
                        <ul>
                            {i18n.array("content.aboutStudy.howIsResearchSetupBulletPoints").map((bp, index) => (
                                <li key={index} dangerouslySetInnerHTML={{ __html: bp }}></li>
                            ))}
                        </ul>
                    </BulletPointContainer>
                    {translatedParagraph("content.aboutStudy.howIsResearchSetupDesc2")}
                </HeadlineContainer>
                <HeadlineContainer>
                    <h2>{i18n.t("content.aboutStudy.whoAreParticipantsTitle") as string}</h2>
                    {translatedParagraph(
                        "content.aboutStudy.whoAreParticipantsDesc",
                        "https://www.rivm.nl/Soa-seksueel-overdraagbare-aandoening/prep",
                        joinStudyRegions({ lastSeparator: i18n.t("common.or") }),
                    )}
                </HeadlineContainer>
                <HeadlineContainer>
                    <h2>{i18n.t("content.aboutStudy.whatIsMeasuredTitle") as string}</h2>
                    {translatedParagraph("content.aboutStudy.whatIsMeasuredDesc")}
                </HeadlineContainer>
                <HeadlineContainer>
                    <h2>{i18n.t("content.aboutStudy.howIsMeasuredTitle") as string}</h2>
                    {translatedParagraph("content.aboutStudy.howIsMeasuredDesc1")}
                    <BulletPointContainer>
                        <ul>
                            {i18n.array("content.aboutStudy.howIsMeasuredBulletPoints").map((bp, index) => (
                                <li key={index} dangerouslySetInnerHTML={{ __html: bp }}></li>
                            ))}
                        </ul>
                    </BulletPointContainer>
                    {this.viewModel.isRegistrationEnabled()
                        ? translatedParagraph(
                              "content.aboutStudy.howIsMeasuredDesc2",
                              Path.public.apply,
                              Path.public.privacy,
                          )
                        : translatedParagraph(
                              "content.aboutStudy.howIsMeasuredDesc2WithoutRegistration",
                              Path.public.privacy,
                          )}
                </HeadlineContainer>
                <HeadlineContainer>
                    <h2>{i18n.t("content.aboutStudy.howLongIsStudyTitle") as string}</h2>
                    {translatedParagraph("content.aboutStudy.howLongIsStudyDesc")}
                </HeadlineContainer>
                <HeadlineContainer>
                    <h2>{i18n.t("content.aboutStudy.whatAreResultsTitle") as string}</h2>
                    {translatedParagraph("content.aboutStudy.whatAreResultsDesc")}
                </HeadlineContainer>
            </ContentPageContainer>
        );
    }
}
