import { API } from "../../domain/API";
import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import {
    APIError,
    APIErrorCode,
    ConfirmPasswordResetRequest,
    EmptyMsg,
    InitiateOrderRequest,
    InitiateOrderResponse,
    LoginRequest,
    LoginResponse,
    MessageRequest,
    MessageResponse,
    SecretResponse,
    TestlabResponse,
    UserInfoResponse,
    ParticipantDetails,
} from "./models";
import { LocalUserPreferenceKeys, Session } from "../../domain/model";
import { LocalStorageRepository } from "../../domain/repositories";
import { EventResponse, PatchEventResponse } from "./models/EventResponse";
import { StudyRegionResponse } from "./models/StudyRegionResponse";
import { sha256 } from "js-sha256";

type CallMethod = "GET" | "POST" | "PUT" | "PATCH" | "DELETE";

export class RESTAPI implements API {
    // Properties

    public get errorObservable(): Rx.Observable<APIError> {
        return this.errorSubject.asObservable();
    }

    public get hasSession(): boolean {
        return this.session != null;
    }

    private errorSubject = new Rx.Subject<APIError>();
    private session: Session | null = null;

    // Public functions

    constructor(private readonly localStorageRepository: LocalStorageRepository) {
        const storedSession: Session | null = localStorageRepository.getValue(LocalUserPreferenceKeys.user.session);
        if (storedSession) {
            this.setSession(storedSession);
        }
    }

    public clearSession(): void {
        this.session = null;
        this.localStorageRepository.removeValue(LocalUserPreferenceKeys.user.session);
    }

    public setSession(session: Session): void {
        this.session = session;
        this.localStorageRepository.setValue(LocalUserPreferenceKeys.user.session, session);
    }

    public login(requestData: LoginRequest): Rx.Observable<LoginResponse> {
        return this.request("/login", "POST", requestData);
    }

    public requestPasswordReset(email: string): Rx.Observable<EmptyMsg> {
        return this.request("/requestPasswordReset", "POST", undefined, { email });
    }

    public confirmPasswordReset(requestData: ConfirmPasswordResetRequest): Rx.Observable<EmptyMsg> {
        return this.request("/resetPassword", "POST", requestData);
    }

    public generateTFASecret(): Rx.Observable<SecretResponse> {
        return this.request("/tfa/generateSecret", "POST");
    }

    public confirmTFA(totp: number): Rx.Observable<EmptyMsg> {
        return this.request("/tfa/confirm", "POST", { totp });
    }

    public requestDeleteAccount(): Rx.Observable<void> {
        return this.request("/user/request-deletion-email", "POST");
    }

    public getCurrentUserInfo(): Rx.Observable<UserInfoResponse> {
        return this.request("/user/me");
    }

    public queryUsers(query: string): Rx.Observable<UserInfoResponse[]> {
        return this.request("/user", "GET", undefined, { query });
    }

    public queryParticipants(query: string): Rx.Observable<UserInfoResponse[]> {
        return this.request("/user/participant", "GET", undefined, { query });
    }

    public activateUser(id: string): Rx.Observable<UserInfoResponse> {
        return this.request(`/user/${id}/activate`, "POST");
    }

    public deactivateUser(id: string): Rx.Observable<UserInfoResponse> {
        return this.request(`/user/${id}/deactivate`, "POST");
    }

    public updateUser(user: UserInfoResponse): Rx.Observable<UserInfoResponse> {
        const userId = user.id;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const body: Record<string, any> = user;
        delete body["id"];
        delete body["status"];
        return this.request(`/user/${userId}`, "PUT", body);
    }

    public createUser(user: UserInfoResponse): Rx.Observable<UserInfoResponse> {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const body: Record<string, any> = user;
        delete body["id"];
        delete body["status"];
        return this.request(`/user`, "POST", body);
    }

    public getRegion(postalCode: string): Rx.Observable<StudyRegionResponse> {
        return this.request(`/info/region/${postalCode}`, "GET");
    }

    public getEvents(userId: string): Rx.Observable<EventResponse[]> {
        return this.request(`/event/user/${userId}`, "GET");
    }

    public generateEvents(userId: string): Rx.Observable<EventResponse[]> {
        return this.request(`/event/user/${userId}/generate`, "POST");
    }

    public createEvent(
        userId: string,
        event: Partial<EventResponse> & Pick<EventResponse, "status" | "type">,
    ): Rx.Observable<EventResponse> {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const body: Record<string, any> = event;
        delete body["id"];
        delete body["created"];
        delete body["period"];
        return this.request(`/event/user/${userId}`, "POST", body);
    }

    public updateEvent(event: EventResponse): Rx.Observable<EventResponse> {
        const eventId = event.id;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const body: Record<string, any> = event;
        delete body["id"];
        delete body["created"];
        delete body["period"];
        return this.request(`/event/${eventId}`, "PUT", body);
    }

    public patchEvent(eventId: string, event: PatchEventResponse): Rx.Observable<EventResponse> {
        const body: Record<string, unknown> = event;
        return this.request(`/event/${eventId}`, "PATCH", body);
    }

    public setEventCompleted(eventId: string): Rx.Observable<EventResponse> {
        return this.request(`/event/${eventId}/complete`, "POST");
    }

    public getCurrentUserEvents(): Rx.Observable<EventResponse[]> {
        return this.request(`/event/user/me`, "GET");
    }

    public getMessages(query?: string, order?: string): Rx.Observable<MessageResponse[]> {
        const queryParams: Record<string, string> = {};
        if (query) {
            queryParams["query"] = query;
        }
        if (order) {
            queryParams["order"] = order;
        }
        return this.request("/message", "GET", undefined, queryParams);
    }

    public sendMessageToAll(message: MessageRequest): Rx.Observable<EmptyMsg> {
        return this.request(`/message/send`, "POST", message);
    }

    public sendMessageToParticipant(message: MessageRequest, participantId: string): Rx.Observable<EmptyMsg> {
        return this.request(`/message/send/${participantId}`, "POST", message);
    }

    public initiateOrder(order: InitiateOrderRequest): Rx.Observable<InitiateOrderResponse> {
        return this.request("/order", "POST", order);
    }

    public downloadAllParticipantData(): Rx.Observable<Blob> {
        return this.requestBlob("/data/all", "GET");
    }

    public getTestlabInfo(): Rx.Observable<TestlabResponse> {
        return this.request("/testlab", "GET");
    }

    public participate(details: ParticipantDetails): Rx.Observable<void> {
        const hash = sha256(`contact;${details.email}`);
        const headers = {
            Authorization: `DIGEST ${hash}`,
        };

        return this.request("/onboarding/contact", "POST", details, undefined, headers);
    }

    // Private functions

    private refreshToken(): Rx.Observable<LoginResponse> {
        const observable: Rx.Observable<LoginResponse> = this.request("/refresh", "POST");
        return observable.pipe(
            RxOperators.tap((response) => {
                const newSession = this.session;
                newSession!.accessToken = (response as LoginResponse).access_token;
                this.setSession(newSession!);
            }),
        );
    }

    private requestBlob<RequestType>(
        path: string,
        method: CallMethod = "GET",
        request?: RequestType,
        queryParams?: Record<string, string>,
        additionalOverrideHeaders?: Record<string, string>,
    ): Rx.Observable<Blob> {
        return this.makeRequest(path, method, request, queryParams, additionalOverrideHeaders, true);
    }

    private request<RequestType, ResponseType>(
        path: string,
        method: CallMethod = "GET",
        request?: RequestType,
        queryParams?: Record<string, string>,
        additionalOverrideHeaders?: Record<string, string>,
    ): Rx.Observable<ResponseType> {
        return this.makeRequest(path, method, request, queryParams, additionalOverrideHeaders, false);
    }

    private makeRequest<RequestType, ResponseType>(
        path: string,
        method: CallMethod,
        request?: RequestType,
        queryParams?: Record<string, string>,
        additionalOverrideHeaders?: Record<string, string>,
        returnsBlob = false,
    ): Rx.Observable<ResponseType> {
        const source = returnsBlob
            ? this.fetchAsObservableBlob(path, method, request, queryParams, additionalOverrideHeaders)
            : this.fetchAsObservable(path, method, request, queryParams, additionalOverrideHeaders);
        return source.pipe(
            RxOperators.catchError((error) => {
                if (this.canFixErrorByRefreshingToken(path, error)) {
                    return this.refreshToken().pipe(
                        // repeat the request after refreshing the token
                        RxOperators.mergeMap(() => {
                            return this.makeRequest(
                                path,
                                method,
                                request,
                                queryParams,
                                additionalOverrideHeaders,
                                returnsBlob,
                            );
                        }),
                    );
                } else {
                    throw error;
                }
            }),
            RxOperators.tap({ error: (error) => this.errorSubject.next(error) }),
            RxOperators.map((value) => value as ResponseType),
        );
    }

    private canFixErrorByRefreshingToken(path: string, error: Error): boolean {
        const errorCode = (error as APIError).error_code;
        if (!errorCode) {
            return false;
        }
        // the error happened while refreshing the token
        if (path === "/refresh") {
            return false;
        }
        return errorCode === APIErrorCode.TOKEN_EXPIRED || errorCode === APIErrorCode.UNAUTHORIZED;
    }

    private fetchAsObservable<ResponseType, RequestType>(
        path: string,
        method: CallMethod,
        request?: RequestType,
        queryParams?: Record<string, string>,
        additionalOverrideHeaders?: Record<string, string>,
    ): Rx.Observable<ResponseType> {
        const url = this.getFullUrlFromPathAndParams(path, queryParams);
        return new Rx.Observable<ResponseType>((emitter) => {
            fetch(url, this.getRequestInit(path, method, request, additionalOverrideHeaders))
                .then(async (response) => {
                    const body = await response.text();
                    if (!response.ok) {
                        this.handleErrorResponse(response, body);
                    } else {
                        if (body.length > 0) {
                            emitter.next(JSON.parse(body) as ResponseType);
                        } else {
                            emitter.next({} as ResponseType);
                        }
                        emitter.complete();
                    }
                })
                .catch((error) => emitter.error(error));
        });
    }

    private fetchAsObservableBlob<RequestType>(
        path: string,
        method: CallMethod,
        request?: RequestType,
        queryParams?: Record<string, string>,
        additionalOverrideHeaders?: Record<string, string>,
    ): Rx.Observable<Blob> {
        return new Rx.Observable<Blob>((emitter) => {
            const url = this.getFullUrlFromPathAndParams(path, queryParams);
            fetch(url, this.getRequestInit(path, method, request, additionalOverrideHeaders))
                .then(async (response) => {
                    if (!response.ok) {
                        const body = await response.text();
                        this.handleErrorResponse(response, body);
                    } else {
                        const body = await response.blob();
                        emitter.next(body);
                        emitter.complete();
                    }
                })
                .catch((error) => emitter.error(error));
        });
    }

    private getFullUrlFromPathAndParams(path: string, queryParams?: Record<string, string>): string {
        let url = this.getBaseURL() + path;
        if (queryParams) {
            url += "?" + this.encodeQueryData(queryParams);
        }
        return url;
    }

    private handleErrorResponse(response: Response, body: string): void {
        if (body.length > 0) {
            const error = JSON.parse(body) as APIError;
            if (response.status === 401 && !error.error_code) {
                error.error_code = APIErrorCode.UNAUTHORIZED;
            }
            throw error;
        } else if (response.status === 401) {
            throw { error_code: APIErrorCode.UNAUTHORIZED } as APIError;
        } else {
            throw { message: "Unknown error" } as APIError;
        }
    }

    private getRequestInit<RequestType>(
        path: string,
        method = "GET",
        request?: RequestType,
        additionalOverrideHeaders: Record<string, string> = {},
    ): RequestInit {
        let body = undefined;
        if (request != undefined) {
            body = JSON.stringify(request);
        }
        const headers: Record<string, string> = {};
        headers["Content-Type"] = "application/json";
        if (this.hasSession) {
            if (path === "/refresh") {
                headers["Authorization"] = "Bearer " + this.session!.refreshToken;
            } else {
                headers["Authorization"] = "Bearer " + this.session!.accessToken;
            }
        }
        return {
            headers: { ...headers, ...additionalOverrideHeaders },
            method: method,
            body: body,
        };
    }

    private getBaseURL(): string {
        return process.env.REACT_APP_API_BASE_URL!;
    }

    private encodeQueryData(data: Record<string, string>): string {
        const ret = [];
        for (const key in data) {
            ret.push(encodeURIComponent(key) + "=" + encodeURIComponent(data[key]));
        }
        return ret.join("&");
    }
}
