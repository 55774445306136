import * as Rx from "rxjs";
import { BaseViewModel } from "../BaseViewModel";
import { EventRepository, UserRepository } from "../../domain/repositories";
import { Event, RoleId, User } from "../../domain/model";

export class EventEditViewModel extends BaseViewModel {
    public constructor(
        private readonly userRepository: UserRepository,
        private readonly eventRepository: EventRepository,
    ) {
        super();
    }

    public fetchUser(id: string): Rx.Observable<User> {
        return this.userRepository.getUser(id, RoleId.PARTICIPANT);
    }

    public fetchEvents(id: string): Rx.Observable<Event[]> {
        return this.eventRepository.getEventsForParticipant(id);
    }

    public updateEvent(eventUpdate: Event): Rx.Observable<void> {
        return this.eventRepository.updateEvent(eventUpdate);
    }
}
