import firebase from "firebase/app";
import "firebase/analytics";
import { AnalyticsEvent } from "./AnalyticsEvent";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Queue of events which were fired before analytics was initialized. Only stored for the duration of the session.
let eventBacklog: AnalyticsEvent[] = [];

export const initializeAnalytics = (): void => {
    if (firebase.apps.length > 0) {
        // Check if Firebase is already initialized
        console.warn("Firebase analytics already initialized");
    } else {
        // Initialize Firebase
        firebase.initializeApp(firebaseConfig);
        firebase.analytics();
        console.log("Firebase analytics initialized");
    }
    // Log any events which have already fired before initialization
    eventBacklog.forEach((event) => logAnalyticsEvent(event));
    eventBacklog = [];
};

export const logAnalyticsEvent = (event: AnalyticsEvent): void => {
    try {
        firebase.analytics().logEvent(event);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
        if (error.code === "app/no-app") {
            // Analytics is not initialized, store the event to be logged after initialization
            eventBacklog.push(event);
        } else {
            console.error("Error logging event", error);
        }
    }
};
