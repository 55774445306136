import React, { PropsWithChildren } from "react";
import styled from "styled-components";
import { LinksFooter } from "./LinksFooter";
import { BackLink } from "../../appearance";
import { Colors } from "../../appearance";
import { SectionContainer } from "./SectionContainer";
import i18n from "i18next";

const Container = styled.div`
    width: 100%;
    position: relative;
    justify-content: center;
    background: ${Colors.lightBackground};
`;
const ContentContainer = styled.div`
    max-width: 940px;
    display: flex;
    margin: auto;
    flex-direction: column;
    align-items: center;
    > div {
        display: flex;
        justify-content: space-between;
    }
    > a {
        align-self: flex-start;
        margin-left: 24px;
    }
    margin-bottom: 20px;
`;
const ChildrenContainer = styled.div`
    max-width: 700px;
    width: 90%;
    display: flex;
    margin: 0 30px;
    flex-direction: column;
`;
const ContentLinksContainer = styled(SectionContainer)`
    background-color: ${Colors.lightBackground};
    > div {
        margin: 25px 24px;
        background-color: ${Colors.homeBackground};
        border-radius: 24px;
        width: 100%;
        display: flex;
        justify-content: center;
        > div {
            max-width: 1000px;
            display: flex;
            flex-direction: row;
            flex-flow: row wrap;
            justify-content: center;
            padding: 36px 5px;
        }
        button {
            margin: 12px;
        }
    }
`;
ContentLinksContainer.defaultProps = { textColor: Colors.text };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface Props extends PropsWithChildren<any> {}

export class ContentPageContainer extends React.Component<Props> {
    // Public functions

    public constructor(props: Props) {
        super(props);
        this.state = {};
    }

    public render(): React.ReactNode {
        return (
            <Container id="content-top">
                <ContentContainer>
                    <BackLink title={i18n.t("common.back")} />
                    <ChildrenContainer>{this.props.children}</ChildrenContainer>
                </ContentContainer>
                <LinksFooter />
            </Container>
        );
    }
}
