import { Event, EventType, User } from "../../../domain/model";
import { BaseSubscriptionHandlerComponent } from "../../BaseSubscriptionHandlerComponent";
import React from "react";
import styled from "styled-components";
import { ToggleSwitch } from "../../appearance/ToggleSwitch";
import { ParticipantProfileEventItem } from "./ParticipantProfileEventItem";
import { Colors } from "../../appearance/Colors";
import { MultipleActionsButton, MultipleActionsButtonItem } from "../../appearance/MultipleActionsButton";
import * as Rx from "rxjs";
import { Path } from "../../App";
import { RouteComponentProps } from "react-router-dom";

const HeaderRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    > div {
        display: flex;
        flex-direction: row;
        align-items: center;

        > label {
            margin-right: 8px;
        }
    }
`;
const EventListContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
`;

const Header = styled.label`
    margin-top: 17px;
    margin-bottom: 12px;
    font-size: 17px;
    display: block;
`;

const Divider = styled.span`
    background-color: ${Colors.darkAccent};
    width: 100%;
    height: 2px;
    margin-top: 32px;
    margin-bottom: 30px;
    display: block;
`;

const HorizontalSpace = styled.span`
    width: 16px;
    height: 1px;
`;

const createNewEventOptions: MultipleActionsButtonItem[] = [
    { id: EventType.ONLINE_MEETING, title: "Online consult" },
    { id: EventType.OFFLINE_MEETING, title: "GGD consult" },
];

interface Props extends RouteComponentProps {
    events: Event[];
    user: User;
    createNewEvent: (type: EventType) => Rx.Observable<Event>;
}
interface State {
    showPastEvents: boolean;
    currentEvents: Record<string, Event[]>;
    pastEvents: Record<string, Event[]>;
}

export class ParticipantProfileEvents extends BaseSubscriptionHandlerComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            showPastEvents: false,
            currentEvents: this.getEvents(false),
            pastEvents: this.getEvents(true),
        };
    }
    public render(): React.ReactNode {
        return (
            <div>
                <HeaderRow>
                    <h4>Tijdlijn</h4>
                    <div>
                        <label>Volledige tijdlijn tonen</label>
                        <ToggleSwitch
                            onChange={(showPastEvents) => {
                                this.setState({ showPastEvents });
                            }}
                        />
                        <HorizontalSpace />
                        <MultipleActionsButton
                            title="Nieuw consult +"
                            items={createNewEventOptions}
                            onItemClicked={(item) => this.handleNewEvent(item.id as EventType)}
                        />
                    </div>
                </HeaderRow>
                {this.state.showPastEvents && this.renderEvents(this.state.pastEvents)}
                {this.state.showPastEvents && Object.keys(this.state.pastEvents).length > 0 && <Divider />}
                {this.renderEvents(this.state.currentEvents)}
            </div>
        );
    }

    private renderEvents(events: Record<string, Event[]>): React.ReactNode {
        return (
            <EventListContainer>
                {Object.keys(events).map((period) => {
                    return (
                        <div key={period}>
                            <Header>{this.getDisplayValueFromPeriod(period)}</Header>
                            {events[period].map((event: Event) => (
                                <ParticipantProfileEventItem
                                    key={event.id}
                                    user={this.props.user}
                                    event={event}
                                    disabled={Event.shouldBeDisabled(event, this.props.events)}
                                    canBeChanged={Event.canBeChanged(event)}
                                    canBePlanned={Event.canBePlanned(event, this.props.events)}
                                    isPast={Event.isPast(event)}
                                />
                            ))}
                        </div>
                    );
                })}
            </EventListContainer>
        );
    }

    private getEvents(pastEvents: boolean): Record<string, Event[]> {
        let events = this.props.events;
        events = events.filter((e) => (pastEvents ? Event.isPast(e) : !Event.isPast(e)));
        events = events.sort((e1, e2) => {
            const eventTypeOrder = [
                EventType.TESTLAB,
                EventType.ONLINE_MEETING,
                EventType.OFFLINE_MEETING,
                EventType.ORDER_PILLS,
                EventType.QUESTIONNAIRE,
                EventType.DBS,
            ];
            // Sort events based on period, if it's equal then type
            if (e1.period == e2.period) {
                return eventTypeOrder.indexOf(e1.type) > eventTypeOrder.indexOf(e2.type) ? 1 : -1;
            }
            return (e1.period || "") > (e2.period || "") ? 1 : -1;
        });

        const groupedByPeriod: Record<string, Event[]> = {};
        events.forEach((e) => {
            const p = e.period || "";
            groupedByPeriod[p] = groupedByPeriod[p] || [];
            groupedByPeriod[p].push(e);
        });
        return groupedByPeriod;
    }

    private getDisplayValueFromPeriod(period: string): string {
        if (!period) {
            return "";
        }

        const month = parseInt(period.replace("month_", ""));
        return "Maand " + month;
    }

    private handleNewEvent(eventType: EventType): void {
        const subscription = this.props.createNewEvent(eventType).subscribe(
            (createdEvent) => {
                this.props.history.push(Path.admin.event.edit(this.props.user.id, createdEvent.id));
            },
            (error) => console.error(error),
        );
        this.collectSubscription(subscription);
    }
}
