import i18n from "i18next";
import { RoundButton } from "../appearance";
import React, { FunctionComponent } from "react";

export const GoBackToApp: FunctionComponent = () => (
    <RoundButton
        fillWidth={true}
        text={i18n.t("common.goBackToApp")}
        onClick={() => {
            window.location.href = process.env.REACT_APP_OPEN_APP_LINK!;
        }}
    />
);
