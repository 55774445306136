import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import { SessionState } from "../model";
import { API } from "../API";
import { APIError, APIErrorCode } from "../../infrastructure/api/models";

export interface SessionRepository {
    state: Rx.Observable<SessionState>;
    tfaSetupUri: string | null;

    logInViaAccessToken(accessToken: string): Rx.Observable<void>;
    logIn(username: string, password: string, totp?: number, requestingDeleteAccount?: boolean): Rx.Observable<void>;
    confirmSetupTFA(totp: number): Rx.Observable<void>;
    requestDeleteAccount(): Rx.Observable<void>;

    logOut(): Rx.Observable<void>;
}

export class DefaultSessionRepository implements SessionRepository {
    // Properties

    public get state(): Rx.Observable<SessionState> {
        return this.stateSubject.asObservable();
    }

    public get tfaSetupUri(): string | null {
        return this.tfaUri;
    }

    private readonly stateSubject: Rx.BehaviorSubject<SessionState>;
    private readonly api: API;
    private tfaUri: string | null = null;

    // Public functions

    public constructor(api: API) {
        this.api = api;
        this.stateSubject = new Rx.BehaviorSubject<SessionState>(
            api.hasSession ? SessionState.LoggedIn : SessionState.LoggedOut,
        );
        this.subscribeToAPIErrors();
    }

    public logInViaAccessToken(accessToken: string): Rx.Observable<void> {
        return this.logOut().pipe(
            RxOperators.tap({
                complete: () => {
                    // Empty refresh token means the user will be logged out automatically once the access token is expired
                    this.api.setSession({ accessToken: accessToken, refreshToken: "" });
                    this.stateSubject.next(SessionState.LoggedIn);
                },
            }),
        );
    }

    public logIn(
        email: string,
        password: string,
        totp?: number,
        requestingDeleteAccount?: boolean,
    ): Rx.Observable<void> {
        // For admin panel we require TFA for every user, so we don't allow logging in without that
        this.stateSubject.next(SessionState.LoggingIn);
        return this.api.login({ email, password, totp }).pipe(
            RxOperators.mergeMap((value) => {
                this.api.setSession({ accessToken: value.access_token, refreshToken: value.refresh_token });
                if (requestingDeleteAccount) {
                    this.stateSubject.next(SessionState.RequestingDeleteAccount);
                    return Rx.EMPTY;
                }
                if (totp) {
                    this.stateSubject.next(SessionState.LoggedIn);
                    return Rx.EMPTY;
                } else {
                    return this.api.generateTFASecret().pipe(
                        RxOperators.tap((response) => {
                            this.tfaUri = `otpauth://totp/${encodeURI(email)}?secret=${response.secret}&issuer=eziprep`;
                            this.stateSubject.next(SessionState.LoggingInSetupTFA);
                        }),
                    );
                }
            }),
            RxOperators.catchError((error) => {
                const errorCode = (error as APIError).error_code;
                if (errorCode === APIErrorCode.TFA_REQUIRED) {
                    this.stateSubject.next(SessionState.LoggingInTFA);
                    return Rx.EMPTY;
                }
                this.clearSessionAndChangeState();
                throw error;
            }),
            RxOperators.ignoreElements(),
        );
    }

    public confirmSetupTFA(totp: number): Rx.Observable<void> {
        return this.api.confirmTFA(totp).pipe(RxOperators.ignoreElements());
    }

    public requestDeleteAccount(): Rx.Observable<void> {
        return this.api.requestDeleteAccount().pipe(RxOperators.ignoreElements());
    }

    public logOut(): Rx.Observable<void> {
        this.clearSessionAndChangeState();
        return Rx.EMPTY;
    }

    // Private functions

    private subscribeToAPIErrors(): void {
        this.api.errorObservable.subscribe((error) => {
            if (error.error_code === APIErrorCode.UNAUTHORIZED || error.error_code === APIErrorCode.TOKEN_EXPIRED) {
                this.stateSubject.next(SessionState.LoggingOutDueToUnauthorisedResponse);
                this.clearSessionAndChangeState();
            }
        });
    }

    private clearSessionAndChangeState(): void {
        this.api.clearSession();
        this.stateSubject.next(SessionState.LoggedOut);
    }
}
