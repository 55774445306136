import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ButtonsContainer, Colors, Link, RoundButton } from "../appearance";
import { ReactComponent as CookieIcon } from "../../../src/res/images/cookie.svg";
import i18n from "i18next";
import { Path } from "../App";
import { CookieConsent, getCookieConsent, storeCookieConsent } from "./Cookies";
import { initializeAnalytics } from "./analytics/Analytics";

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 8px;

    position: fixed;
    left: 16px;
    right: 16px;
    bottom: 16px;

    background: ${Colors.lightBackground};
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.25);
    border-radius: 16px;

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
    }
`;

const ContentContainer = styled.div`
    flex: 1;
    flex-direction: column;
    margin: 0px 16px 16px;
`;

const IconContainer = styled.div`
    margin-top: 16px;
    margin-left: 16px;
`;

export const CookieConsentDialog = (): JSX.Element | null => {
    const [cookieConsent, setCookieConsent] = useState<CookieConsent>(getCookieConsent());

    useEffect(() => {
        if (cookieConsent !== CookieConsent.UNDECIDED) {
            storeCookieConsent(cookieConsent);
        }
        if (cookieConsent === CookieConsent.ALL) {
            initializeAnalytics();
        }
    }, [cookieConsent]);

    const handleAcceptAll = (): void => {
        setCookieConsent(CookieConsent.ALL);
    };

    const handleAcceptFunctional = (): void => {
        setCookieConsent(CookieConsent.FUNCTIONAL);
    };

    // Only show dialog if no saved cookie consent state
    const dialogVisible = cookieConsent === CookieConsent.UNDECIDED;

    if (!dialogVisible) {
        return null;
    }

    return (
        <Container>
            <IconContainer>
                <CookieIcon />
            </IconContainer>
            <ContentContainer>
                <p>
                    {i18n.t("cookieConsent.description") as string}{" "}
                    <Link to={Path.public.privacy}>
                        <u>{i18n.t("cookieConsent.moreInformationLink") as string}</u>
                    </Link>
                </p>
                <ButtonsContainer responsive>
                    <RoundButton
                        text={i18n.t("cookieConsent.acceptAll")}
                        background={Colors.background}
                        textColor={Colors.textLight}
                        onClick={() => handleAcceptAll()}
                    />
                    <RoundButton
                        text={i18n.t("cookieConsent.acceptFunctional")}
                        background={Colors.darkAccent}
                        textColor={Colors.background}
                        onClick={() => handleAcceptFunctional()}
                    />
                </ButtonsContainer>
            </ContentContainer>
        </Container>
    );
};
