export enum SessionState {
    LoggedOut,
    LoggingIn,
    LoggingInTFA,
    LoggingInSetupTFA,
    LoggedIn,
    RequestingDeleteAccount,
    LoadingSession,
    LoggingOut,
    LoggingOutDueToUnauthorisedResponse,
}
