import React from "react";
import styled from "styled-components";
import { Colors } from "./Colors";

interface Props extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
    openInNewTab?: boolean;
}

const Anchor = ({ openInNewTab, children, ...rest }: Props): JSX.Element => {
    const target = openInNewTab ? "_blank" : undefined;
    const rel = openInNewTab ? "noreferrer noopener" : undefined;

    return (
        <a target={target} rel={rel} {...rest}>
            {children}
        </a>
    );
};

Anchor.defaultProps = { openInNewTab: true };

export const ExternalLink = styled(Anchor)<{ defaultStyling?: boolean }>`
    ${({ defaultStyling }) => !defaultStyling && `text-decoration: none; color: ${Colors.text};`}
`;
