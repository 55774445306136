import React from "react";
import i18n from "i18next";
import { ReactComponent as AppStoreIcon } from "../../../res/images/app_store.svg";
import { ReactComponent as GooglePlayIcon } from "../../../res/images/google_play.svg";
import styled from "styled-components";
import { ExternalLink } from "../../appearance";

const Container = styled.div`
    svg {
        height: 70px;
    }
`;

interface Props {}

export class AppDownloadLinks extends React.Component<Props> {
    // Public functions

    public render(): React.ReactNode {
        return (
            <Container>
                <p>{i18n.t("content.register.downloadIOSDescription") as string}</p>
                <ExternalLink href={process.env.REACT_APP_iOS_APP_DOWNLOAD_LINK}>
                    <AppStoreIcon />
                </ExternalLink>
                <p>{i18n.t("content.register.downloadAndroidDescription") as string}</p>
                <ExternalLink href={process.env.REACT_APP_ANDROID_APP_DOWNLOAD_LINK}>
                    <GooglePlayIcon />
                </ExternalLink>
            </Container>
        );
    }
}
