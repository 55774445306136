import React from "react";
import { BaseSubscriptionHandlerComponent } from "../BaseSubscriptionHandlerComponent";
import { RouteComponentProps } from "react-router-dom";
import i18n from "i18next";
import { Container, ContentContainer } from "./PrepOrderResultStyle";
import { GoBackToApp } from "./GoBackToApp";
import { StudyRegions } from "../../domain/model";
import { BulletPointContainer, Mailto, ReverseText } from "../appearance";
import { reverseString } from "../../utils/StringUtils";

interface Props extends RouteComponentProps {}
interface State {}

export class PrepOrderErrorResult extends BaseSubscriptionHandlerComponent<Props, State> {
    // Public functions

    public render(): React.ReactNode {
        return (
            <Container>
                <ContentContainer>
                    <h3>{i18n.t("orderResult.errorTitle") as string}</h3>
                    <p>{i18n.t("orderResult.failureText") as string}</p>
                    <BulletPointContainer>
                        <ul>
                            {Object.values(StudyRegions).map((r, index) => (
                                <li key={index}>
                                    <span>{`GGD ${r.name}: `}</span>
                                    <br />
                                    <Mailto email={r.eziPrepAppointmentsEmailAddress} obfuscate>
                                        <ReverseText>{reverseString(r.eziPrepAppointmentsEmailAddress)}</ReverseText>
                                    </Mailto>
                                </li>
                            ))}
                        </ul>
                    </BulletPointContainer>
                    <br />
                    <GoBackToApp />
                </ContentContainer>
            </Container>
        );
    }
}
