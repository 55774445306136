import { Event, EventStatus, EventType, StudyRegion, User, ValidationPattern } from "../model";
import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import { API } from "../API";
import { EventResponse } from "../../infrastructure/api/models/EventResponse";

export interface EventRepository {
    getEventsForRegion(region: StudyRegion): Rx.Observable<Event[]>;
    getEventsForParticipant(participantId: string): Rx.Observable<Event[]>;
    generateEvents(participantId: string): Rx.Observable<void>;
    createEvent(userId: string, eventType: EventType): Rx.Observable<Event>;
    updateEvent(event: Event): Rx.Observable<void>;
    setEventCompleted(eventId: string): Rx.Observable<EventResponse>;
    getCurrentUserEvents(): Rx.Observable<Event[]>;
}

export const PREP_PILLS_PRICE = 0.25; // 7.5 for 30 pills
export const PREP_DELIVERY_PRICE = 9.5;
export const PREP_NUMBER_OF_PILLS_IN_PACKAGE = 30;

export class DefaultEventRepository implements EventRepository {
    // Properties

    private readonly api: API;

    // Public functions

    public constructor(api: API) {
        this.api = api;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public getEventsForRegion(region: StudyRegion): Rx.Observable<Event[]> {
        // TODO: Connect to API when it's available
        // https://innovattic.atlassian.net/browse/GGD-149
        return Rx.of([]);
    }

    public getEventsForParticipant(participantId: string, participant?: User): Rx.Observable<Event[]> {
        return this.api
            .getEvents(participantId)
            .pipe(RxOperators.map((list) => list.map((model) => this.getEventFromResponse(model, participant))));
    }

    public generateEvents(participantId: string): Rx.Observable<void> {
        return this.api.generateEvents(participantId).pipe(RxOperators.ignoreElements());
    }

    public createEvent(userId: string, eventType: EventType): Rx.Observable<Event> {
        if (eventType !== EventType.OFFLINE_MEETING && eventType !== EventType.ONLINE_MEETING) {
            return Rx.throwError(
                `For now we only support creating ${EventType.OFFLINE_MEETING} and ${EventType.ONLINE_MEETING} events.`,
            );
        }
        return this.api
            .createEvent(userId, { status: EventStatus.CREATED, type: eventType })
            .pipe(RxOperators.map((event) => this.getEventFromResponse(event)));
    }

    public updateEvent(event: Event): Rx.Observable<void> {
        return this.api.updateEvent(this.getRequestBodyFromEvent(event)).pipe(RxOperators.ignoreElements());
    }

    public setEventCompleted(eventId: string): Rx.Observable<EventResponse> {
        return this.api.setEventCompleted(eventId);
    }

    public getCurrentUserEvents(): Rx.Observable<Event[]> {
        return this.api
            .getCurrentUserEvents()
            .pipe(RxOperators.map((list) => list.map((model) => this.getEventFromResponse(model))));
    }

    // Private functions

    private getEventFromResponse(model: EventResponse, user?: User): Event {
        return new Event(
            model.id,
            model.type as EventType,
            new Date(model.created),
            model.status as EventStatus,
            model.period,
            model.description,
            model.location,
            (model.url && ValidationPattern.link.test(model.url) && model.url) || null,
            (model.planned && new Date(model.planned)) || null,
            model.pills || null,
            user,
        );
    }

    private getRequestBodyFromEvent(event: Event): EventResponse {
        return {
            id: event.id,
            created: event.createDate.toISOString(),
            period: event.period,
            type: event.type,
            status: event.status,
            description: event.description,
            location: event.location,
            url: event.url,
            planned: event.plannedDate?.toISOString() || null,
            pills: event.numberOfPills,
        };
    }
}
