import React from "react";
import { Home } from "./home/Home";
import { Login } from "./login/Login";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { BaseSubscriptionHandlerComponent } from "./BaseSubscriptionHandlerComponent";
import { SessionState, StudyRegions } from "../domain/model";
import { History } from "history";
import GlobalStyles from "./appearance/GlobalStyles";
import styled from "styled-components";
import { SessionRepository } from "../domain/repositories";
import { TYPES } from "../di/Types";
import DI from "../di/DI";
import { ParticipantProfile } from "./participant/profile/ParticipantProfile";
import { ContentContainer } from "./appearance/ContentContainer";
import { ParticipantEdit } from "./participant/edit/ParticipantEdit";
import { NewUser } from "./user/NewUser";
import { UserProfile } from "./user/UserProfile";
import { EventEdit } from "./event/EventEdit";
import { LandingPage } from "./public/landingPage/LandingPage";
import { PasswordRecovery } from "./password/PasswordRecovery";
import { PrepOrderStart } from "./order/PrepOrderStart";
import { PrepOrderResult } from "./order/PrepOrderResult";
import { NewMessage } from "./message/NewMessage";
import { DownloadApp } from "./public/DownloadApp";
import { ApplyForStudy } from "./public/apply/ApplyForStudy";
import { AppDownloadLinks } from "./public/apply/AppDownloadLinks";
import { TestLabAuthenticate } from "./testlab/TestLabAuthenticate";
import { AboutStudy } from "./public/aboutStudy/AboutStudy";
import { Privacy } from "./public/Privacy";
import { Disclaimer } from "./public/Disclaimer";
import { Contact } from "./public/Contact";
import { AboutOnlinePrepService } from "./public/AboutOnlinePrepService";
import { ParticipationInfo } from "./public/participationInfo/ParticipationInfo";
import { Procedures } from "./public/Procedures";
import ScrollToTop from "./appearance/ScrollToTop";
import { CookieConsentDialog } from "./public/CookieConsentDialog";
import { ApplicationForm } from "./public/apply/ApplicationForm";
import { PrepOrderErrorResult } from "./order/PrepOrderErrorResult";
import { UIConfigRepository } from "./UIConfigRepository";
import { DeleteAccount } from "./deleteAccount/deleteAccount";

const Container = styled.div`
    display: flex;
    flex: 1;
`;

export const Path = {
    admin: {
        root: "/admin",
        login: "/admin/login",
        participant: {
            profile: (id: string): string => `/admin/participant/${id}`,
            edit: (id: string): string => `/admin/participant/${id}/edit`,
            newMessage: (id: string): string => `/admin/participant/${id}/newMessage`,
        },
        user: {
            newSuperuser: (region: string): string => `/admin/newSuperuser/${region}`,
            newCaretaker: "/admin/newCaretaker",
            newAdmin: "/admin/newAdmin",
            profile: (id: string): string => `/admin/user/${id}`,
            newMessage: `/admin/newMessage`,
        },
        event: {
            edit: (userId: string, eventId: string): string => `/admin/user/${userId}/event/${eventId}`,
        },
    },
    public: {
        home: "/",
        downloadApp: "/dl",
        downloadLinks: "/downloadLinks",
        user: {
            passwordRecovery: (id: string, token: string): string => `/user/${id}/passwordRecovery/${token}`,
            resetPassword: "/user/resetPassword",
            deleteAccount: "/user/deleteAccount",
        },
        order: {
            start: (accessToken: string, eventId: string): string => `/order/start/${eventId}/${accessToken}`,
            successResult: "/order/success/",
            errorResult: "/order/error/",
        },
        apply: "/studie/aanmelden",
        applicationForm: "/studie/aanmelden/start",
        testlab: {
            authenticate: (accessToken: string, eventId: string): string => `/testlab/start/${eventId}/${accessToken}`,
        },
        participationInfo: "/studie/deelnemen",
        aboutStudy: "/studie",
        aboutOnlinePrEPService: "/onlineprepservice",
        privacy: "/privacy",
        disclaimer: "/disclaimer",
        contact: "/contact",
        procedures: {
            amsterdam: StudyRegions.AMSTERDAM.proceduresUrl,
            rotterdam: StudyRegions.ROTTERDAM.proceduresUrl,
            haaglanden: StudyRegions.HAAGLANDEN.proceduresUrl,
            gelderland_zuid: StudyRegions.GELDERLAND_ZUID.proceduresUrl,
        },
    },
};

export class App extends BaseSubscriptionHandlerComponent {
    // Public properties

    private sessionRepository: SessionRepository = DI.get(TYPES.SessionRepository);
    private uiConfigRepository: UIConfigRepository = DI.get(TYPES.UIConfigRepository);
    private readonly router: React.RefObject<Router>;
    private get history(): History {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return (this.router.current as any).history as History;
    }

    // Lifecycle

    public constructor(props: Record<string, unknown>) {
        super(props);
        this.router = React.createRef();
    }

    // Public functions

    public componentDidMount(): void {
        this.subscribeToSessionState();
    }

    public render(): JSX.Element {
        return (
            <Container>
                <GlobalStyles />
                <Router ref={this.router}>
                    <ScrollToTop />
                    {this.renderRoute(Path.admin.login, Login, false)}
                    {this.renderRoute(Path.admin.root, Home)}

                    {this.renderRoute(Path.admin.participant.profile(":id"), ParticipantProfile)}
                    {this.renderRoute(Path.admin.participant.edit(":id"), ParticipantEdit)}

                    {this.renderRoute(Path.admin.user.newSuperuser(":region"), NewUser)}
                    {this.renderRoute(Path.admin.user.newCaretaker, NewUser)}
                    {this.renderRoute(Path.admin.user.newAdmin, NewUser)}
                    {this.renderRoute(Path.admin.user.profile(":id"), UserProfile)}

                    {this.renderRoute(Path.admin.event.edit(":userId", ":eventId"), EventEdit)}

                    {this.renderRoute(Path.admin.user.newMessage, NewMessage)}
                    {this.renderRoute(Path.admin.participant.newMessage(":participantId"), NewMessage)}

                    {/*Public routes*/}

                    {this.renderRoute(Path.public.user.passwordRecovery(":id", ":token"), PasswordRecovery)}
                    {this.renderRoute(Path.public.user.resetPassword, PasswordRecovery)}
                    {this.renderRoute(Path.public.user.deleteAccount, DeleteAccount)}

                    {this.renderRoute(Path.public.order.successResult, PrepOrderResult)}
                    {this.renderRoute(Path.public.order.errorResult, PrepOrderErrorResult)}
                    {this.renderRoute(Path.public.order.start(":accessToken", ":eventId"), PrepOrderStart)}

                    {this.renderRoute(Path.public.downloadApp, DownloadApp, false)}
                    {this.renderRoute(Path.public.downloadLinks, AppDownloadLinks)}
                    {this.renderRoute(Path.public.home, LandingPage)}
                    {this.uiConfigRepository.isRegistrationEnabled() &&
                        this.renderRoute(Path.public.applicationForm, ApplicationForm)}
                    {this.uiConfigRepository.isRegistrationEnabled() &&
                        this.renderRoute(Path.public.apply, ApplyForStudy)}
                    {this.renderRoute(
                        Path.public.testlab.authenticate(":accessToken", ":eventId"),
                        TestLabAuthenticate,
                    )}
                    {this.renderRoute(Path.public.aboutStudy, AboutStudy)}
                    {this.renderRoute(Path.public.aboutOnlinePrEPService, AboutOnlinePrepService)}
                    {this.renderRoute(Path.public.participationInfo, ParticipationInfo)}
                    {this.renderRoute(Path.public.privacy, Privacy)}
                    {this.renderRoute(Path.public.disclaimer, Disclaimer)}
                    {this.renderRoute(Path.public.contact, Contact)}
                    {this.renderRoute(Path.public.procedures.amsterdam, Procedures)}
                    {this.renderRoute(Path.public.procedures.rotterdam, Procedures)}
                    {this.renderRoute(Path.public.procedures.haaglanden, Procedures)}
                    {this.renderRoute(Path.public.procedures.gelderland_zuid, Procedures)}
                    <CookieConsentDialog />
                </Router>
            </Container>
        );
    }
    // Private functions

    private renderRoute(
        path: string,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        component: React.ComponentType<any>,
        inContainer = true,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        extraProps?: any,
    ): React.ReactNode {
        return (
            <Route
                exact
                path={path}
                render={(routeComponentProps) => {
                    const props = { ...routeComponentProps, ...extraProps };
                    if (inContainer) {
                        return <ContentContainer>{React.createElement(component, props)}</ContentContainer>;
                    } else {
                        return React.createElement(component, props);
                    }
                }}
            />
        );
    }

    private subscribeToSessionState(): void {
        const subscription = this.sessionRepository.state.subscribe((state) => {
            this.handleNewSessionState(state);
        });
        this.collectSubscription(subscription);
    }

    private handleNewSessionState(state: SessionState): void {
        // Change the route
        const currentPath = this.history.location.pathname;
        if (state === SessionState.LoggedIn && currentPath === Path.admin.login) {
            this.history.push(Path.admin.root);
        } else if (
            state === SessionState.LoggedOut &&
            currentPath !== Path.admin.login &&
            currentPath.startsWith(Path.admin.root)
        ) {
            this.history.push(Path.admin.login);
        }
    }
}
