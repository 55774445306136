import styled from "styled-components";
import { RoundButton } from "./RoundButton";
import { Colors } from "./Colors";

export const LineButton = styled(RoundButton)`
    border-bottom: 2px solid ${Colors.background} !important;
    border-radius: 0 !important;
`;
LineButton.defaultProps = { horizontalPadding: 0, background: null, textColor: Colors.background };

export const BorderButton = styled(RoundButton)`
    border: 2px solid ${Colors.background} !important;
`;
BorderButton.defaultProps = { background: null, textColor: Colors.background };
