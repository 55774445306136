import React from "react";
import styled from "styled-components";
import { HashLink, HashLinkProps } from "react-router-hash-link";
import { Colors } from "./Colors";

const FixedNavLink = ({ to, ...rest }: HashLinkProps): JSX.Element => {
    const isSameLoc = location.pathname === to;
    return <HashLink to={isSameLoc ? "#top" : to} smooth={isSameLoc} replace={isSameLoc} {...rest} />;
};

export const Link = styled(FixedNavLink)`
    cursor: pointer;
    position: relative;
    text-decoration: none;
    color: ${Colors.text};
`;
