import { Message, MessageSource, MessageType } from "../model";
import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import { API } from "../API";
import { MessageRequest, MessageResponse } from "../../infrastructure/api/models";

export interface MessageRepository {
    sendMessageToParticipant(message: Message, participantId: string): Rx.Observable<void>;

    sendMessageToAll(message: Message): Rx.Observable<void>;

    getMessages(participantId?: string): Rx.Observable<Message[]>;
}

export class DefaultMessageRepository implements MessageRepository {
    // Properties

    private readonly api: API;

    // Public functions

    public constructor(api: API) {
        this.api = api;
    }

    public sendMessageToParticipant(message: Message, participantId: string): Rx.Observable<void> {
        return this.api
            .sendMessageToParticipant(this.getRequestBodyFromMessage(message), participantId)
            .pipe(RxOperators.ignoreElements());
    }

    public sendMessageToAll(message: Message): Rx.Observable<void> {
        return this.api.sendMessageToAll(this.getRequestBodyFromMessage(message)).pipe(RxOperators.ignoreElements());
    }

    public getMessages(participantId?: string): Rx.Observable<Message[]> {
        const call = participantId
            ? this.api.getMessages(`to.id==${participantId}`, "-date")
            : this.api.getMessages(undefined, "-date");
        return call.pipe(RxOperators.map((messages) => messages.map(this.getMessageFromResponse)));
    }

    // Private functions

    private getMessageFromResponse(model: MessageResponse): Message {
        let subject = model.subject;
        let message = model.message;
        switch (model.type) {
            case "reset_password":
                subject = "Wachtwoord instellen";
                message =
                    "dit bericht wordt door het systeem naar de participant gestuurd om een wachtwoord in te stellen.";
                break;
            case "registration":
                subject = "Registratie";
                message =
                    "Dit bericht wordt door het systeem naar de participant gestuurd om de registratie te bevestigen.";
                break;
            default:
                break;
        }

        return new Message(
            model.id,
            subject,
            message,
            model.source as MessageSource,
            new Date(model.date),
            model.type as MessageType,
            model.sender && {
                id: model.sender.id,
                firstName: model.sender.first_name,
                lastName: model.sender.last_name,
            },
            model.to && { id: model.to.id, firstName: model.to.first_name, lastName: model.to.last_name },
        );
    }

    private getRequestBodyFromMessage(message: Message): MessageRequest {
        return {
            source: message.source,
            subject: message.subject,
            message: message.message,
        };
    }
}
