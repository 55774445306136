import React from "react";
import styled from "styled-components";
import { BaseSubscriptionHandlerComponent } from "../BaseSubscriptionHandlerComponent";
import DI from "../../di/DI";
import { TYPES } from "../../di/Types";
import { RouteComponentProps } from "react-router-dom";
import { Colors } from "../appearance/Colors";
import { showError } from "../../utils/MessageUtils";
import { PasswordRecoveryViewModel } from "./PasswordRecoveryViewModel";
import { RoundInput } from "../appearance/RoundInput";
import { ValidationPattern } from "../../domain/model";
import { RoundButton } from "../appearance/RoundButton";
import i18n from "i18next";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 90%;
    max-width: 480px;
    background-color: ${Colors.lightBackground};
    border-radius: 12px;
    padding: 40px;
    margin: auto;
    h3 {
        font-size: 20px;
        margin-top: 0px;
        margin-bottom: 0px;
    }
    p {
        margin-top: 6px;
        margin-bottom: 24px;
    }
    button {
        margin-top: 36px;
    }
    form > div {
        margin-top: 16px;
    }
`;
const Title = styled.h3``;

enum Mode {
    REQUEST,
    CONFIRM,
}

interface Props extends RouteComponentProps<{ id?: string; token?: string }> {}
interface State {
    mode: Mode;
    password: string;
    passwordConfirm: string;
    email: string;
    fieldErrors: (keyof State)[];
    isSubmitting: boolean;
    submittedSuccessfully: boolean;
}

export class PasswordRecovery extends BaseSubscriptionHandlerComponent<Props, State> {
    // Properties

    private readonly viewModel: PasswordRecoveryViewModel = DI.get(TYPES.PasswordRecoveryViewModel);

    // Public functions

    public constructor(props: Props) {
        super(props);
        this.state = {
            mode: props.match.params.id && props.match.params.token ? Mode.CONFIRM : Mode.REQUEST,
            isSubmitting: false,
            password: "",
            passwordConfirm: "",
            email: "",
            fieldErrors: [],
            submittedSuccessfully: false,
        };
    }

    public render(): React.ReactNode {
        return (
            <Container>
                {!this.state.submittedSuccessfully && (
                    <form action={"#"}>
                        <h3>
                            {
                                i18n.t(
                                    this.state.mode == Mode.CONFIRM
                                        ? "passwordRecovery.confirm.title"
                                        : "passwordRecovery.request.title",
                                ) as string
                            }
                        </h3>
                        {this.state.mode == Mode.CONFIRM && (
                            <p>{i18n.t("passwordRecovery.confirm.passwordLength") as string}</p>
                        )}
                        {this.state.mode == Mode.CONFIRM &&
                            this.renderInput(i18n.t("passwordRecovery.confirm.newPassword"), "password")}
                        {this.state.mode == Mode.CONFIRM &&
                            this.renderInput(i18n.t("passwordRecovery.confirm.newPasswordConfirm"), "passwordConfirm")}
                        {this.state.mode == Mode.REQUEST &&
                            this.renderInput(i18n.t("passwordRecovery.request.yourEmail"), "email")}
                        <RoundButton
                            isLoading={this.state.isSubmitting}
                            fillWidth={true}
                            onClick={this.submit.bind(this)}
                            text={
                                this.state.mode == Mode.CONFIRM
                                    ? i18n.t("passwordRecovery.confirm.submit")
                                    : i18n.t("passwordRecovery.request.submit")
                            }
                        />
                    </form>
                )}
                {this.state.submittedSuccessfully &&
                    (this.state.mode == Mode.CONFIRM ? (
                        <div>
                            <Title>{i18n.t("passwordRecovery.confirm.successTitle") as string}</Title>
                            <p>{i18n.t("passwordRecovery.confirm.successDesc") as string}</p>
                        </div>
                    ) : (
                        <div>
                            <Title>{i18n.t("passwordRecovery.request.successTitle") as string}</Title>
                            <p>{i18n.t("passwordRecovery.request.successDesc") as string}</p>
                        </div>
                    ))}
            </Container>
        );
    }

    private renderInput<K extends keyof State>(title: string, stateKey: K): React.ReactNode {
        return (
            <RoundInput
                autoComplete={this.state.mode == Mode.CONFIRM ? "new-password" : "email"}
                type={this.state.mode == Mode.CONFIRM ? "password" : "email"}
                errorOccurred={this.state.fieldErrors.includes(stateKey)}
                text={this.state[stateKey] as string | null}
                onTextChange={(value) => this.updateFields({ [stateKey]: value } as Pick<State, K>)}
                fillWidth={true}
                title={title}
            />
        );
    }

    private updateFields<K extends keyof State>(update: Pick<State, K>): void {
        this.setState({ fieldErrors: this.state.fieldErrors.filter((key) => !Object.keys(update).includes(key)) });
        this.setState(update);
    }

    private submit(e: React.MouseEvent): void {
        e.preventDefault();
        const validation = this.validateForm();
        if (!validation.result) {
            showError(new Error(validation.message || i18n.t("common.validationFailed")));
            return;
        }
        const call =
            this.state.mode == Mode.REQUEST
                ? this.viewModel.requestPasswordReset(this.state.email!)
                : this.viewModel.resetPassword(
                      this.props.match.params.id!,
                      this.props.match.params.token!,
                      this.state.password,
                  );
        this.setState({ isSubmitting: true });
        call.subscribe({
            complete: () => {
                this.setState({ submittedSuccessfully: true, isSubmitting: false });
            },
            error: (error) => {
                showError(error);
                this.setState({ isSubmitting: false });
            },
        });
    }

    private validateForm(): { result: boolean; message?: string } {
        let message: string | undefined = undefined;
        const fieldErrors: (keyof State)[] = [];
        if (this.state.mode == Mode.CONFIRM) {
            if (!ValidationPattern.password.test(this.state.password)) {
                fieldErrors.push("password");
                message = i18n.t("passwordRecovery.confirm.passwordLength");
            }
            if (this.state.passwordConfirm != this.state.password) {
                fieldErrors.push("passwordConfirm");
                fieldErrors.push("password");
            }
        } else {
            if (!ValidationPattern.email.test(this.state.email)) {
                fieldErrors.push("email");
            }
        }
        this.setState({ fieldErrors });
        return { result: fieldErrors.length === 0, message };
    }
}
