import { SessionRepository } from "../../domain/repositories";
import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import { BaseViewModel } from "../BaseViewModel";
import { SessionState } from "../../domain/model";

export class DeleteAccountViewModel extends BaseViewModel {
    // Properties

    public get isBusy(): Rx.Observable<boolean> {
        return this.isBusySubject.asObservable();
    }

    public get requestingDeleteAccount(): Rx.Observable<boolean> {
        return this.sessionRepository.state.pipe(
            RxOperators.map((value) => value === SessionState.RequestingDeleteAccount),
        );
    }

    private isBusySubject = new Rx.BehaviorSubject<boolean>(false);

    // Lifecycle

    public constructor(private readonly sessionRepository: SessionRepository) {
        super();
    }

    // Public functions

    public login(email: string, password: string): Rx.Observable<void> {
        this.isBusySubject.next(true);
        return this.sessionRepository.logIn(email, password, undefined, true).pipe(
            RxOperators.tap({
                error: () => this.isBusySubject.next(false),
                complete: () => this.isBusySubject.next(false),
            }),
            RxOperators.ignoreElements(),
        );
    }

    public confirmDeleteAccount(): Rx.Observable<void> {
        return this.sessionRepository.requestDeleteAccount();
    }
}
