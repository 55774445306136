import React from "react";
import { ContentPageContainer } from "../content/ContentPageContainer";
import styled from "styled-components";
import i18n from "i18next";
import DI from "../../../di/DI";
import { TYPES } from "../../../di/Types";
import { ApplyForStudyViewModel } from "./ApplyForStudyViewModel";
import { StudyRegion, ValidationPattern } from "../../../domain/model";
import { showError } from "../../../utils/MessageUtils";
import { BaseSubscriptionHandlerComponent } from "../../BaseSubscriptionHandlerComponent";
import { Path } from "../../App";
import {
    BackButton,
    Colors,
    GroupRadioButton,
    HeadlineContainer,
    Link,
    RoundButton,
    RoundInput,
    translatedParagraph,
} from "../../appearance";
import { joinStudyRegions } from "../Utils";
import { AnalyticsEvent } from "../analytics/AnalyticsEvent";
import { logAnalyticsEvent } from "../analytics/Analytics";

const PostalCodeContainer = styled.div`
    width: 100%;
    margin: auto;
`;

const NavContainer = styled.div`
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    height: 150px;
    padding-top: 17px;
    border-top: 1px solid ${Colors.darkAccent};
    justify-content: space-between;
    padding-bottom: 80px;
`;

enum Page {
    POSTAL_CODE_QUESTION = 0,
    CANT_PARTICIPATE_POSTCODE = 1,
    PREP_QUESTION = 2,
    CANT_PARTICIPATE_PREP = 3,
    PARTICIPATE = 4,
    PARTICIPATION_SUCCESS = 5,
}

enum PrepUse {
    YES,
    NO,
}

interface Props {}
interface State {
    page: Page;
    prepUse: PrepUse | null;
    postalCode: string;
    isCheckingPostCode: boolean;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    fieldErrors: (keyof State)[];
    email: string;
    region: StudyRegion | null;
    isSubmitting: boolean;
}

export class ApplicationForm extends BaseSubscriptionHandlerComponent<Props, State> {
    // Properties

    private readonly viewModel: ApplyForStudyViewModel = DI.get(TYPES.ApplyForStudyViewModel);

    // Public functions

    public constructor(props: Props) {
        super(props);
        this.state = {
            page: Page.POSTAL_CODE_QUESTION,
            postalCode: "",
            region: null,
            prepUse: null,
            isCheckingPostCode: false,
            firstName: "",
            lastName: "",
            phoneNumber: "",
            email: "",
            fieldErrors: [],
            isSubmitting: false,
        };
    }

    public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
        if (prevState.page >= this.state.page) {
            return;
        }

        switch (this.state.page) {
            case Page.POSTAL_CODE_QUESTION:
                logAnalyticsEvent(AnalyticsEvent.APPLY_STEP_POSTCODE);
                break;
            case Page.PREP_QUESTION:
                logAnalyticsEvent(AnalyticsEvent.APPLY_STEP_USE_PREP);
                break;
            case Page.PARTICIPATE:
                logAnalyticsEvent(AnalyticsEvent.APPLY_STEP_GIVE_CONTACT_INFO);
                break;
            case Page.PARTICIPATION_SUCCESS:
                logAnalyticsEvent(AnalyticsEvent.APPLY_FINALIZED);
                break;
        }
    }

    public render(): React.ReactNode {
        return (
            <ContentPageContainer>
                {this.state.page == Page.POSTAL_CODE_QUESTION && this.renderPostalCode()}
                {this.state.page == Page.PREP_QUESTION && this.renderPrepQuestion()}
                {this.state.page == Page.CANT_PARTICIPATE_POSTCODE && this.renderCantParticipatePostalCode()}
                {this.state.page == Page.CANT_PARTICIPATE_PREP && this.renderCantParticipatePrepUse()}
                {this.state.page == Page.PARTICIPATE && this.renderParticipateForm()}
                {this.state.page == Page.PARTICIPATION_SUCCESS && this.renderParticipationSuccess()}
            </ContentPageContainer>
        );
    }

    // Private functions

    private renderPostalCode(): React.ReactNode {
        return (
            <>
                <HeadlineContainer>
                    <h1>{i18n.t("content.register.postalCodeTitle") as string}</h1>
                    {translatedParagraph("content.register.postalCodeDescription")}
                </HeadlineContainer>
                <PostalCodeContainer>
                    <h3>{i18n.t("content.register.postalCodeQuestion") as string}</h3>
                    <RoundInput
                        disabled={this.state.isCheckingPostCode}
                        text={this.state.postalCode}
                        placeholder={"1234"}
                        autoComplete={"postal-code"}
                        onTextChange={(postalCode) => this.setState({ postalCode })}
                    />
                    {this.renderNavigation({ onNext: this.checkPostalCode.bind(this) })}
                </PostalCodeContainer>
            </>
        );
    }
    private renderPrepQuestion(): React.ReactNode {
        return (
            <div>
                <HeadlineContainer>
                    <h1>{i18n.t("content.register.usePrepTitle") as string}</h1>
                </HeadlineContainer>
                <h3>{i18n.t("content.register.usePrepQuestion") as string}</h3>
                <GroupRadioButton
                    direction={"column"}
                    selectedOption={this.state.prepUse}
                    displayValues={[
                        i18n.t("content.register.usePrepAnswerYes"),
                        i18n.t("content.register.usePrepAnswerNo"),
                    ]}
                    options={[PrepUse.YES, PrepUse.NO]}
                    onChange={(prepUse) => this.setState({ prepUse })}
                />
                {this.renderNavigation({
                    onPrev: () => this.setState({ page: Page.POSTAL_CODE_QUESTION }),
                    onNext: this.checkPrepAnswer.bind(this),
                })}
            </div>
        );
    }

    private renderCantParticipatePostalCode(): React.ReactNode {
        return (
            <div>
                <HeadlineContainer>
                    <h1>{i18n.t("content.register.cantParticipateTitle") as string}</h1>
                    {translatedParagraph(
                        "content.register.cantParticipatePostalCodeDescription",
                        joinStudyRegions({
                            addPrefix: true,
                            lastSeparator: i18n.t("common.or"),
                        }),
                    )}
                </HeadlineContainer>
                <br />
                <Link to={Path.public.home}>
                    <RoundButton text={i18n.t("content.register.backToHome")} />
                </Link>
            </div>
        );
    }

    private renderCantParticipatePrepUse(): React.ReactNode {
        return (
            <div>
                <HeadlineContainer>
                    <h1>{i18n.t("content.register.cantParticipateTitle") as string}</h1>
                    {translatedParagraph(
                        "content.register.cantParticipatePrepDescription",
                        joinStudyRegions({
                            addPrefix: true,
                            lastSeparator: i18n.t("common.or"),
                            anchorHref: (r) => r.applyUrl,
                        }),
                    )}
                </HeadlineContainer>
                <br />
                <Link to={Path.public.home}>
                    <RoundButton text={i18n.t("content.register.backToHome")} />
                </Link>
            </div>
        );
    }

    private renderParticipateForm(): React.ReactNode {
        return (
            <div>
                <HeadlineContainer>
                    <h1>{i18n.t("content.register.participateTitle") as string}</h1>
                    {translatedParagraph("content.register.participateDescription", Path.public.participationInfo)}
                    <br />
                </HeadlineContainer>
                <form action={"#"}>
                    {this.renderInput(i18n.t("content.register.participateFormFirstName"), "firstName", "given-name")}
                    <br />
                    {this.renderInput(i18n.t("content.register.participateFormLastName"), "lastName", "family-name")}
                    <br />
                    {this.renderInput(
                        i18n.t("content.register.participateFormPhoneNumber"),
                        "phoneNumber",
                        "tel",
                        "tel",
                    )}
                    <br />
                    {this.renderInput(i18n.t("content.register.participateEmail"), "email", "email")}
                    <br />
                    {translatedParagraph("content.register.participateConsent", Path.public.privacy)}
                    <br />
                    <RoundButton
                        isLoading={this.state.isSubmitting}
                        onClick={this.submitParticipantInfo.bind(this)}
                        fillWidth={true}
                        text={i18n.t("content.register.participateSubmit")}
                    />
                </form>
            </div>
        );
    }

    private renderInput<K extends keyof State>(
        title: string,
        stateKey: K,
        autoComplete?: string,
        type?: string,
    ): React.ReactNode {
        return (
            <RoundInput
                autoComplete={autoComplete}
                type={type}
                errorOccurred={this.state.fieldErrors.includes(stateKey)}
                text={this.state[stateKey] as string | null}
                onTextChange={(value) => this.updateFields({ [stateKey]: value } as Pick<State, K>)}
                fillWidth={true}
                title={title}
            />
        );
    }

    private renderParticipationSuccess(): React.ReactNode {
        return (
            <div>
                <HeadlineContainer>
                    <h1>{i18n.t("content.register.participationSuccessTitle") as string}</h1>
                    <p>{i18n.t("content.register.participationSuccessDescription") as string}</p>
                </HeadlineContainer>
                <br />
                <Link to={Path.public.home}>
                    <RoundButton text={i18n.t("content.register.backToHome")} />
                </Link>
            </div>
        );
    }

    private renderNavigation(nav: { onPrev?: () => void; onNext?: () => void }): React.ReactNode {
        return (
            <NavContainer>
                {nav.onPrev ? <BackButton title={i18n.t("common.previous")} onClick={nav.onPrev} /> : <div />}
                {nav.onNext && (
                    <RoundButton
                        isLoading={this.state.isCheckingPostCode}
                        text={i18n.t("common.next")}
                        onClick={nav.onNext}
                    />
                )}
            </NavContainer>
        );
    }

    private checkPrepAnswer(): void {
        if (this.state.prepUse == null) {
            showError(new Error(i18n.t("content.register.prepUseIsNotSelectedError")));
            return;
        }
        const canParticipate = this.state.prepUse == PrepUse.YES;
        this.setState({ page: canParticipate ? Page.PARTICIPATE : Page.CANT_PARTICIPATE_PREP });
    }

    private checkPostalCode(): void {
        if (!ValidationPattern.simplePostalCode.test(this.state.postalCode)) {
            showError(new Error(i18n.t("content.register.postalCodeIsIncorrect")));
            return;
        }
        this.setState({ isCheckingPostCode: true });
        const subscription = this.viewModel.checkPostalCode(this.state.postalCode).subscribe({
            next: (region) => {
                this.setState({
                    isCheckingPostCode: false,
                    region: region,
                    page: region != null ? Page.PREP_QUESTION : Page.CANT_PARTICIPATE_POSTCODE,
                });
            },
            error: (error) => {
                showError(error);
                this.setState({ isCheckingPostCode: false });
            },
        });
        this.collectSubscription(subscription);
    }

    private validateParticipateForm(): boolean {
        const fieldErrors: (keyof State)[] = [];
        if (!ValidationPattern.notBlank.test(this.state.firstName || "")) {
            fieldErrors.push("firstName");
        }
        if (!ValidationPattern.notBlank.test(this.state.lastName || "")) {
            fieldErrors.push("lastName");
        }
        if (!ValidationPattern.notBlank.test(this.state.phoneNumber || "")) {
            fieldErrors.push("phoneNumber");
        }
        if (!ValidationPattern.email.test(this.state.email || "")) {
            fieldErrors.push("email");
        }
        this.setState({ fieldErrors });
        return fieldErrors.length === 0;
    }

    private submitParticipantInfo(e: React.MouseEvent): void {
        e.preventDefault();
        if (!this.validateParticipateForm()) {
            showError(new Error(i18n.t("common.validationFailed")));
            return;
        }
        this.setState({ isSubmitting: true });
        const s = this.state;
        this.viewModel.submitParticipant(s.region!, s.firstName, s.lastName, s.phoneNumber, s.email).subscribe({
            error: (e) => {
                showError(e);
                this.setState({ isSubmitting: false });
            },
            complete: () => {
                this.setState({ isSubmitting: false, page: Page.PARTICIPATION_SUCCESS });
            },
        });
    }

    private updateFields<K extends keyof State>(update: Pick<State, K>): void {
        this.setState({ fieldErrors: this.state.fieldErrors.filter((key) => !Object.keys(update).includes(key)) });
        this.setState(update);
    }
}
