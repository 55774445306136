import React from "react";
import { ContentPageContainer } from "./content/ContentPageContainer";
import { HeadlineContainer } from "../appearance/HeadlineContainer";
import { translated, translatedParagraph } from "../appearance/Translated";
import { RouteComponentProps } from "react-router-dom";
import { StudyRegion, StudyRegions } from "../../domain/model";
import i18next from "i18next";
import { Mailto, ReverseText } from "../appearance";
import { reverseString } from "../../utils/StringUtils";

interface Props extends RouteComponentProps {}
interface State {
    region: StudyRegion;
}

export class Procedures extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);
        const path = this.props.location.pathname;
        this.state = { region: Object.values(StudyRegions).find((r) => r.proceduresUrl === path)! };
    }

    public render(): React.ReactNode {
        const regionName = `GGD ${this.state.region.name}`;
        const regionEmail = this.state.region.eziPrepAppointmentsEmailAddress;
        return (
            <ContentPageContainer>
                <HeadlineContainer>
                    {translated("h1", "content.procedures.title", regionName)}
                    {translatedParagraph("content.procedures.subtitle", regionName)}
                </HeadlineContainer>
                {this.renderSections()}
                <HeadlineContainer>
                    {translated("h3", "content.procedures.questionsTitle")}
                    <p>
                        {translated("span", "content.procedures.questionsDescription")}
                        <Mailto email={regionEmail} obfuscate>
                            <ReverseText>{reverseString(regionEmail)}</ReverseText>
                        </Mailto>
                    </p>
                </HeadlineContainer>
            </ContentPageContainer>
        );
    }

    private renderSections(): React.ReactNode {
        const regionKey = `content.procedures.${this.state.region.id}`;
        if (!i18next.exists(regionKey)) {
            return null;
        }
        return <>{i18next.array(regionKey).map((_, index) => this.renderSection(index))}</>;
    }

    private renderSection(index: number): React.ReactNode {
        const sectionKey = `content.procedures.${this.state.region.id}.${index}`;
        return (
            <HeadlineContainer key={index}>
                {translated("h3", `${sectionKey}.title`)}
                {translatedParagraph(`${sectionKey}.description`)}
            </HeadlineContainer>
        );
    }
}
