export const Colors = {
    background: "#271080",
    text: "#1E1E1E",
    textLight: "#fffcf6",
    disabledText: "#4a4a4a",
    lightBackground: "#ffffff",
    homeBackground: "#D1DAF2",
    darkAccent: "#a8bbed",
    borderFocused: "#6286e3",
    primaryTint: "#EE6338",
    primaryTintDisabled: "#edb29b",
    primaryTintHighlight: "#f57047",
    primaryTintActivated: "#f77b54",
    danger: "#e62a10",
};
