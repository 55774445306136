import React from "react";
import styled from "styled-components";
import { Colors } from "./Colors";
import { RoundButton } from "./RoundButton";

const Container = styled.div<{ isListOpen: boolean }>`
    display: flex;
    flex-direction: column;

    button {
        width: fit-content;
        border-radius: ${(props) => (props.isListOpen ? "12px 12px 0 0" : "12px")};
    }
`;

const List = styled.div`
    width: fit-content;
    position: absolute;
    z-index: 1;
    box-shadow: #00000061 0 20px 20px 0px;
    border-radius: 0 12px 12px 12px;

    > * {
        background-color: ${Colors.primaryTint};
        color: ${Colors.textLight};
        min-width: 200px;

        :hover {
            background-color: ${Colors.primaryTintHighlight};
        }

        :active {
            background-color: ${Colors.primaryTintActivated};
        }

        :first-child {
            border-radius: 0 12px 0 0;
        }

        :last-child {
            border-radius: 0 0 12px 12px;
        }
    }
`;

const ListItem = styled.div`
    padding: 16px;
    cursor: pointer;
`;

export interface MultipleActionsButtonItem {
    id: string;
    title: string;
}

interface Props {
    items: MultipleActionsButtonItem[];
    title: string;
    onItemClicked: (item: MultipleActionsButtonItem) => void;
}

interface State {
    isListOpen: boolean;
}

export class MultipleActionsButton extends React.Component<Props, State> {
    private containerRef = React.createRef<HTMLDivElement>();

    constructor(props: Readonly<Props>) {
        super(props);

        this.state = {
            isListOpen: false,
        };
    }

    public componentDidMount(): void {
        window.addEventListener("mouseup", this.handleClickOutside);
    }

    public componentWillUnmount(): void {
        window.removeEventListener("mouseup", this.handleClickOutside);
    }

    public render(): React.ReactNode {
        const { isListOpen } = this.state;

        return (
            <Container ref={this.containerRef} isListOpen={this.state.isListOpen}>
                <RoundButton onClick={() => this.toggleList()} text={this.props.title} />
                <div>{isListOpen && <List role="items">{this.listItems()}</List>}</div>
            </Container>
        );
    }

    private handleClickOutside = (event: MouseEvent): void => {
        if (this.containerRef.current!.contains(event.target as Node)) {
            return;
        }
        this.setState({
            isListOpen: false,
        });
    };

    private handleItemClick(item: MultipleActionsButtonItem): void {
        this.setState({
            isListOpen: false,
        });
        this.props.onItemClicked(item);
    }

    private toggleList(): void {
        this.setState((prevState: State) => ({
            isListOpen: !prevState.isListOpen,
        }));
    }

    private listItems(): React.ReactNode {
        const { items } = this.props;

        if (items.length == 0) {
            return <span>No items</span>;
        }

        return items.map((item) => (
            <ListItem onClick={() => this.handleItemClick(item)} key={item.id}>
                {item.title}
            </ListItem>
        ));
    }
}
