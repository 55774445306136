import { BaseViewModel } from "../../BaseViewModel";
import { EventRepository, UserRepository } from "../../../domain/repositories";
import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import { RoleId, User } from "../../../domain/model";
import { APIError, APIErrorCode } from "../../../infrastructure/api/models";

export class ParticipantEditViewModel extends BaseViewModel {
    // Properties

    // Public functions

    public constructor(
        private readonly userRepository: UserRepository,
        private readonly eventRepository: EventRepository,
    ) {
        super();
    }

    public getCurrentUserInfo(): Rx.Observable<User> {
        return this.userRepository.getCurrentUserInfo();
    }

    public fetchUser(id: string): Rx.Observable<User> {
        return this.userRepository.getUser(id, RoleId.PARTICIPANT);
    }

    public activateRegisteredUser(user: User): Rx.Observable<void> {
        return Rx.concat(
            this.assertNoOtherUserWithSameStudyNumberExists(user.id, user.studyNumber!),
            this.editUser(user),
            this.activateUser(user.id),
            this.generateEventsForUser(user.id),
            this.userRepository.requestPasswordReset(user.email),
        );
    }

    public editUser(user: User): Rx.Observable<void> {
        return this.userRepository.updateUser(user);
    }

    public deactivateUser(id: string): Rx.Observable<void> {
        return this.userRepository.setUserActivate(id, false);
    }

    public activateUser(id: string): Rx.Observable<void> {
        return this.userRepository.setUserActivate(id, true);
    }

    public assertNoOtherUserWithSameStudyNumberExists(userId: string, studyNumber: string): Rx.Observable<void> {
        return this.userRepository.getParticipantByStudyNumber(studyNumber).pipe(
            RxOperators.mergeMap((user) =>
                user != null && user.id !== userId
                    ? Rx.throwError(Error("Er is al een gebruiker met dit studienummer"))
                    : Rx.of(true),
            ),
            RxOperators.ignoreElements(),
        );
    }

    private generateEventsForUser(id: string): Rx.Observable<void> {
        return this.eventRepository.generateEvents(id).pipe(
            RxOperators.catchError((error) => {
                const apiError = error as APIError;
                // ignore the error if the events already generated
                if (apiError.error_code == APIErrorCode.EVENTS_ALREADY_GENERATED) {
                    return Rx.EMPTY;
                }
                throw error;
            }),
        );
    }
}
