import styled from "styled-components";

export const BulletPointContainer = styled.div<{ bulletType?: "dots" | "numbers" }>`
    h3 {
        font-size: 28px;
    }
    li {
        margin: 8px 0;
        font-size: 17px;
        line-height: 1.5;
    }
    ul {
        list-style-type: ${(props) => (props.bulletType != "numbers" ? "none" : "decimal")};
    }
    ${(props) =>
        props.bulletType != "dots"
            ? ""
            : `ul li::before {
        width: 20px;
        height: 8px;
        border-radius: 8px;
        background-color: #88d6bd;
        content: "";
        color: #88d6bd;
        font-weight: bold;
        display: inline-block;
        margin-left: -40px;
        margin-right: 20px;
        }`}
`;

BulletPointContainer.defaultProps = { bulletType: "dots" };
