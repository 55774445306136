import { EventStatus } from "./EventStatus";
import { EventType } from "./EventType";
import { User } from "./User";
import { endOfDay } from "../../utils/DateTimeUtils";

export class Event {
    // Static functions

    static isPast(event: Event): boolean {
        if (event.status == EventStatus.PLANNED && event.plannedDate != null) {
            return endOfDay(event.plannedDate) < new Date();
        }
        return false;
    }

    static canBePlanned(event: Event, allEvents: Event[]): boolean {
        return (
            (event.status == EventStatus.CREATED || event.status == EventStatus.CANCELLED) &&
            !this.shouldBeDisabled(event, allEvents)
        );
    }

    static shouldBeDisabled(event: Event, allEvents: Event[]): boolean {
        return this.numberOfNotPlannedEventsBefore(event, allEvents) > 1;
    }

    static canBeChanged(event: Event): boolean {
        return event.status == EventStatus.PLANNED && !this.isPast(event);
    }

    private static numberOfNotPlannedEventsBefore(event: Event, allEvents: Event[]): number {
        const currentEventPeriod = event.period;
        if (currentEventPeriod == null) {
            return 0;
        }

        return allEvents.filter(
            (e) =>
                // Count the number of other events that...
                // Have a period (are automatically generated)
                e.period != null &&
                // Are before this event's period
                e.period < currentEventPeriod &&
                // Are of the same type
                e.type === event.type &&
                // Are not completed
                e.status !== EventStatus.COMPLETED &&
                // Are not cancelled
                e.status !== EventStatus.CANCELLED &&
                // Are not in the past
                (!e.plannedDate || e.plannedDate > new Date()),
        ).length;
    }

    // Public functions

    public constructor(
        public readonly id: string,
        public readonly type: EventType,
        public readonly createDate: Date,
        public readonly status: EventStatus,
        public readonly period: string | null,
        public readonly description: string,
        public readonly location: string,
        public readonly url: string | null,
        public readonly plannedDate: Date | null,
        public readonly numberOfPills: number | null,
        public readonly participant?: User,
    ) {}
}
