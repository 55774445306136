import React from "react";
import { ContentPageContainer } from "./content/ContentPageContainer";
import i18n from "i18next";
import { BulletPointContainer } from "../appearance/BulletPointsContainer";
import { HeadlineContainer } from "../appearance/HeadlineContainer";
import { translated, translatedBulletPoints, translatedParagraph } from "../appearance/Translated";
import { joinStudyRegions } from "./Utils";
import styled from "styled-components";
import { Colors } from "../appearance/Colors";
import { StudyRegions } from "../../domain/model";
import { Link } from "react-router-dom";

const StepsContainer = styled.div`
    background-color: ${Colors.homeBackground};
    padding: 24px;
    border-radius: 24px;
    justify-content: center;
    margin-left: -24px;
    margin-right: -24px;

    @media (max-width: 768px) {
        margin-right: -18px;
    }
`;

export class AboutOnlinePrepService extends React.Component {
    public render(): React.ReactNode {
        return (
            <ContentPageContainer>
                <HeadlineContainer>
                    <h1>{i18n.t("content.aboutOnlinePrepService.title") as string}</h1>
                    {translatedParagraph(
                        "content.aboutOnlinePrepService.description",
                        joinStudyRegions({ lastSeparator: ",", addPrefix: true, anchorHref: (r) => r.proceduresUrl }),
                    )}
                </HeadlineContainer>
                <hr />
                <h2>{i18n.t("content.aboutOnlinePrepService.stepsHeader") as string}</h2>
                {this.renderSteps()}
                <HeadlineContainer>
                    <h2>{i18n.t("content.aboutOnlinePrepService.proceduresTitle") as string}</h2>
                    <BulletPointContainer>
                        <ul>
                            {Object.values(StudyRegions).map((r) => (
                                <li key={r.id}>
                                    {i18n.t("content.aboutOnlinePrepService.proceduresAt") as string}{" "}
                                    <Link to={r.proceduresUrl}>GGD {r.name}</Link>
                                </li>
                            ))}
                        </ul>
                    </BulletPointContainer>
                </HeadlineContainer>
            </ContentPageContainer>
        );
    }

    private renderSteps(): React.ReactNode {
        return (
            <StepsContainer>
                {i18n.array("content.aboutOnlinePrepService.steps").map((o, index) => this.renderStep(index))}
            </StepsContainer>
        );
    }

    private renderStep(index: number): React.ReactNode {
        const stepKey = `content.aboutOnlinePrepService.steps.${index}`;
        return (
            <HeadlineContainer key={index}>
                <h3>
                    {i18n.t("common.step") as string} {index + 1}:{" "}
                </h3>
                {translated("b", `${stepKey}.title`)}
                {translatedParagraph(`${stepKey}.description`)}
                {translatedBulletPoints(`${stepKey}.bulletPoints`, (bp) =>
                    bp.replace(
                        "{regions}",
                        joinStudyRegions({
                            lastSeparator: ",",
                            addPrefix: true,
                            anchorHref: (r) => r.proceduresUrl,
                        }),
                    ),
                )}
            </HeadlineContainer>
        );
    }
}
