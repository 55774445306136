export const ValidationPattern = {
    email: /[^@]+@[^@]+/,
    date: /^([0-2][0-9]|(3)[0-1])-(((0)[0-9])|((1)[0-2]))-\d{4}$/,
    time: /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/,
    studyNumber: /^ezi\d{4}$/,
    number: /\d+/,
    notBlank: /^(?!\s*$).+/,
    postalCode: /\d{4}\s\w{2}/,
    simplePostalCode: /\d{4}/,
    link: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
    password: /.{10,}/,
};
