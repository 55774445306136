import { BaseViewModel } from "../BaseViewModel";
import { SessionRepository, UserRepository } from "../../domain/repositories";
import * as Rx from "rxjs";
import { TestlabResponse } from "../../infrastructure/api/models";

export class TestLabAuthenticateViewModel extends BaseViewModel {
    public constructor(
        private readonly sessionRepository: SessionRepository,
        private readonly userRepository: UserRepository,
    ) {
        super();
    }

    // Public functions

    public login(accessToken: string): Rx.Observable<void> {
        return this.sessionRepository.logInViaAccessToken(accessToken);
    }

    public getTestlabInfo(): Rx.Observable<TestlabResponse> {
        return this.userRepository.getTestlabInfo();
    }
}
