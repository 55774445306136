import React from "react";
import styled from "styled-components";
import { BaseSubscriptionHandlerComponent } from "../BaseSubscriptionHandlerComponent";
import { BackLink } from "../appearance/BackLink";
import DI from "../../di/DI";
import { TYPES } from "../../di/Types";
import { fullNameOf, User, UserStatus } from "../../domain/model";
import { RouteComponentProps } from "react-router-dom";
import { LoadingPanel } from "../appearance/LoadingPanel";
import { UserProfileViewModel } from "./UserProfileViewModel";
import { Colors } from "../appearance/Colors";
import { RoundButton } from "../appearance/RoundButton";
import * as Rx from "rxjs";
import { showError, showSuccess } from "../../utils/MessageUtils";
import { Path } from "../App";

const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    width: 90%;
    max-width: 480px;
`;
const ContentContainer = styled.form`
    display: flex;
    background-color: ${Colors.lightBackground};
    border-radius: 12px;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 40px;
    margin-top: 16px;
    margin-bottom: 100px;
    > h3 {
        font-size: 28px;
        margin-top: 0px;
        margin-bottom: 8px;
    }
    > button {
        margin-top: 36px;
    }
`;

interface Props extends RouteComponentProps<{ id: string }> {}
interface State {
    user: User | null;
    isChangingStatus: boolean;
}

export class UserProfile extends BaseSubscriptionHandlerComponent<Props, State> {
    // Properties

    private readonly viewModel: UserProfileViewModel = DI.get(TYPES.UserProfileViewModel);

    // Public functions

    public constructor(props: Props) {
        super(props);
        this.state = { user: null, isChangingStatus: false };
        this.assignToState(this.viewModel.fetchUser(this.props.match.params.id), (user) => ({ user }));
    }

    public render(): React.ReactNode {
        return (
            <Container>
                <BackLink title={"Dashboard"} to={Path.admin.root} />
                {this.state.user == null && <LoadingPanel />}
                {this.state.user && (
                    <ContentContainer>
                        <h3>{fullNameOf(this.state.user)}</h3>
                        <span>{this.state.user.email}</span>
                        <RoundButton
                            isLoading={this.state.isChangingStatus}
                            background={Colors.darkAccent}
                            textColor={Colors.background}
                            text={this.state.user.status == UserStatus.ACTIVE ? "Verwijderen" : "Activeren"}
                            onClick={this.changeStatus.bind(this)}
                        />
                    </ContentContainer>
                )}
            </Container>
        );
    }

    private changeStatus(e: React.MouseEvent): void {
        e.preventDefault();
        let call: Rx.Observable<void>;
        if (this.state.user?.status == UserStatus.INACTIVE) {
            call = this.viewModel.activateUser(this.state.user!.id);
        } else {
            call = this.viewModel.deactivateUser(this.state.user!.id);
        }
        this.setState({ isChangingStatus: true });
        call.subscribe({
            complete: () => {
                showSuccess("Succesvol ingediend");
                this.props.history.push(Path.admin.root);
            },
            error: (error) => {
                showError(error);
                this.setState({ isChangingStatus: false });
            },
        });
    }
}
