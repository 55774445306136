import React from "react";
import styled from "styled-components";
import { SectionContainer } from "./SectionContainer";
import { Colors, ExternalLink, Link, Mailto, ReverseText } from "../../appearance";
import { StudyRegions } from "../../../domain/model";
import i18n from "i18next";
import { reverseString } from "../../../utils/StringUtils";
import { Path } from "../../App";
import DI from "../../../di/DI";
import { TYPES } from "../../../di/Types";
import { LinksFooterViewModel } from "./LinksFooterViewModel";

interface Props {}

const FooterContainer = styled(SectionContainer)`
    background-color: ${Colors.background};
    display: flex;
    flex-direction: row;
    flex-flow: row nowrap;
    align-items: flex-start;
    padding: 64px 0;
    justify-content: space-evenly;

    @media (max-width: 768px) {
        flex-flow: column;
        padding-left: 24px;
    }
`;

FooterContainer.defaultProps = { textColor: Colors.lightBackground };

const Column = styled.div`
    display: flex;
    flex-direction: column;
    color: ${Colors.lightBackground};

    > ul {
        list-style-type: none;
        padding: 0;

        > li {
            margin-bottom: 8px;

            * {
                font-size: 17px;
                font-weight: bold;
                text-decoration: underline;
                margin-top: 5px;
                cursor: pointer;
            }
        }
    }

    @media (max-width: 768px) {
        margin-top: 32px;
    }
`;

export class LinksFooter extends React.Component<Props> {
    // Properties

    private readonly viewModel: LinksFooterViewModel = DI.get(TYPES.LinksFooterViewModel);

    // Public functions

    public render(): React.ReactNode {
        return (
            <FooterContainer>
                {this.renderNavigationLinksColumn()}
                {this.renderContactDetailsColumn()}
                {this.renderPartnersColumn()}
            </FooterContainer>
        );
    }

    private renderNavigationLinksColumn(): React.ReactNode {
        return (
            <Column>
                <h3>{i18n.t("content.footer.columnNavigate") as string}</h3>
                <ul>
                    <li>
                        <Link to={Path.public.home}>{i18n.t("content.pages.home") as string}</Link>
                    </li>
                    {this.viewModel.isRegistrationEnabled() && (
                        <li>
                            <Link to={Path.public.apply}>{i18n.t("content.pages.register") as string}</Link>
                        </li>
                    )}
                    <li>
                        <Link to={Path.public.participationInfo}>
                            {i18n.t("content.pages.participationInfo") as string}
                        </Link>
                    </li>
                    <li>
                        <Link to={Path.public.aboutStudy}>{i18n.t("content.pages.aboutStudy") as string}</Link>
                    </li>
                    <li>
                        <Link to={Path.public.aboutOnlinePrEPService}>
                            {i18n.t("content.pages.aboutOnlinePrepService") as string}
                        </Link>
                    </li>
                    <li>
                        <Link to={Path.public.privacy}>{i18n.t("content.pages.privacy") as string}</Link>
                    </li>
                    <li>
                        <Link to={Path.public.contact}>{i18n.t("content.pages.contact") as string}</Link>
                    </li>
                    <li>
                        <Link to={Path.public.disclaimer}>{i18n.t("content.pages.disclaimer") as string}</Link>
                    </li>
                </ul>
            </Column>
        );
    }

    private renderContactDetailsColumn(): React.ReactNode {
        const email = StudyRegions.AMSTERDAM.eziPrepEmailAddress;
        return (
            <Column>
                <h3>{i18n.t("content.footer.columnContact") as string}</h3>
                <ul>
                    <li>
                        {i18n.t("common.email") as string}:&nbsp;
                        <Mailto email={email} obfuscate>
                            <ReverseText>{reverseString(email)}</ReverseText>
                        </Mailto>
                    </li>
                    <li>&nbsp;{/* This is here just for more spacing */}</li>
                    <li>
                        {i18n.t("common.address") as string}:&nbsp;
                        <br />
                        <ExternalLink href="https://goo.gl/maps/bFodcCsJa1HFP6t16">
                            GGD Amsterdam
                            <br />
                            Afdeling IZ, Onderzoek
                            <br />
                            Nieuwe Achtergracht 100
                            <br />
                            1018 WT Amsterdam
                        </ExternalLink>
                        <br />
                        Postbus 2200
                        <br />
                        1000 CE Amsterdam
                        <br />
                    </li>
                </ul>
            </Column>
        );
    }

    private renderPartnersColumn(): React.ReactNode {
        return (
            <Column>
                <h3>{i18n.t("content.footer.columnPartners") as string}</h3>
                <ul>
                    {Object.values(StudyRegions).map((region) => (
                        <li key={region.id}>
                            <ExternalLink href={region.website}>{`GGD ${region.name}`}</ExternalLink>
                        </li>
                    ))}
                    <li>
                        <ExternalLink href="https://www.soaaids.nl/nl">Soa Aids Nederland</ExternalLink>
                    </li>
                    <li>
                        <ExternalLink href="https://mantotman.nl/nl/">Man-tot-Man</ExternalLink>
                    </li>
                    <li>
                        <ExternalLink href="https://www.dcklinieken.nl/apotheek/">DC Apotheek</ExternalLink>
                    </li>
                </ul>
            </Column>
        );
    }
}
