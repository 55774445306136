import { BaseViewModel } from "../BaseViewModel";
import { EventRepository, SessionRepository, UserRepository } from "../../domain/repositories";
import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import { Event, EventStatus, EventType, User } from "../../domain/model";
import i18n from "i18next";
import {
    APIError,
    APIErrorCode,
    OrderPickupPlace,
    OrderReceivingType,
    OrderRequest,
} from "../../infrastructure/api/models";
import { OrderRepository } from "../../domain/repositories/OrderRepository";

export class PrepOrderStartViewModel extends BaseViewModel {
    // Public functions

    public constructor(
        private readonly userRepository: UserRepository,
        private readonly sessionRepository: SessionRepository,
        private readonly eventRepository: EventRepository,
        private readonly orderRepository: OrderRepository,
    ) {
        super();
    }

    public fetchUserAndEvent(accessToken: string, eventId: string): Rx.Observable<[User, Event]> {
        return Rx.concat(this.logInViaAccessToken(accessToken), Rx.of(true))
            .pipe(RxOperators.mergeMapTo(Rx.zip(this.getCurrentUserInfo(), this.fetchEvent(eventId))))
            .pipe(
                RxOperators.catchError((error) => {
                    switch ((error as APIError).error_code) {
                        case APIErrorCode.UNAUTHORIZED:
                            error.message = i18n.t("prepOrder.unauthorizedError");
                    }
                    throw error;
                }),
            );
    }

    // this function returns an url if the payment is online otherwise null
    public placeOrder(
        eventId: string,
        receivingType: OrderReceivingType,
        pickupPlace: OrderPickupPlace | null,
        recipientName: string,
        streetName: string,
        houseNumber: string,
        postalCode: string,
        town: string,
    ): Rx.Observable<string | null> {
        const request: OrderRequest = {
            event_id: eventId,
            receiving_type: receivingType,
            delivery_type: receivingType,
        };
        if (receivingType == OrderReceivingType.PICKUP) {
            request.pickup_place = pickupPlace!;
        }
        if (receivingType == OrderReceivingType.DELIVERY) {
            request.recipient_name = recipientName;
            request.address = {
                street: streetName,
                street_number: houseNumber,
                postal_code: postalCode,
                city: town,
            };
        }

        if (receivingType == OrderReceivingType.PICKUP && pickupPlace == OrderPickupPlace.GGD) {
            return this.eventRepository.setEventCompleted(eventId).pipe(RxOperators.mergeMap(() => Rx.of(null)));
        }

        return this.orderRepository
            .initiateOrder({
                delivery_type: request.delivery_type,
                event_id: request.event_id,
                address: request.address,
            })
            .pipe(RxOperators.map((response) => response.url));
    }

    // Private functions

    private logInViaAccessToken(accessToken: string): Rx.Observable<void> {
        return this.sessionRepository.logInViaAccessToken(accessToken);
    }

    private getCurrentUserInfo(): Rx.Observable<User> {
        return this.userRepository.getCurrentUserInfo();
    }

    private fetchEvent(eventId: string): Rx.Observable<Event> {
        return this.eventRepository.getCurrentUserEvents().pipe(
            RxOperators.map((events) => events.find((event) => event.id.toLowerCase() == eventId.toLowerCase())!),
            RxOperators.tap((event) => {
                if (event.type != EventType.ORDER_PILLS || event.status != EventStatus.PLANNED) {
                    throw Error(i18n.t("prepOrder.paymentNotPossibleError"));
                }
            }),
        );
    }
}
