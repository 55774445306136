import React, { CSSProperties } from "react";
import styled from "styled-components";
import { Colors } from "./Colors";
import * as Rx from "rxjs";
import { BaseSubscriptionHandlerComponent } from "../BaseSubscriptionHandlerComponent";

interface Props {
    style?: CSSProperties;
    disabled: boolean;
    value: boolean | null;
    observableValue: Rx.Observable<boolean> | null;
    onChange: (isOn: boolean) => void;
}

const Container = styled.div`
    line-height: 36px;
    height: 36px;
    width: 68px;
`;

const Input = styled.input`
    display: none;
    + label {
        cursor: pointer;
        position: relative;
        padding-left: 55px;
        transition: all 0.4s ease-in-out 0s;
    }
    + label:after,
    + label:before {
        content: "";
        position: absolute;
        transition: inherit;
    }
    + label:before {
        top: 50%;
        left: 4px;
        z-index: 1;
        width: 28px;
        content: "";
        height: 28px;
        margin-top: -14px;
        border-radius: 50%;
        position: absolute;
        transition: inherit;
        background: ${Colors.lightBackground};
    }
    + label:after {
        left: 0;
        width: 68px;
        margin-left: 0;
        height: 36px;
        background: ${Colors.background};
        border-radius: 34px;
    }
    :checked + label:after {
        background: ${Colors.background};
    }
    :checked + label:before {
        left: 36px;
    }
    :not(:checked) + label:after {
        background-color: ${Colors.darkAccent};
    }
`;

export class ToggleSwitch extends BaseSubscriptionHandlerComponent<Props, State> {
    // Static properties

    public static defaultProps = {
        disabled: false,
        value: null,
        observableValue: null,
    };

    // Properties

    private randomId = "ToggleSwitch" + Math.random().toString(36);

    // Lifecycle

    public constructor(props: Props) {
        super(props);
        this.state = {
            value: props.value,
        };
    }

    public componentDidMount(): void {
        this.subscribeToObservableValueIfNeeded();
    }

    // Public functions

    public render(): JSX.Element {
        return (
            <Container style={this.props.style}>
                <Input
                    disabled={this.props.disabled}
                    checked={this.state.value === true}
                    type={"checkbox"}
                    id={this.randomId}
                    onChange={this.onChange.bind(this)}
                />
                <label htmlFor={this.randomId} />
            </Container>
        );
    }

    // Private functions

    private onChange(event: React.ChangeEvent<HTMLInputElement>): void {
        this.props.onChange(event.target.checked);
        this.setState({ value: event.target.checked });
    }

    private subscribeToObservableValueIfNeeded(): void {
        if (this.props.observableValue == null) {
            return;
        }
        const subscription = this.props.observableValue.subscribe((value) => {
            this.setState({ value: value });
        });
        this.collectSubscription(subscription);
    }
}

interface State {
    value: boolean | null;
}
