export const SECOND = 1000;
export const MINUTE = SECOND * 60;
export const HOUR = MINUTE * 60;
export const DAY = HOUR * 24;

export function datesAreOnSameDay(first: Date, second: Date): boolean {
    return (
        first.getFullYear() === second.getFullYear() &&
        first.getMonth() === second.getMonth() &&
        first.getDate() === second.getDate()
    );
}
export function startOfDay(date: Date): Date {
    date.setHours(0, 0, 0, 0);
    return date;
}
export function endOfDay(date: Date): Date {
    date.setHours(23, 59, 59, 0);
    return date;
}
