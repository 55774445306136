import React, { Component } from "react";
import styled from "styled-components";
import { Colors } from "./Colors";
import { RoundButton } from "./RoundButton";

// TODO FIX gender list
interface Props<T> {
    title?: string;
    onChange: (option: T) => void;
    options: T[];
    displayValues: string[];
    selectedOption?: T;
    background: string;
    selectedBackground: string;
    textColor: string;
    selectedTextColor: string;
    adaptTextColor: boolean;
    isDisabled: boolean;
    oneItemPerLine?: boolean;
    separatorColor?: string;
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

const Separator = styled.span<{ color?: string }>`
    display: inline-block;
    width: 1px;
    height: 20px;
    background-color: ${(props) => props.color || "white"};

    @media (max-width: 768px) {
        display: none;
    }
`;

const ButtonsContainer = styled.div<{ background: string; oneItemPerLine?: boolean }>`
    padding: 4px;
    border-radius: 14px;
    background-color: ${(props) => props.background};
    display: ${(props) => (props.oneItemPerLine ? "flex" : "inline-flex")};
    ${(props) => (props.oneItemPerLine ? "flex-direction: column;" : "align-items: center;")}
    width: 100%;

    @media (max-width: 768px) {
        display: block;
    }
`;

const ButtonWrapper = styled.div<{ oneItemPerLine?: boolean }>`
    display: ${(props) => (props.oneItemPerLine ? "block" : "inline-block")};
    flex: 1;

    @media (max-width: 768px) {
        display: block;
    }

    button {
        @media (max-width: 768px) {
            width: 100%;
            text-align: left;
        }
    }
`;

const Title = styled.h4`
    font-size: 17px;
    font-weight: bold;
    margin: 0 0 4px 0;
    color: ${Colors.text};
`;

export class SegmentedControl<T> extends Component<Props<T>> {
    // Static properties

    public static defaultProps = {
        background: Colors.background,
        textColor: "#FFFFFF",
        selectedTextColor: Colors.background,
        selectedBackground: "#FFFFFF",
        adaptTextColor: true,
        isDisabled: false,
    };

    // Public functions

    public render(): JSX.Element {
        if (this.props.title) {
            return (
                <Container>
                    <Title>{this.props.title}</Title>
                    {this.renderButtons()}
                </Container>
            );
        } else {
            return this.renderButtons();
        }
    }

    // Private functions

    private renderButtons(): JSX.Element {
        return (
            <ButtonsContainer background={this.props.background} oneItemPerLine={this.props.oneItemPerLine}>
                {this.props.options.map((option, index) => {
                    const selection = this.props.selectedOption;
                    const isSelected = option === selection;
                    const selectionIndex = selection !== undefined ? this.props.options.indexOf(selection) : -1;
                    const drawSeparator =
                        this.props.oneItemPerLine !== true &&
                        index !== selectionIndex &&
                        index !== selectionIndex - 1 &&
                        index !== this.props.options.length - 1;

                    return (
                        <React.Fragment key={index}>
                            <ButtonWrapper oneItemPerLine={this.props.oneItemPerLine}>
                                <RoundButton
                                    disabled={this.props.isDisabled}
                                    background={isSelected ? this.props.selectedBackground : this.props.background}
                                    textColor={!isSelected ? this.props.textColor : this.props.selectedTextColor}
                                    text={this.props.displayValues[index]}
                                    horizontalPadding={20}
                                    fillWidth={true}
                                    onClick={(event) => this.onButtonClick(event, option)}
                                />
                            </ButtonWrapper>
                            {drawSeparator && <Separator color={this.props.separatorColor} />}
                        </React.Fragment>
                    );
                })}
            </ButtonsContainer>
        );
    }
    private onButtonClick(event: React.MouseEvent, option: T): void {
        event.preventDefault();
        this.props.onChange(option);
    }
}
