export const TYPES = {
    API: Symbol.for("API"),
    DateFormatter: Symbol.for("DateFormatter"),
    // Repositories
    SessionRepository: Symbol.for("SessionRepository"),
    LocalStorageRepository: Symbol.for("LocalStorageRepository"),
    UserRepository: Symbol.for("UserRepository"),
    EventRepository: Symbol.for("EventRepository"),
    MessageRepository: Symbol.for("MessageRepository"),
    OrderRepository: Symbol.for("OrderRepository"),
    UIConfigRepository: Symbol.for("UIConfigRepository"),
    // ViewModels
    HomeViewModel: Symbol.for("HomeViewModel"),
    LandingPageViewModel: Symbol.for("LandingPageViewModel"),
    AboutStudyViewModel: Symbol.for("AboutStudyViewModel"),
    ParticipationInfoViewModel: Symbol.for("ParticipationInfoViewModel"),
    LinksFooterViewModel: Symbol.for("LinksFooterViewModel"),
    LoginViewModel: Symbol.for("LoginViewModel"),
    DeleteAccountViewModel: Symbol.for("DeleteAccountViewModel"),
    ToolbarViewModel: Symbol.for("ToolbarViewModel"),
    ParticipantProfileViewModel: Symbol.for("ParticipantProfileViewModel"),
    ParticipantEditViewModel: Symbol.for("ParticipantEditViewModel"),
    NewUserViewModel: Symbol.for("NewUserViewModel"),
    UserProfileViewModel: Symbol.for("UserProfileViewModel"),
    EventEditViewModel: Symbol.for("EventEditViewModel"),
    PasswordRecoveryViewModel: Symbol.for("PasswordRecoveryViewModel"),
    PrepOrderStartViewModel: Symbol.for("PrepOrderStartViewModel"),
    NewMessageViewModel: Symbol.for("NewMessageViewModel"),
    ApplyForStudyViewModel: Symbol.for("ApplyForStudyViewModel"),
    TestLabAuthenticateViewModel: Symbol.for("TestLabAuthenticateViewModel"),
};
