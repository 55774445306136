import { BaseViewModel } from "../../BaseViewModel";
import { EventRepository, MessageRepository, UserRepository } from "../../../domain/repositories";
import * as Rx from "rxjs";
import { Event, EventType, Message, RoleId, User } from "../../../domain/model";

export class ParticipantProfileViewModel extends BaseViewModel {
    public constructor(
        private readonly userRepository: UserRepository,
        private readonly eventRepository: EventRepository,
        private readonly messageRepository: MessageRepository,
    ) {
        super();
    }

    public fetchUser(userId: string): Rx.Observable<User> {
        return this.userRepository.getUser(userId, RoleId.PARTICIPANT);
    }

    public fetchEvents(userId: string): Rx.Observable<Event[]> {
        return this.eventRepository.getEventsForParticipant(userId);
    }

    public fetchMessages(userId: string): Rx.Observable<Message[]> {
        return this.messageRepository.getMessages(userId);
    }

    public createEvent(userId: string, eventType: EventType): Rx.Observable<Event> {
        return this.eventRepository.createEvent(userId, eventType);
    }
}
