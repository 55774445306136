import React, { useState, useEffect } from "react";
import { shape, number } from "prop-types";

import Toast from "./Toast";
import { CTPosition, CTType } from "./types";

type CToastItem = {
    id: number;
    text: string;
    type: keyof CTType;
    hideAfter: number;
    onClick: () => void;
    onHide: () => void;
};

type CToastContainerProps = Partial<{
    toast: {
        position?: CTPosition;
    };
    hiddenID: number;
}>;

const defaultToasts: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key in CTPosition]: any[];
} = {
    "top-left": [],
    "top-center": [],
    "top-right": [],
    "bottom-left": [],
    "bottom-center": [],
    "bottom-right": [],
};

const ToastContainer: React.FC<CToastContainerProps> = ({ toast, hiddenID }) => {
    const [allToasts, setToasts] = useState(defaultToasts);

    useEffect(() => {
        if (toast) {
            setToasts((prevToasts) => {
                const position = toast.position || "top-center";
                return { ...prevToasts, [position]: [...prevToasts[position], toast] };
            });
        }
    }, [toast]);

    const handleRemove = (callback: (id: number, position: string) => void) => {
        return (id: number, position: CTPosition) => {
            setToasts((prevToasts) => {
                const toastPosition = position || "top-center";
                return {
                    ...prevToasts,
                    [toastPosition]: prevToasts[toastPosition].filter((item: CToastItem) => item.id !== id),
                };
            });
            typeof callback === "function" && callback(id, position);
        };
    };

    const rows = ["top", "bottom"];
    const groups = ["left", "center", "right"];

    return (
        <>
            {rows.map((row) => (
                <div key={`row_${row}`} className="ct-row">
                    {groups.map((group) => {
                        const type = `${row}-${group}` as CTPosition;
                        const className = ["ct-group", row === "bottom" ? "ct-flex-bottom" : ""].join(" ");
                        return (
                            <div key={type} className={className}>
                                {allToasts[type].map((item: CToastItem) => (
                                    <Toast
                                        key={`${type}_${item.id}`}
                                        {...item}
                                        id={item.id}
                                        text={item.text}
                                        type={item.type}
                                        onClick={item.onClick}
                                        hideAfter={item.hideAfter}
                                        show={hiddenID !== item.id}
                                        onHide={handleRemove(item.onHide)}
                                    />
                                ))}
                            </div>
                        );
                    })}
                </div>
            ))}
        </>
    );
};

ToastContainer.propTypes = {
    toast: shape({}),
    hiddenID: number,
};

ToastContainer.defaultProps = {
    toast: undefined,
    hiddenID: undefined,
};

export default ToastContainer;
