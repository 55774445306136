import React from "react";

export function toSearchString(searchParams: Record<string, string> = {}): string {
    return Object.keys(searchParams)
        .map((key) => `${key}=${encodeURIComponent(searchParams[key])}`)
        .join("&");
}

export function createMailtoLink(email: string, headers?: Record<string, string>): string {
    let link = `mailto:${email}`;
    if (headers) {
        link += `?${toSearchString(headers)}`;
    }
    return link;
}

interface Props extends React.AnchorHTMLAttributes<unknown> {
    obfuscate: boolean;
    email: string;
    headers?: Record<string, string>;
}

export class Mailto extends React.Component<Props> {
    public render(): React.ReactNode {
        return this.props.obfuscate ? this.renderObfuscatedLink() : this.renderLink();
    }

    private renderLink(): React.ReactNode {
        // We don't want to include the properties of this file on the anchor tag, so we make sure
        // that they will not be in the `...others` object
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { email, headers, obfuscate, children, ...others } = this.props;
        return (
            <a href={createMailtoLink(email, headers)} {...others}>
                {children}
            </a>
        );
    }

    private renderObfuscatedLink(): React.ReactNode {
        // We don't want to include the properties of this file on the anchor tag, so we make sure
        // that they will not be in the `...others` object
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { email, obfuscate, headers, children, ...others } = this.props;
        return (
            <a onClick={this.handleClick.bind(this)} href="mailto:obfuscated" {...others}>
                {children}
            </a>
        );
    }

    private handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void {
        event.preventDefault();
        const { email, headers } = this.props;
        window.open(createMailtoLink(email, headers), "_blank");
    }
}
