import React from "react";
import styled from "styled-components";
import { BaseSubscriptionHandlerComponent } from "../../BaseSubscriptionHandlerComponent";
import { RoundButton } from "../../appearance";
import { Colors } from "../../appearance";
import { ReactComponent as QuestionnaireIcon } from "../../../res/images/big_circle_questionnaire.svg";
import { ReactComponent as PrepIcon } from "../../../res/images/big_circle_prep.svg";
import { SectionContainer } from "../content/SectionContainer";
import { LinksFooter } from "../content/LinksFooter";
import { Link } from "../../appearance";
import { Path } from "../../App";
import { LineButton } from "../../appearance";
import { ReactComponent as LogoLarge } from "../../../res/images/logo_large.svg";
import i18n from "i18next";
import { ButtonsContainer } from "../../appearance";
import { translatedParagraph } from "../../appearance";
import { AnalyticsEvent } from "../analytics/AnalyticsEvent";
import { logAnalyticsEvent } from "../analytics/Analytics";
import DI from "../../../di/DI";
import { TYPES } from "../../../di/Types";
import { LandingPageViewModel } from "./LandingPageViewModel";

const Container = styled.div`
    display: block;
    flex-direction: column;
    width: 100%;
    position: relative;
`;

const HeaderContainer = styled(SectionContainer)`
    padding: 128px 24px;
    background-color: ${Colors.background};
    > div {
        max-width: 473px;
        width: 90%;

        @media (max-width: 768px) {
            width: 100%;
            max-width: 100%;
        }
    }

    @media (max-width: 768px) {
        padding-top: 32px;
    }
`;
HeaderContainer.defaultProps = { textColor: "#fffcf6" };

const IconDescContainer = styled(SectionContainer)`
    display: flex;
    flex-direction: row;
    flex-flow: row wrap;
    background-color: ${Colors.lightBackground};
    padding: 96px 0;
    p {
        font-size: 17px;
        line-height: 1.5;
    }
    > div {
        max-width: 525px;
        width: 90%;
        padding: 40px;

        @media (max-width: 768px) {
            width: 100%;
            padding: 24px;
        }
    }
    > svg {
        margin: 40px;
    }

    @media (max-width: 768px) {
        padding: 8px 0;
        flex-flow: column wrap;
    }
`;
IconDescContainer.defaultProps = { textColor: Colors.text };

const AboutContainer = styled(SectionContainer)`
    background-color: ${Colors.lightBackground};
    p {
        font-size: 17px;
    }
    > div {
        padding-top: 96px;
        padding-bottom: 96px;
        margin-left: 24px;
        margin-right: 24px;
        background-color: ${Colors.homeBackground};
        border-bottom-left-radius: 24px;
        border-bottom-right-radius: 24px;
        width: 100%;
        display: flex;
        justify-content: center;
        > div {
            max-width: 1000px;
            display: flex;
            flex-direction: row;
            flex-flow: row wrap;
            justify-content: center;
            > div {
                padding: 35px;
                max-width: 50%;

                @media (max-width: 768px) {
                    width: 100%;
                    max-width: 100%;
                    padding: 24px;
                }
            }
        }

        @media (max-width: 768px) {
            padding-top: 24px;
            padding-bottom: 24px;
            flex-flow: column;
            margin: 0;
        }
    }
`;
AboutContainer.defaultProps = { textColor: Colors.text };

interface Props {}

export class LandingPage extends BaseSubscriptionHandlerComponent<Props> {
    // Properties

    private readonly viewModel: LandingPageViewModel = DI.get(TYPES.LandingPageViewModel);

    // Public functions

    public constructor(props: Props) {
        super(props);
        this.state = {};
    }

    public componentDidMount(): void {
        logAnalyticsEvent(AnalyticsEvent.LANDING_PAGE_VISIT);
    }

    public render(): React.ReactNode {
        return (
            <Container>
                <HeaderContainer>
                    <div>
                        <LogoLarge />
                        {translatedParagraph("content.home.landingDescription")}
                        {this.viewModel.isRegistrationEnabled() && (
                            <ButtonsContainer>
                                <Link to={Path.public.apply}>
                                    <RoundButton text={i18n.t("common.register")} />
                                </Link>
                            </ButtonsContainer>
                        )}
                    </div>
                </HeaderContainer>
                <AboutContainer>
                    <div>
                        <div>
                            <div>
                                <h2>{i18n.t("content.home.aboutTheStudyTitle") as string}</h2>
                                {translatedParagraph("content.home.aboutTheStudyDesc")}
                                <Link to={Path.public.aboutStudy}>
                                    <LineButton text={i18n.t("common.readMore")} />
                                </Link>
                            </div>
                            <div>
                                <h2>{i18n.t("content.home.aboutOnlinePrepServiceTitle") as string}</h2>
                                {translatedParagraph("content.home.aboutOnlinePrepServiceDesc")}
                                <Link to={Path.public.aboutOnlinePrEPService}>
                                    <LineButton text={i18n.t("common.readMore")} />
                                </Link>
                            </div>
                        </div>
                    </div>
                </AboutContainer>
                <IconDescContainer>
                    <QuestionnaireIcon />
                    <div>
                        <h2>{i18n.t("content.home.applyForTheResearchTitle") as string}</h2>
                        {translatedParagraph("content.home.applyForTheResearchDesc")}

                        <ButtonsContainer>
                            <Link to={Path.public.participationInfo}>
                                <LineButton text={i18n.t("content.pages.participationInfo")} />
                            </Link>
                            {this.viewModel.isRegistrationEnabled() && (
                                <Link to={Path.public.apply}>
                                    <RoundButton text={i18n.t("common.register")} />
                                </Link>
                            )}
                        </ButtonsContainer>
                    </div>
                </IconDescContainer>
                <IconDescContainer>
                    <PrepIcon className="mobile-only" />
                    <div>
                        <h2>{i18n.t("content.home.aboutPrepTitle") as string}</h2>
                        {translatedParagraph("content.home.aboutPrepDesc")}
                    </div>
                    <PrepIcon className="non-mobile-only" />
                </IconDescContainer>
                <LinksFooter />
            </Container>
        );
    }
}
