export enum UserPermission {
    ADD_CARETAKER,
    SEE_CARETAKERS,
    ADD_REGION_MANAGER,
    SEE_REGION_MANAGERS,
    SEE_ADMIN,
    ADD_ADMIN,
    DOWNLOAD_STUDY_RESULTS,
    SEE_EVENTS,
    SEE_MESSAGES,
    SEND_MESSAGES_TO_ALL,
    CHANGE_PARTICIPANT_EMAIL,
}
