export interface APIError extends Error {
    error_code: string;
}

export enum APIErrorCode {
    TOKEN_EXPIRED = "authentication_token_expired",
    TFA_REQUIRED = "tfa_required",
    UNAUTHORIZED = "unauthorized",
    EVENTS_ALREADY_GENERATED = "events_already_generated",
    REGION_NOT_SUPPORTED = "no_participating_region",
}
