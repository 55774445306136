import React, { Component } from "react";
import styled from "styled-components";
import { Colors } from "./Colors";

interface Props<T> {
    title?: string;
    onChange: (option: T) => void;
    options: T[];
    displayValues: string[];
    displayValueSubtitles: string[];
    selectedOption?: T;
    color: string;
    textColor: string;
    isDisabled: boolean;
    direction: "row" | "column";
}

const Container = styled.div<{ disabled: boolean }>`
    display: flex;
    flex-direction: column;
    opacity: ${(p) => (p.disabled ? 0.5 : 1)};
`;

const ButtonsContainer = styled.div<{ direction: "row" | "column" }>`
    padding: 7px 0;
    border-radius: 14px;
    display: flex;
    flex-direction: ${(p) => p.direction};
`;

const Title = styled.h4`
    font-size: 17px;
    font-weight: bold;
    margin: 0 0 4px 0;
    color: ${Colors.text};
`;

const RadioButtonContainer = styled.div<{ direction: "row" | "column"; disabled: boolean }>`
    display: flex;
    flex-direction: row;
    margin-right: ${(p) => (p.direction == "row" ? 25 : 0)}px;
    margin-bottom: ${(p) => (p.direction == "column" ? 15 : 0)}px;
    align-items: center;
    cursor: ${(p) => (p.disabled ? "not-allowed" : "pointer")};
    position: relative;
    > div {
        display: flex;
        flex-direction: column;
        span {
            font-size: 14px;
            opacity: 0.5;
        }
        padding-left: 37px;
    }
    label {
        cursor: ${(p) => (p.disabled ? "not-allowed" : "pointer")};
        font-size: 16px;
    }
`;

const RadioButton = styled.span<{ color: string }>`
    height: 24px;
    width: 24px;
    border-radius: 12px;
    display: flex;
    border: 2px solid ${(p) => p.color};
    position: absolute;
`;

const RadioButtonFilling = styled.span<{ color: string }>`
    height: 16px;
    width: 16px;
    display: flex;
    border-radius: 8px;
    background-color: ${(p) => p.color};
    margin: auto;
`;

export class GroupRadioButton<T> extends Component<Props<T>> {
    // Static properties

    public static defaultProps = {
        color: Colors.background,
        textColor: Colors.text,
        isDisabled: false,
        displayValueSubtitles: [],
        direction: "row",
    };

    // Public functions

    public render(): JSX.Element {
        if (this.props.title) {
            return (
                <Container disabled={this.props.isDisabled}>
                    <Title>{this.props.title}</Title>
                    {this.renderButtons()}
                </Container>
            );
        } else {
            return this.renderButtons();
        }
    }

    // Private functions

    private renderButtons(): JSX.Element {
        return (
            <ButtonsContainer direction={this.props.direction}>
                {this.props.options.map((option, index) => {
                    const isSelected = option === this.props.selectedOption;
                    return (
                        <RadioButtonContainer
                            disabled={this.props.isDisabled}
                            direction={this.props.direction}
                            onClick={(e) => this.onButtonClick(e, option)}
                            key={index}
                        >
                            <RadioButton color={this.props.color}>
                                {isSelected && <RadioButtonFilling color={this.props.color} />}
                            </RadioButton>
                            <div>
                                <label>{this.props.displayValues[index]}</label>
                                {index < this.props.displayValueSubtitles.length && (
                                    <span>{this.props.displayValueSubtitles[index]}</span>
                                )}
                            </div>
                        </RadioButtonContainer>
                    );
                })}
            </ButtonsContainer>
        );
    }

    private onButtonClick(event: React.MouseEvent, option: T): void {
        event.preventDefault();
        if (this.props.isDisabled) {
            return;
        }
        this.props.onChange(option);
    }
}
