import React from "react";
import styled from "styled-components";
import { BaseSubscriptionHandlerComponent } from "../../BaseSubscriptionHandlerComponent";
import { BackLink } from "../../appearance/BackLink";
import DI from "../../../di/DI";
import { TYPES } from "../../../di/Types";
import { ParticipantProfileViewModel } from "./ParticipantProfileViewModel";
import { User, Event, Message, fullNameOf } from "../../../domain/model";
import { RouteComponentProps } from "react-router-dom";
import { SegmentedControl } from "../../appearance/SegmentedControl";
import { LoadingPanel } from "../../appearance/LoadingPanel";
import { ParticipantProfileInfo } from "./ParticipantProfileInfo";
import { ParticipantProfileEvents } from "./ParticipantProfileEvents";
import { Path } from "../../App";
import { ParticipantProfileMessages } from "./ParticipantProfileMessages";

const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    width: 90%;
    max-width: 840px;
`;
const Name = styled.h2`
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 0;
`;

interface Props extends RouteComponentProps<{ id: string }> {}
interface State {
    user: User | null;
    events: Event[];
    messages: Message[];
    mode: Mode;
}

enum Mode {
    INFO,
    EVENTS,
    MESSAGES,
}

export class ParticipantProfile extends BaseSubscriptionHandlerComponent<Props, State> {
    // Properties

    private readonly viewModel: ParticipantProfileViewModel = DI.get(TYPES.ParticipantProfileViewModel);

    // Public functions

    public constructor(props: Props) {
        super(props);
        this.state = { user: null, events: [], messages: [], mode: Mode.INFO };
    }

    public componentDidMount(): void {
        this.assignToState(this.viewModel.fetchUser(this.props.match.params.id), (user) => ({ user }));
        this.assignToState(this.viewModel.fetchEvents(this.props.match.params.id), (events) => ({
            events,
            mode: events.length > 0 ? Mode.EVENTS : Mode.INFO,
        }));
        this.assignToState(this.viewModel.fetchMessages(this.props.match.params.id), (messages) => ({ messages }));
    }

    public render(): React.ReactNode {
        return (
            <Container>
                <BackLink title={"Dashboard"} to={Path.admin.root} />
                {this.state.user == null && <LoadingPanel />}
                {this.state.user && (
                    <div>
                        <Name>{fullNameOf(this.state.user)}</Name>
                        <p>
                            {this.state.user.prepServiceType} - {this.state.user.visitFrequency} mnd
                        </p>
                        <SegmentedControl
                            selectedOption={this.state.mode}
                            onChange={(mode) => this.setState({ mode })}
                            options={[Mode.INFO, Mode.EVENTS, Mode.MESSAGES]}
                            displayValues={["Gegevens", "Events", "Berichten"]}
                        />
                        {this.state.mode == Mode.INFO && <ParticipantProfileInfo user={this.state.user!} />}
                        {this.state.mode == Mode.EVENTS && (
                            <ParticipantProfileEvents
                                user={this.state.user!}
                                events={this.state.events}
                                createNewEvent={(eventType) =>
                                    this.viewModel.createEvent(this.state.user!.id, eventType)
                                }
                                history={this.props.history}
                                location={this.props.location}
                                match={this.props.match}
                            />
                        )}
                        {this.state.mode == Mode.MESSAGES && (
                            <ParticipantProfileMessages user={this.state.user} messages={this.state.messages} />
                        )}
                    </div>
                )}
            </Container>
        );
    }
}
