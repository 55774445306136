import React, { Component, PropsWithChildren } from "react";
import styled from "styled-components";
import { Colors } from "./Colors";
import Loading from "./Loading";
import { hexColorWithAlpha } from "../../utils/ColorUtils";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface Props extends PropsWithChildren<any> {
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    isLoading?: boolean;
    text?: string;
    disabled?: boolean;
    background?: string;
    textColor?: string;
    verticalPadding?: number;
    horizontalPadding?: number;
    fillWidth?: boolean;
    type?: "submit" | "reset" | "button";
}

const Button = styled.button<Props>`
    position: relative;
    padding: ${(props) => `${props.verticalPadding}px ${props.horizontalPadding}px`};
    border: none;
    border-radius: 12px;
    color: ${(props) => (props.textColor ? hexColorWithAlpha(props.textColor, props.disabled ? 0.6 : 1) : undefined)};
    flex-grow: ${(props) => (props.fillWidth ? "1" : "unset")};
    width: ${(props) => (props.fillWidth ? "100%" : "auto")};
    cursor: ${(props: Props) => (props.disabled ? "not-allowed" : "pointer")};
    font-weight: bold;
    font-size: 17px;
    background-color: ${(props: Props) =>
        props.background ? hexColorWithAlpha(props.background, props.disabled ? 0.5 : 1) : "transparent"}};
    :hover {
        opacity: ${(props: Props) => (props.disabled ? 1 : 0.9)};
    }
    :active {
        opacity: ${(props: Props) => (props.disabled ? 1 : 0.6)};
    }
`;

const LoadingContainer = styled.span`
    left: 50%;
    position: absolute;
    margin-left: -10px;
    top: 50%;
    margin-top: -10px;
`;

export class RoundButton extends Component<Props> {
    // Static properties

    public static defaultProps = {
        isLoading: false,
        disabled: false,
        background: Colors.primaryTint,
        textColor: "#FFFFFF",
        verticalPadding: 11,
        horizontalPadding: 16,
    };

    // Public functions

    public render(): JSX.Element {
        return (
            <Button {...this.props} disabled={this.props.isLoading || this.props.disabled}>
                <span style={{ opacity: this.props.isLoading ? 0 : 1 }}>{this.props.text}</span>
                {this.props.isLoading && (
                    <LoadingContainer>
                        <Loading />
                    </LoadingContainer>
                )}
                {!this.props.isLoading && this.props.children}
            </Button>
        );
    }
}

export const StyledCircleButton = styled(Button)`
    width: 40px;
    height: 40px;
    align-items: center;
    justify-content: center;
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface CircleProps extends PropsWithChildren<any> {
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
    isLoading?: boolean;
    disabled: boolean;
    background: string;
    fillWidth?: boolean;
    textColor: string;
    verticalPadding: number;
    horizontalPadding: number;
}

export class CircleButton extends Component<CircleProps> {
    // Static properties

    public static defaultProps = {
        isLoading: false,
        disabled: false,
        background: Colors.primaryTint,
        textColor: "#ffffff",
        verticalPadding: 0,
        horizontalPadding: 0,
    };

    // Public functions

    public render(): JSX.Element {
        return (
            <StyledCircleButton {...this.props} disabled={this.props.isLoading || this.props.disabled}>
                {this.props.isLoading ? null : this.props.children}
                {this.props.isLoading && (
                    <LoadingContainer>
                        <Loading />
                    </LoadingContainer>
                )}
            </StyledCircleButton>
        );
    }
}
