import { BaseViewModel } from "./BaseViewModel";
import { UIConfigRepository } from "./UIConfigRepository";

export abstract class BaseUIConfigRepositoryViewModel extends BaseViewModel {
    protected constructor(protected readonly uiConfigRepository: UIConfigRepository) {
        super();
    }

    public isRegistrationEnabled(): boolean {
        return this.uiConfigRepository.isRegistrationEnabled();
    }
}
