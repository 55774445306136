import { SessionRepository } from "../../domain/repositories";
import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import { BaseViewModel } from "../BaseViewModel";
import { SessionState } from "../../domain/model";

export class LoginViewModel extends BaseViewModel {
    // Properties

    public get isBusy(): Rx.Observable<boolean> {
        return this.isBusySubject.asObservable();
    }
    public get tfaSetupUri(): string | null {
        return this.sessionRepository.tfaSetupUri;
    }
    public get tfaSetupNeeded(): Rx.Observable<boolean> {
        return this.sessionRepository.state.pipe(RxOperators.map((value) => value === SessionState.LoggingInSetupTFA));
    }
    public get tfaVerificationNeeded(): Rx.Observable<boolean> {
        return this.sessionRepository.state.pipe(RxOperators.map((value) => value === SessionState.LoggingInTFA));
    }

    private isBusySubject = new Rx.BehaviorSubject<boolean>(false);
    private lastEmail: string | null = null;
    private lastPassword: string | null = null;

    // Lifecycle

    public constructor(private readonly sessionRepository: SessionRepository) {
        super();
    }

    // Public functions

    public login(email: string, password: string, totp?: number): Rx.Observable<void> {
        this.lastEmail = email;
        this.lastPassword = password;
        this.isBusySubject.next(true);
        return this.sessionRepository.logIn(email, password, totp).pipe(
            RxOperators.tap({
                error: () => this.isBusySubject.next(false),
                complete: () => this.isBusySubject.next(false),
            }),
            RxOperators.ignoreElements(),
        );
    }

    public loginWithTFA(totp: number): Rx.Observable<void> {
        if (!this.lastEmail || !this.lastPassword) {
            return Rx.throwError(Error("Log opnieuw in"));
        }
        return this.login(this.lastEmail, this.lastPassword, totp);
    }

    public confirmTFA(totp: number): Rx.Observable<void> {
        return this.sessionRepository.confirmSetupTFA(totp);
    }

    public logout(): Rx.Observable<void> {
        return this.sessionRepository.logOut();
    }
}
