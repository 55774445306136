import React from "react";
import Loading from "./Loading";
import styled from "styled-components";
import { hexColorWithAlpha } from "../../utils/ColorUtils";
import { Colors } from "./Colors";

const Container = styled.div`
    width: 100%;
    max-width: 840px;
    height: 200px;
    background-color: ${hexColorWithAlpha(Colors.darkAccent, 0.8)};
    border-radius: 16px;
    justify-content: center;
    align-items: center;
    display: flex;
}
`;

interface Props {}

export class LoadingPanel extends React.Component<Props> {
    public render(): React.ReactNode {
        return (
            <Container>
                <Loading />
            </Container>
        );
    }
}
