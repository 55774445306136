import React from "react";
import styled from "styled-components";
import { TYPES } from "../../di/Types";
import DI from "../../di/DI";
import { BaseSubscriptionHandlerComponent } from "../BaseSubscriptionHandlerComponent";
import { HomeViewModel, ListMode } from "./HomeViewModel";
import { Colors } from "../appearance/Colors";
import { Event, Message, MessageType, StudyRegion, StudyRegions, User, UserPermission } from "../../domain/model";
import { SegmentedControl } from "../appearance/SegmentedControl";
import { RoundInput } from "../appearance/RoundInput";
import { RoundButton } from "../appearance/RoundButton";
import { UserList } from "../userlist/UserList";
import { Link } from "../appearance/Link";
import { EventList } from "../eventlist/EventList";
import { Path } from "../App";
import { MessageList } from "../message/MessageList";
import { downloadBlob } from "../../utils/DOMUtils";

const Container = styled.div`
    width: 90%;
    max-width: 840px;
`;
const WelcomeTitle = styled.h2`
    font-size: 28px;
    font-weight: bold;
`;
const SearchInput = styled(RoundInput)`
    width: 100%;
`;
const Spacer = styled.div`
    height: 12px;
`;

interface State {
    currentUser: User | null;
    listMode: ListMode;
    region: StudyRegion | null;
    isLoadingUsers: boolean;
    isLoadingEvents: boolean;
    isLoadingMessages: boolean;
    users: User[];
    events: Event[];
    messages: Message[];
}
interface Props {}

export class Home extends BaseSubscriptionHandlerComponent<Props, State> {
    // Properties

    private readonly viewModel: HomeViewModel = DI.get(TYPES.HomeViewModel);
    private readonly searchInput: React.RefObject<RoundInput> = React.createRef();
    private get isLoading(): boolean {
        return this.state.isLoadingEvents || this.state.isLoadingUsers;
    }

    // Public functions

    public constructor(props: Props) {
        super(props);
        this.state = {
            currentUser: null,
            listMode: ListMode.PARTICIPANTS,
            region: null,
            isLoadingUsers: false,
            isLoadingEvents: false,
            isLoadingMessages: false,
            users: [],
            events: [],
            messages: [],
        };
    }

    public componentDidMount(): void {
        this.fetchCurrentUser();
        this.subscribeToObservables();
    }

    public render(): React.ReactNode {
        return (
            this.state.currentUser != null && (
                <Container>
                    <WelcomeTitle>{`Welkom ${this.state.currentUser.firstName}`}</WelcomeTitle>
                    <p>
                        De deelnemerinformatie in dit systeem is vertrouwelijk en dient als zodanig te worden behandeld.
                    </p>
                    <SegmentedControl
                        isDisabled={this.isLoading}
                        options={Object.keys(this.viewModel.getListModes(this.state.currentUser)) as ListMode[]}
                        displayValues={Object.values(this.viewModel.getListModes(this.state.currentUser))}
                        selectedOption={this.state.listMode}
                        onChange={(listMode) => {
                            this.setState({ listMode });
                            this.queryUpdated({ listMode });
                        }}
                    />
                    <Spacer />
                    {this.state.listMode == ListMode.USERS && (
                        <div>
                            {User.can(this.state.currentUser, UserPermission.SEE_REGION_MANAGERS) && (
                                <SegmentedControl
                                    isDisabled={this.isLoading}
                                    background={Colors.darkAccent}
                                    selectedBackground={"#ffffff"}
                                    textColor={Colors.background}
                                    separatorColor={Colors.background}
                                    selectedTextColor={Colors.background}
                                    adaptTextColor={false}
                                    options={this.viewModel.allRegions}
                                    displayValues={this.viewModel.allRegions.map((r) => r.name)}
                                    selectedOption={this.state.region}
                                    onChange={(region) => {
                                        this.setState({ region });
                                        this.queryUpdated({ region });
                                    }}
                                />
                            )}
                            <Spacer />
                            {User.can(this.state.currentUser, UserPermission.ADD_REGION_MANAGER) && this.state.region && (
                                <Link to={Path.admin.user.newSuperuser(this.state.region.id)}>
                                    <RoundButton text={`Superuser ${this.state.region.name} toevoegen +`} />
                                </Link>
                            )}
                            {User.can(this.state.currentUser, UserPermission.ADD_CARETAKER) && (
                                <Link to={Path.admin.user.newCaretaker}>
                                    <RoundButton text={`Zorgverlener toevoegen +`} />
                                </Link>
                            )}
                        </div>
                    )}
                    {this.state.listMode == ListMode.ADMINS && (
                        <div>
                            {User.can(this.state.currentUser, UserPermission.ADD_ADMIN) && (
                                <Link to={Path.admin.user.newAdmin}>
                                    <RoundButton text={`Admin +`} />
                                </Link>
                            )}
                        </div>
                    )}
                    {this.state.listMode == ListMode.PARTICIPANTS &&
                        User.can(this.state.currentUser, UserPermission.DOWNLOAD_STUDY_RESULTS) && (
                            <RoundButton text={"Download resultaten"} onClick={this.downloadResult.bind(this)} />
                        )}
                    <Spacer />
                    {[ListMode.USERS, ListMode.PARTICIPANTS, ListMode.ADMINS].includes(this.state.listMode) && (
                        <SearchInput
                            ref={this.searchInput}
                            onTextChange={(query) => this.queryUpdated({ query })}
                            placeholder={"Zoek"}
                        />
                    )}
                    {[ListMode.USERS, ListMode.PARTICIPANTS, ListMode.ADMINS].includes(this.state.listMode) && (
                        <UserList isLoading={this.state.isLoadingUsers} users={this.state.users} />
                    )}
                    {this.state.listMode == ListMode.EVENTS && (
                        <EventList isLoading={this.state.isLoadingEvents} events={this.state.events} />
                    )}

                    {this.state.listMode == ListMode.MESSAGES && (
                        <div>
                            {User.can(this.state.currentUser, UserPermission.SEND_MESSAGES_TO_ALL) && (
                                <Link to={Path.admin.user.newMessage}>
                                    <RoundButton text={`Nieuw bericht naar alle participanten +`} />
                                </Link>
                            )}
                            <Spacer />
                        </div>
                    )}
                    {this.state.listMode == ListMode.MESSAGES && (
                        <MessageList
                            isLoading={this.state.isLoadingMessages}
                            messages={this.state.messages.filter((m) => m.type === MessageType.ACTION)}
                            showTo={true}
                        />
                    )}
                </Container>
            )
        );
    }

    // Private functions

    private queryUpdated(update: { region?: StudyRegion | null; listMode?: ListMode; query?: string }): void {
        this.viewModel.queryUpdated(
            update.region || this.state.region,
            update.listMode || this.state.listMode,
            update.query || this.searchInput.current?.value || "",
        );
    }
    private fetchCurrentUser(): void {
        this.assignToState(this.viewModel.getCurrentUserInfo(), (currentUser) => {
            // Refresh list once we have the current user
            setTimeout(() => this.queryUpdated({}), 0);
            return {
                currentUser,
                region: currentUser.region || StudyRegions.AMSTERDAM,
                listMode:
                    User.can(currentUser, UserPermission.SEE_CARETAKERS) ||
                    User.can(currentUser, UserPermission.SEE_REGION_MANAGERS)
                        ? ListMode.USERS
                        : ListMode.PARTICIPANTS,
            };
        });
    }

    private subscribeToObservables(): void {
        this.assignToState(this.viewModel.usersObservable, (users) => ({ users }));
        this.assignToState(this.viewModel.isLoadingUsersObservable, (isLoadingUsers) => ({ isLoadingUsers }));
        this.assignToState(this.viewModel.isLoadingEventsObservable, (isLoadingEvents) => ({ isLoadingEvents }));
        this.assignToState(this.viewModel.eventsObservable, (events) => ({ events }));
        this.assignToState(this.viewModel.isLoadingMessagesObservable, (isLoadingMessages) => ({ isLoadingMessages }));
        this.assignToState(this.viewModel.messagesObservable, (messages) => ({ messages }));
    }

    private downloadResult(): void {
        const timestamp = Date.now();
        const fileName = "participant_data_" + timestamp;
        this.viewModel.downloadAllParticipantInfo().subscribe((blob) => downloadBlob(blob, fileName));
    }
}
