import { UserRole } from "./UserRole";
import { UserStatus } from "./UserStatus";
import { Gender } from "./Gender";
import { StudyArmVisitFrequency } from "./StudyArmVisitFrequency";
import { StudyArmPrepServiceType } from "./StudyArmPrepServiceType";
import { StudyRegion } from "./StudyRegion";
import { UserPermission } from "./UserPermission";

export class User {
    // Static functions

    public static can(user: User | null, permission: UserPermission[] | UserPermission | null): boolean {
        if (user == null || permission == null) {
            return false;
        }
        const permissions = permission instanceof Array ? permission : [permission];
        for (const p of permissions) {
            if (!user.role.permissions.includes(p)) {
                return false;
            }
        }
        return true;
    }

    // Public functions

    public constructor(
        public readonly id: string,
        public readonly email: string,
        public readonly firstName: string,
        public readonly lastName: string,
        public readonly locale: string,
        public readonly role: UserRole,
        public readonly status: UserStatus,
        public readonly region: StudyRegion | null = null,
        public readonly prepServiceType: StudyArmPrepServiceType | null = null,
        public readonly visitFrequency: StudyArmVisitFrequency | null = null,
        public readonly starter: boolean | null = null,
        public readonly birthDate: Date | null = null,
        public readonly phone: string | null = null,
        public readonly gender: Gender | null = null,
        public readonly hadHCV: boolean | null = null,
        public readonly hadSyfilis: boolean | null = null,
        public readonly studyNumber: string | null = null,
        public readonly city: string | null = null,
        public readonly postalCode: string | null = null,
        public readonly street: string | null = null,
        public readonly streetNumber: string | null = null,
        public readonly regionPostalCode: string | null = null,
    ) {}
}

export function fullNameOf(user: User): string {
    return user.firstName + " " + user.lastName;
}
