import React from "react";
import styled from "styled-components";
import { BaseSubscriptionHandlerComponent } from "../BaseSubscriptionHandlerComponent";
import { Event } from "../../domain/model";
import { EventListRow } from "./EventListRow";
import Loading from "../appearance/Loading";
import { hexColorWithAlpha } from "../../utils/ColorUtils";
import { Colors } from "../appearance/Colors";
import { datesAreOnSameDay } from "../../utils/DateTimeUtils";
import { DateFormatter } from "../../infrastructure/DateFormatter";
import { TYPES } from "../../di/Types";
import DI from "../../di/DI";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    min-height: 100px;
`;

const LoadingContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: absolute;
    flex: 1;
    background-color: ${hexColorWithAlpha(Colors.darkAccent, 0.8)};
    border-radius: 16px;
    top: 10px;
    bottom: 10px;
`;

const Header = styled.h3`
    margin-top: 20px;
    margin-bottom: 12px;
    font-size: 22px;
`;

interface Props {
    isLoading: boolean;
    events: Event[];
}

export class EventList extends BaseSubscriptionHandlerComponent<Props> {
    // Public functions

    private readonly dateFormatter: DateFormatter = DI.get(TYPES.DateFormatter);

    public constructor(props: Props) {
        super(props);
        this.state = {};
    }

    public render(): React.ReactNode {
        return (
            <Container>
                {this.getEvents(true).length > 0 && (
                    <div>
                        <Header>Vandaag {this.dateFormatter.formatDateDay(new Date(), false, false)}</Header>
                        {this.getEvents(true).map((event) => {
                            return <EventListRow key={event.id} event={event} />;
                        })}
                    </div>
                )}
                {this.getEvents(false).length > 0 && (
                    <div>
                        <Header>Toekomstig</Header>
                        {this.getEvents(false).map((event) => {
                            return <EventListRow key={event.id} event={event} />;
                        })}
                    </div>
                )}
                {this.props.isLoading && (
                    <LoadingContainer>
                        <Loading />
                    </LoadingContainer>
                )}
            </Container>
        );
    }

    // Private functions

    private getEvents(onToday: boolean): Event[] {
        const today = new Date();
        return this.props.events.filter((e) =>
            onToday ? datesAreOnSameDay(e.plannedDate!, today) : !datesAreOnSameDay(e.plannedDate!, today),
        );
    }
}
