import cogoToast from "../components/toast";
import { CTReturn } from "../components/toast/types";
import { ReactComponent as ErrorIcon } from "../res/images/error.svg";
import { ReactComponent as SuccessIcon } from "../res/images/success.svg";
import React from "react";
import { APIError, APIErrorCode } from "../infrastructure/api/models";

interface Options {
    title?: string;
    message: string;
    icon?: React.ComponentType;
    isError?: boolean;
    onClick?: (toast: CTReturn | null) => void;
}

export function showMessageWithOptions(options: Options): CTReturn {
    const oc = options.onClick;
    let toastRef: CTReturn | null = null;
    const defaultOnClick = (): void => {
        if (toastRef && toastRef.hide) {
            toastRef.hide();
        }
    };
    const toast = cogoToast.error(options.message, {
        position: "bottom-center",
        hideAfter: 5,
        renderIcon: () => (options.icon && React.createElement(options.icon)) || null,
        heading: (options && options.title) || (options.isError ? "Fout!" : ""),
        bar: { size: "0px" },
        onClick: oc != null ? () => oc(toastRef) : defaultOnClick,
    });
    toastRef = toast;
    return toast;
}

export function showSuccess(message: string): CTReturn {
    return showMessageWithOptions({
        message,
        icon: SuccessIcon,
        title: "Gelukt!",
    });
}

export function showError(error?: Error | null, overrideOptions?: Options): CTReturn {
    if (!error) {
        return {} as CTReturn;
    }
    error = localizeError(error);
    console.error(error);
    const o = overrideOptions;
    return showMessageWithOptions({
        ...o,
        icon: ErrorIcon,
        title: (o && o.title) || error.name,
        message: (o && o.message) || error.message,
        isError: true,
    });
}

function localizeError(error: Error): Error {
    switch ((error as APIError).error_code) {
        case APIErrorCode.UNAUTHORIZED:
            if (error.message == null || error.message.length < 1) {
                error.message = "e-mailadres of wachtwoord is niet correct";
            }
    }
    return error;
}
