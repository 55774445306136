import { StudyRegion, StudyRegions } from "../../domain/model";

type AnchorSelector = (region: StudyRegion) => string;

/**
 * Join study region names in one string.
 * @param options
 * `lastSeparator`: Set what should be used as the separator between the last two regions in the text.
 *
 * `addPrefix`: If `true`, the region names will be pre-pended with 'GGD '
 *
 * `anchorHref`: An optional selector to choose the link for each region. If provided, the return value is an html anchor tag which should not be used in plaintext.
 */
export function joinStudyRegions(options: {
    lastSeparator: string;
    addPrefix?: boolean;
    anchorHref?: AnchorSelector;
}): string {
    const regions = Object.values(StudyRegions).map((r) => {
        const text = `${options.addPrefix ? "GGD " : ""}${r.name}`;
        if (options.anchorHref) {
            return `<a href="${options.anchorHref(r)}">${text}</a>`;
        } else {
            return text;
        }
    });
    const last = regions.pop();

    const initialPackages = regions.join(", ");
    return [initialPackages, last].join(` ${options.lastSeparator} `);
}
