import React from "react";
import { ContentPageContainer } from "../content/ContentPageContainer";
import i18n from "i18next";
import { BaseSubscriptionHandlerComponent } from "../../BaseSubscriptionHandlerComponent";
import { Path } from "../../App";
import {
    BulletPointContainer,
    HeadlineContainer,
    Link,
    RoundButton,
    ButtonsContainer,
    LineButton,
} from "../../appearance";
import { AnalyticsEvent } from "../analytics/AnalyticsEvent";
import { logAnalyticsEvent } from "../analytics/Analytics";

interface Props {}

export class ApplyForStudy extends BaseSubscriptionHandlerComponent<Props> {
    public componentDidMount(): void {
        logAnalyticsEvent(AnalyticsEvent.APPLY_INTRO);
    }

    public render(): React.ReactNode {
        return (
            <ContentPageContainer>
                <div>
                    <HeadlineContainer>
                        <h1>{i18n.t("content.register.title") as string}</h1>
                        <p>{i18n.t("content.register.contentPart1") as string}</p>
                        <ButtonsContainer>
                            <Link to={Path.public.participationInfo}>
                                <LineButton text={i18n.t("content.pages.participationInfo")} />
                            </Link>
                            <Link to={Path.public.applicationForm}>
                                <RoundButton text={i18n.t("content.register.toRegisterForm")} />
                            </Link>
                        </ButtonsContainer>
                        <BulletPointContainer>
                            <h2>{i18n.t("content.register.bulletPoint1Header") as string}</h2>
                            <ul>
                                {i18n.array("content.register.bulletPoints").map((bp, index) => (
                                    <li key={index} dangerouslySetInnerHTML={{ __html: bp }}></li>
                                ))}
                            </ul>
                        </BulletPointContainer>
                        <br />
                        <h2>{i18n.t("content.register.contentPart2Header") as string}</h2>
                        <p>{i18n.t("content.register.contentPart2Desc") as string}</p>
                        <br />
                        <Link to={Path.public.applicationForm}>
                            <RoundButton text={i18n.t("content.register.toRegisterForm")} />
                        </Link>
                    </HeadlineContainer>
                </div>
            </ContentPageContainer>
        );
    }
}
