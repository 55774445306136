import styled from "styled-components";

export const ButtonsContainer = styled.div<{ responsive?: boolean }>`
    display: flex;
    flex-direction: row;

    button:not(:first-child) {
        margin-left: 12px;
    }
    > a:not(:first-child) {
        margin-left: 12px;
    }

    ${({ responsive }) =>
        responsive &&
        `@media (max-width: 768px) {
                flex-direction: column;
                
                button:not(:first-child) {
                    margin-left: unset;
                    margin-top: 12px;
                }
                > a:not(:first-child) {
                    margin-left: unset;
                    margin-top: 12px;
                }
            }`}
`;
