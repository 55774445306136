import { UserRepository } from "../../../domain/repositories";
import * as Rx from "rxjs";
import { BaseViewModel } from "../../BaseViewModel";
import { StudyRegion } from "../../../domain/model";

export class ApplyForStudyViewModel extends BaseViewModel {
    // Public functions
    public constructor(private readonly userRepository: UserRepository) {
        super();
    }

    public checkPostalCode(postalCode: string): Rx.Observable<StudyRegion | null> {
        return this.userRepository.getStudyRegionWithPostalCode(postalCode);
    }

    public submitParticipant(
        region: StudyRegion,
        firstName: string,
        lastName: string,
        phoneNumber: string,
        email: string,
    ): Rx.Observable<void> {
        return this.userRepository.participate(region, firstName, lastName, phoneNumber, email);
    }
}
