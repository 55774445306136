import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

class ScrollToTop extends React.Component<RouteComponentProps> {
    public componentDidUpdate(prevProps: Readonly<RouteComponentProps>): void {
        if (this.props.location !== prevProps.location) {
            window.scrollTo(0, 0);
        }
    }

    public render(): React.ReactNode {
        return null;
    }
}

export default withRouter(ScrollToTop);
