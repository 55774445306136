import React from "react";
import { BaseSubscriptionHandlerComponent } from "../BaseSubscriptionHandlerComponent";
import { RouteComponentProps } from "react-router-dom";
import i18n from "i18next";
import { Container, ContentContainer } from "./PrepOrderResultStyle";
import { GoBackToApp } from "./GoBackToApp";

interface Props extends RouteComponentProps {}
interface State {}

export class PrepOrderResult extends BaseSubscriptionHandlerComponent<Props, State> {
    // Public functions

    public render(): React.ReactNode {
        return (
            <Container>
                <ContentContainer>
                    <h3>{i18n.t("orderResult.successTitle") as string}</h3>
                    <p>{i18n.t("orderResult.emailWhenReadyToPickup") as string}</p>
                    <GoBackToApp />
                </ContentContainer>
            </Container>
        );
    }
}
