import { DAY } from "../utils/DateTimeUtils";

export interface DateFormatter {
    formatDateDay(date: Date, relative: boolean, shortMonthName: boolean): string;
    formatFullDate(date: Date): string;

    formatYearMonthDay(date: Date): string; //jjjj-mm-dd
    formatTime(date: Date): string; //HH:mm

    formatDayMonthYear(date: Date): string; //dd-mm-jjjj
    formatHourMinute(date: Date): string; //HH:mm

    getDateFromDayMonthYear(string: string): Date; //format: dd-mm-jjjj {HH:mm}
}

export class DefaultDateFormatter implements DateFormatter {
    public formatTime(date: Date): string {
        return this.appendLeadingZeroes(date.getHours()) + ":" + this.appendLeadingZeroes(date.getMinutes());
    }

    public formatFullDate(date: Date): string {
        return this.formatDateDay(date, false, false, true);
    }

    public formatYearMonthDay(date: Date): string {
        return `${date.getFullYear()}-${this.appendLeadingZeroes(date.getMonth() + 1)}-${this.appendLeadingZeroes(
            date.getDate(),
        )}`;
    }

    public formatDayMonthYear(date: Date): string {
        return `${this.appendLeadingZeroes(date.getDate())}-${this.appendLeadingZeroes(
            date.getMonth() + 1,
        )}-${date.getFullYear()}`;
    }

    public formatHourMinute(date: Date): string {
        return `${this.appendLeadingZeroes(date.getHours())}:${this.appendLeadingZeroes(date.getMinutes())}`;
    }

    public getDateFromDayMonthYear(string: string): Date {
        if (string.length < 10) {
            throw Error("wrong date format");
        }
        const day = parseInt(string.substr(0, 2));
        const month = parseInt(string.substr(3, 2));
        const year = parseInt(string.substr(6, 4));
        let hour = 12;
        let minute = 0;
        if (string.split(" ").length == 2 && string.split(" ")[1].length == 5) {
            const hourMinute = string.split(" ")[1];
            hour = parseInt(hourMinute.substr(0, 2));
            minute = parseInt(hourMinute.substr(3, 2));
        }
        return new Date(year, month - 1, day, hour, minute, 0);
    }

    public formatDateDay(date: Date, relative: boolean, shortMonthName = true, showYear = false): string {
        const deltaDays = Math.floor(new Date().getTime() / DAY) - Math.floor(date.getTime() / DAY);
        const monthNames = [
            "januari",
            "februari",
            "maart",
            "april",
            "mei",
            "juni",
            "Juli",
            "augustus",
            "september",
            "oktober",
            "november",
            "december",
        ];
        const monthShortNames = [
            "jan.",
            "feb.",
            "maart",
            "apr.",
            "mei",
            "juni",
            "juli",
            "aug.",
            "sept.",
            "okt.",
            "nov.",
            "dec.",
        ];

        const notRelativeDate = `
            ${date.getDate()} 
            ${(shortMonthName ? monthShortNames : monthNames)[date.getMonth()]} 
            ${showYear ? date.getFullYear() : ""}
        `;
        if (!relative) {
            return notRelativeDate;
        }
        if (deltaDays == -1) {
            return "Morgen";
        } else if (deltaDays == 0) {
            return "Vandaag";
        } else if (deltaDays == 1) {
            return "Gisteren";
        } else {
            return notRelativeDate;
        }
    }

    private appendLeadingZeroes(n: number): string {
        if (n <= 9) {
            return "0" + n;
        }
        return n + "";
    }
}
