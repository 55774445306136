import { BaseViewModel } from "../BaseViewModel";
import { UserRepository } from "../../domain/repositories";
import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import { RoleId, StudyRegion, User, UserRole, UserStatus } from "../../domain/model";

export class NewUserViewModel extends BaseViewModel {
    public constructor(private readonly userRepository: UserRepository) {
        super();
    }

    public createUser(
        email: string,
        firstName: string,
        lastName: string,
        roleId: RoleId,
        region: StudyRegion | null,
    ): Rx.Observable<void> {
        const regionObservable: Rx.Observable<StudyRegion> = region
            ? Rx.of(region)
            : this.userRepository.getCurrentUserInfo().pipe(RxOperators.map((user) => user.region!));
        return regionObservable.pipe(
            RxOperators.mergeMap((region) => {
                const user = new User(
                    "",
                    email,
                    firstName,
                    lastName,
                    "nl",
                    UserRole.fromId(roleId),
                    UserStatus.ACTIVE,
                    region,
                );
                return Rx.concat(
                    this.userRepository.createUser(user),
                    this.userRepository.requestPasswordReset(user.email),
                );
            }),
        );
    }
}
