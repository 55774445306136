import React from "react";
import styled from "styled-components";
import { BaseSubscriptionHandlerComponent } from "../BaseSubscriptionHandlerComponent";
import { RoleId, User, UserStatus } from "../../domain/model";
import { UserListRow } from "./UserListRow";
import Loading from "../appearance/Loading";
import { hexColorWithAlpha } from "../../utils/ColorUtils";
import { Colors } from "../appearance/Colors";
import { Link } from "../appearance/Link";
import { Path } from "../App";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    min-height: 100px;
`;

const LoadingContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: absolute;
    flex: 1;
    background-color: ${hexColorWithAlpha(Colors.darkAccent, 0.8)};
    border-radius: 16px;
    top: 10px;
    bottom: 10px;
`;

interface Props {
    isLoading: boolean;
    users: User[];
}

export class UserList extends BaseSubscriptionHandlerComponent<Props> {
    // Public functions

    public constructor(props: Props) {
        super(props);
        this.state = {};
    }

    public render(): React.ReactNode {
        return (
            <Container>
                {this.props.users.map((user) => {
                    return (
                        <Link key={user.id} to={this.getRouteForUser(user)}>
                            <UserListRow user={user} />
                        </Link>
                    );
                })}
                {this.props.isLoading && (
                    <LoadingContainer>
                        <Loading />
                    </LoadingContainer>
                )}
            </Container>
        );
    }

    // Private functions

    private getRouteForUser(user: User): string {
        switch (user.role.id) {
            case RoleId.PARTICIPANT:
                if (user.status == UserStatus.REGISTERED) {
                    return Path.admin.participant.edit(user.id);
                } else {
                    return Path.admin.participant.profile(user.id);
                }
            case RoleId.REGION_MANAGER:
            case RoleId.CARETAKER:
            case RoleId.ADMIN:
                return Path.admin.user.profile(user.id);
        }
        return Path.admin.root;
    }
}
