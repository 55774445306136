import React from "react";
import i18n from "i18next";
import { format } from "../../utils/StringUtils";
import { BulletPointContainer } from ".";

export function translatedParagraph(translationKey: string, ...params: string[]): React.ReactNode | undefined {
    return translated("p", translationKey, ...params);
}

export function translated(
    type: keyof React.ReactHTML,
    translationKey: string,
    ...params: string[]
): React.ReactNode | undefined {
    if (!i18n.exists(translationKey)) {
        return undefined;
    }
    const translated = i18n.t(translationKey);
    const text = processTextParams(translated, ...params);
    return React.createElement(type, { dangerouslySetInnerHTML: { __html: text } });
}

export function translatedBulletPoints(
    translationKey: string,
    transform?: (bp: string, index: number) => string,
): React.ReactNode | undefined {
    const bulletPoints = translatedArray(translationKey);
    if (!bulletPoints) {
        return undefined;
    }
    return (
        <BulletPointContainer>
            <ul>
                {bulletPoints.map((bp, index) => (
                    <li
                        key={index}
                        dangerouslySetInnerHTML={{
                            __html: transform?.(bp, index) || bp,
                        }}
                    />
                ))}
            </ul>
        </BulletPointContainer>
    );
}

export function translatedArray(translationKey: string, ...params: string[]): string[] | undefined {
    if (!i18n.exists(translationKey)) {
        return undefined;
    }
    return i18n.array(translationKey).map((translated) => processTextParams(translated, ...params));
}

function processTextParams(text: string, ...params: string[]): string {
    return params.length == 0 ? text : format(text, ...params);
}
