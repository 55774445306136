import styled from "styled-components";
import React, { Component } from "react";

const DEFAULT_ANIMATION_DURATION_MILLIS = 300;

const Container = styled.div<{ isVisible: boolean; animationDurationMillis?: number }>`
    background-color: rgba(0, 0, 0, 0.6);
    opacity: ${(props) => (props.isVisible ? 1 : 0)};
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    transition: opacity ${(props) => props.animationDurationMillis || DEFAULT_ANIMATION_DURATION_MILLIS}ms ease-out;
`;

interface Props {
    animationDurationMillis?: number;
    onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
}

export class ScreenDimmer extends Component<Props, State> {
    // Lifecycle

    public constructor(props: Props) {
        super(props);
        this.state = {
            isVisible: false,
        };
    }
    // Public functions

    public componentDidMount(): void {
        setTimeout(() => {
            this.setState({ isVisible: true });
        });
    }

    public render(): JSX.Element {
        return <Container isVisible={this.state.isVisible} {...this.props} />;
    }

    public hide(): void {
        this.setState({ isVisible: false });
    }
}

interface State {
    isVisible: boolean;
}
