/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */

import React, { useState, useEffect, MouseEventHandler } from "react";

import Icons from "./ToastIcons";
import { CTBar, CTType, CTPosition } from "./types";

const colors: CTType = {
    success: "#6EC05F",
    info: "#1271EC",
    warn: "#FED953",
    error: "#D60A2E",
    loading: "#0088ff",
};

type CToastProps = {
    type: keyof CTType;
    text: string | React.ReactElement;
    show: boolean;
    onHide?: Function;
    id?: string | number;
    hideAfter: number;
    heading?: string;
    position?: CTPosition;
    renderIcon?: () => React.ReactElement;
    bar?: CTBar;
    onClick?: MouseEventHandler;
    role?: string;
};

const defaultProps: Partial<CToastProps> = {
    show: true,
    hideAfter: 3,
    position: "top-center",
    bar: {},
    role: "status",
};

const Toast: React.FC<CToastProps> = (passedProps) => {
    const props: CToastProps = { ...defaultProps, ...passedProps };
    const place = (props.position || "top-center").includes("bottom") ? "Bottom" : "Top";
    const marginType = `margin${place}`;

    const className = ["ct-toast", props.onClick ? " ct-cursor-pointer" : "", `ct-toast-${props.type}`].join(" ");
    const borderLeft = `${props.bar?.size || "3px"} ${props.bar?.style || "solid"} ${
        props.bar?.color || colors[props.type]
    }`;

    const CurrentIcon = Icons[props.type];

    const [animStyles, setAnimStyles]: [any, Function] = useState({ opacity: 0, [marginType]: -15 });

    const style = {
        paddingLeft: props.heading ? 25 : undefined,
        minHeight: props.heading ? 50 : undefined,
        borderLeft,
        ...animStyles,
    };

    const handleHide = (): void => {
        setAnimStyles({ opacity: 0, [marginType]: "-15px" });

        setTimeout(() => {
            props.onHide?.(props.id, props.position);
        }, 300);
    };

    useEffect(() => {
        const animTimeout = setTimeout(() => {
            setAnimStyles({ opacity: 1, [marginType]: "15px" });
        }, 50);

        let hideTimeout: any = undefined;

        if (props.hideAfter !== 0) {
            hideTimeout = setTimeout(() => {
                handleHide();
            }, props.hideAfter * 1000);
        }

        return () => {
            clearTimeout(animTimeout);

            if (hideTimeout) {
                clearTimeout(hideTimeout);
            }
        };
    }, []);

    useEffect(() => {
        if (!props.show) {
            handleHide();
        }
    }, [props.show]);

    const clickProps = {
        tabIndex: 0,
        onClick: props.onClick,
        onKeyPress: (e: any) => {
            if (e.keyCode === 13) {
                props.onClick?.(e);
            }
        },
    };

    return (
        <div
            className={className}
            role={props.role ? props.role : "status"}
            style={style}
            {...(props.onClick ? clickProps : {})}
        >
            {props.renderIcon ? props.renderIcon() : <CurrentIcon />}
            <div className={props.heading ? "ct-text-group-heading" : "ct-text-group"}>
                {props.heading && <h4 className="ct-heading">{props.heading}</h4>}
                <div className="ct-text">{props.text}</div>
            </div>
        </div>
    );
};

export default Toast;
