import React, { Component } from "react";
import styled from "styled-components";
import { Colors } from "./Colors";

const Input = styled.input<{
    borderColor: string;
    borderColorFocused: string;
    borderRadius: number;
    disabled: boolean;
    fillWidth: boolean;
}>`
    padding: 9px 10px 9px 10px;
    border: 2px solid ${(props) => props.borderColor};
    border-radius: ${(props) => props.borderRadius}px;
    color: ${(props) => (props.disabled ? Colors.disabledText : Colors.text)};
    font-weight: 500;
    font-size: 17px;
    background-color: ${Colors.lightBackground};
    min-width: 0;
    width: ${(p) => (p.fillWidth ? "100%" : "auto")};
    :focus {
        border: 2px solid ${(props) => props.borderColorFocused};
    }
    :invalid {
        box-shadow: none;
    }
`;

const Textarea = styled.textarea<{
    borderColor: string;
    borderColorFocused: string;
    borderRadius: number;
    disabled: boolean;
    fillWidth: boolean;
}>`
    padding: 9px 10px 9px 10px;
    border: 2px solid ${(props) => props.borderColor};
    border-radius: ${(props) => props.borderRadius}px;
    color: ${(props) => (props.disabled ? Colors.disabledText : Colors.text)};
    font-weight: 500;
    font-size: 17px;
    background-color: ${Colors.lightBackground};
    min-width: 0;
    width: ${(p) => (p.fillWidth ? "100%" : "auto")};
    :focus {
        border: 2px solid ${(props) => props.borderColorFocused};
    }
    :invalid {
        box-shadow: none;
    }
`;

const Container = styled.div<{ fillWidth: boolean; disabled: boolean }>`
    display: flex;
    flex-direction: column;
    width: ${(p) => (p.fillWidth ? "100%" : "auto")};
    opacity: ${(p) => (p.disabled ? 0.5 : 1)};
`;

const Title = styled.h4`
    font-size: 17px;
    font-weight: bold;
    margin: 0 0 4px 0;
    color: ${Colors.text};
`;

export enum InputFormatter {
    DATE = "date", // dd-mm-jjjj
    TIME = "time", // HH:mm
}

interface Props {
    disabled: boolean;
    title: string | null;
    autoComplete: string;
    text: string | null;
    type: string;
    placeholder: string;
    alwaysShowBorder: boolean;
    borderRadius: number;
    borderColor: string;
    borderColorFocused: string;
    onTextChange?: (text: string) => void;
    onFocus?: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    value?: string | string[] | number;
    className?: string;
    fillWidth?: boolean;
    errorOccurred: boolean;
    multiline: boolean;
    formatter?: InputFormatter;
}

interface State {
    errorOccurred: boolean;
}

export class RoundInput extends Component<Props, State> {
    // Static properties

    public static defaultProps = {
        disabled: false,
        title: null,
        autoComplete: "",
        text: "",
        type: "text",
        placeholder: "",
        alwaysShowBorder: true,
        borderRadius: 12,
        borderColor: Colors.darkAccent,
        borderColorFocused: Colors.borderFocused,
        errorOccurred: false,
        multiline: false,
    };

    // Properties

    public get value(): string {
        return this.input.current?.value || "";
    }

    private readonly input: React.RefObject<HTMLInputElement | HTMLTextAreaElement> = React.createRef();

    // Public functions

    constructor(props: Props) {
        super(props);
        this.state = { errorOccurred: false };
    }

    public render(): JSX.Element {
        if (this.props.title == null) {
            return this.renderInput();
        } else {
            return (
                <Container fillWidth={this.props.fillWidth || false} disabled={this.props.disabled}>
                    <Title>{this.props.title}</Title>
                    {this.renderInput()}
                </Container>
            );
        }
    }

    public showError(): void {
        this.setState({ errorOccurred: true });
    }

    public hideError(): void {
        this.setState({ errorOccurred: false });
    }

    // Private functions

    private renderInput(): JSX.Element {
        return React.createElement(this.props.multiline ? Textarea : Input, {
            onChange: this.onChange.bind(this),
            onFocus: this.onFocus.bind(this),
            onKeyDown: this.onKeyDown.bind(this),
            ref: this.input,
            placeholder: this.props.placeholder,
            defaultValue: this.props.text,
            value: this.props.value,
            type: this.props.type,
            className: this.props.className,
            borderRadius: this.props.borderRadius,
            borderColor: this.getBorderColor(),
            borderColorFocused: this.props.borderColorFocused,
            disabled: this.props.disabled,
            autoComplete: this.props.autoComplete,
            fillWidth: this.props.fillWidth,
        });
    }

    private onFocus(event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>): void {
        this.hideError();
        if (this.props.onFocus) {
            this.props.onFocus(event);
        }
    }

    private onChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void {
        this.hideError();
        if (this.props.onTextChange) {
            this.props.onTextChange(event.target.value);
        }
    }

    private onKeyDown(e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>): void {
        const input = this.input.current;
        if (!input) {
            return;
        }
        if (!this.props.formatter) {
            return;
        }
        if (e.key === "Backspace" || e.key === "Delete") {
            return;
        }
        switch (this.props.formatter) {
            case InputFormatter.DATE:
                if ((input.value.length === 2 && e.key !== "-") || (input.value.length === 5 && e.key !== "-")) {
                    input.value = input.value + "-";
                }
                break;
            case InputFormatter.TIME:
                if (input.value.length === 2 && e.key !== ":") {
                    input.value = input.value + ":";
                }
                break;
        }
    }

    private getBorderColor(): string {
        if (this.state.errorOccurred || this.props.errorOccurred) {
            return Colors.danger;
        }
        if (!this.props.alwaysShowBorder) {
            return "transparent";
        }
        return this.props.borderColor;
    }
}
