export enum Gender {
    MALE = "male",
    TRANS_MALE_MALE_GENITALIA = "trans_male_male_genitalia",
    TRANS_FEMALE_MALE_GENITALIA = "trans_female_male_genitalia",
    TRANS_MALE_FEMALE_GENITALIA = "trans_male_female_genitalia",
    TRANS_FEMALE_FEMALE_GENITALIA = "trans_female_female_genitalia",
    NON_BINARY_MALE_GENITALIA = "nonbinary_male_genitalia",
    NON_BINARY_FEMALE_GENITALIA = "nonbinary_female_genitalia",
}

export function getGenderTranslationKey(gender: Gender): string {
    switch (gender) {
        case Gender.MALE:
            return "genders.male";
        case Gender.TRANS_MALE_MALE_GENITALIA:
            return "genders.transMaleWithMaleGenitalia";
        case Gender.TRANS_FEMALE_MALE_GENITALIA:
            return "genders.transFemaleWithMaleGenitalia";
        case Gender.TRANS_MALE_FEMALE_GENITALIA:
            return "genders.transMaleWithFemaleGenitalia";
        case Gender.TRANS_FEMALE_FEMALE_GENITALIA:
            return "genders.transFemaleWithFemaleGenitalia";
        case Gender.NON_BINARY_MALE_GENITALIA:
            return "genders.nonBinaryMaleGenitalia";
        case Gender.NON_BINARY_FEMALE_GENITALIA:
            return "genders.nonBinaryFemaleGenitalia";
    }
}
