import React from "react";
import styled from "styled-components";
import { Colors } from "./Colors";
import { ReactComponent as BackIcon } from "../../../src/res/images/back.svg";

const Button = styled.div`
    padding: 20px 0;
    display: flex;
    flex-direction: row;
    cursor: pointer;
    position: relative;
    align-items: center;
    font-size: 15px;
    font-weight: bold;
    span {
        color: ${Colors.background};
        margin-left: 14px;
    }
    :hover {
        opacity: 0.7;
    }
`;

interface Props {
    title: string;
    onClick?: () => void;
}

export class BackButton extends React.Component<Props> {
    // Public functions

    public constructor(props: Props) {
        super(props);
    }

    public render(): React.ReactNode {
        return (
            <Button onClick={this.props.onClick}>
                <BackIcon />
                <span>{this.props.title}</span>
            </Button>
        );
    }
}
