import { MessageSource, MessageType } from ".";

export class Message {
    // Public functions

    public constructor(
        public readonly id: string,
        public readonly subject: string,
        public readonly message: string,
        public readonly source: MessageSource,
        public readonly date: Date,
        public readonly type?: MessageType,
        public readonly sender?: UserReference,
        public readonly to?: UserReference,
    ) {}
}

interface UserReference {
    id: string;
    firstName: string;
    lastName: string;
}
