import { reverseString } from "../../utils/StringUtils";

export const StudyRegions: Record<string, StudyRegion> = {
    AMSTERDAM: {
        id: "amsterdam",
        name: "Amsterdam",
        address: "Nieuwe Achtergracht 100, 1018 WT Amsterdam",
        applyUrl: "https://www.ggd.amsterdam.nl/infectieziekten/soa-hiv-sense/prep/nationaal-prep-programma/",
        website: "https://www.ggd.amsterdam.nl/",
        privacyUrl: "https://www.ggd.amsterdam.nl/ggd/proclaimer/",
        proceduresUrl: "/onlineprepservice/amsterdam",
        eziPrepEmailAddress: reverseString("ln.madretsma.dgg@perpize"),
        eziPrepAppointmentsEmailAddress: reverseString("ln.madretsma.dgg@nekarpsfaperpize"),
        privacyEmailAddress: reverseString("ln.madretsma.dgg@ycavirp"),
        complaintsDetails: {
            website: "https://www.ggd.amsterdam.nl/ggd/klachten/",
            telephone: "020-555 5218",
            address: "GGD Amsterdam, t.a.v. de klachtencoördinator, Postbus 2200, 1000 CE Amsterdam.",
        },
    },
    ROTTERDAM: {
        id: "rotterdam",
        name: "Rotterdam-Rijnmond",
        address: "Schiedamsedijk 95, 3011 EN Rotterdam",
        applyUrl: "https://www.ggdrotterdamrijnmond.nl/soa-seksualiteit/prep/",
        website: "https://www.ggdrotterdamrijnmond.nl/",
        privacyUrl: "https://www.ggdrotterdamrijnmond.nl/privacy/",
        proceduresUrl: "/onlineprepservice/rotterdam-rijnmond",
        eziPrepEmailAddress: reverseString("ln.madrettor@perpize"),
        eziPrepAppointmentsEmailAddress: reverseString("ln.madrettor@perpize"),
        privacyEmailAddress: reverseString("ln.madrettor@gf"),
        complaintsDetails: {
            website: "https://www.ggdrotterdamrijnmond.nl/contact/klachtenregeling/",
            email: reverseString("ln.madrettor@OCBOM_reehebeitamrofnI"),
            telephone: "010 - 433 93 85",
            address: "GGD Rotterdam-Rijnmond t.a.v. de klachtencoördinator, Postbus 70032, 3000 LP  Rotterdam.",
        },
    },
    HAAGLANDEN: {
        id: "den_haag",
        name: "Haaglanden",
        address: "Westeinde 128, 2512 HE Den Haag",
        applyUrl: "https://www.seksuelegezondheidhaaglanden.nl/prep/wat-is-prep/",
        website: "https://www.ggdhaaglanden.nl/home.htm",
        privacyUrl: "https://www.ggdhaaglanden.nl/privacyverklaring-ggd-haaglanden.htm",
        proceduresUrl: "/onlineprepservice/haaglanden",
        eziPrepEmailAddress: reverseString("ln.nednalgaahdgg@perpize"),
        eziPrepAppointmentsEmailAddress: reverseString("ln.nednalgaahdgg@perpize"),
        privacyEmailAddress: reverseString("ln.gaahned@gnimrehcsebsnevegeg"),
        complaintsDetails: {
            website: "https://www.ggdrotterdamrijnmond.nl/contact/klachtenregeling/",
        },
    },
    GELDERLAND_ZUID: {
        id: "gelderland",
        name: "Gelderland-Zuid",
        address: "Groenewoudseweg 275, 6524 TV Nijmegen",
        applyUrl: "https://ggdgelderlandzuid.nl/seksuelegezondheid/soa-zorg/hiv/prep/",
        website: "https://ggdgelderlandzuid.nl/",
        privacyUrl: "https://ggdgelderlandzuid.nl/over-de-ggd/privacy/",
        proceduresUrl: "/onlineprepservice/gelderland-zuid",
        eziPrepEmailAddress: reverseString("ln.diuzdnalredlegdgg@perpize"),
        eziPrepAppointmentsEmailAddress: reverseString("ln.diuzdnalredlegdgg@perpize"),
        privacyEmailAddress: reverseString("ln.zgrv@etnarahcnavllom.ekiaam"),
        complaintsDetails: {
            website: "https://secure.ggdgelderlandzuid.nl/dir/klachtenformulier/",
            email: reverseString("ln.diuzdnalredlegdgg@siranoitcnufnethcalk"),
        },
    },
};
export interface StudyRegion {
    name: string;
    id: string;
    address: string;
    applyUrl: string;
    website: string;
    privacyUrl: string;
    proceduresUrl: string;
    eziPrepEmailAddress: string;
    eziPrepAppointmentsEmailAddress: string;
    privacyEmailAddress: string;
    complaintsDetails: ComplaintsDetails;
}
export interface ComplaintsDetails {
    website?: string;
    email?: string;
    telephone?: string;
    address?: string;
}

export const AmsterdamPharmacy = {
    name: "DC Apotheek",
    email: reverseString("ln.groz@perpize"),
    telephone: "088 0100 989",
    address: "Valeriusplein 11, 1075 BG Amsterdam",
};
