import { BaseViewModel } from "../BaseViewModel";
import { EventRepository, MessageRepository, UserRepository } from "../../domain/repositories";
import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import { Event, Message, RoleId, StudyRegion, StudyRegions, User, UserPermission } from "../../domain/model";
import { showError } from "../../utils/MessageUtils";

export enum ListMode {
    ADMINS = "admins",
    USERS = "users",
    PARTICIPANTS = "participants",
    EVENTS = "events",
    MESSAGES = "messages",
}

type QueryUpdate = {
    roles: RoleId[];
    region?: StudyRegion;
    query: string;
};

export class HomeViewModel extends BaseViewModel {
    // Properties
    public get usersObservable(): Rx.Observable<User[]> {
        return this.usersSubject.asObservable();
    }
    public get eventsObservable(): Rx.Observable<Event[]> {
        return this.eventsSubject.asObservable();
    }
    public get messagesObservable(): Rx.Observable<Message[]> {
        return this.messagesSubject.asObservable();
    }
    public get isLoadingUsersObservable(): Rx.Observable<boolean> {
        return this.isLoadingUsersSubject.asObservable();
    }
    public get isLoadingEventsObservable(): Rx.Observable<boolean> {
        return this.isLoadingEventsSubject.asObservable();
    }
    public get isLoadingMessagesObservable(): Rx.Observable<boolean> {
        return this.isLoadingMessagesSubject.asObservable();
    }
    public readonly allRegions = Object.values(StudyRegions);

    private readonly usersSubject = new Rx.BehaviorSubject<User[]>([]);
    private readonly eventsSubject = new Rx.BehaviorSubject<Event[]>([]);
    private readonly messagesSubject = new Rx.BehaviorSubject<Message[]>([]);
    private readonly isLoadingUsersSubject = new Rx.BehaviorSubject<boolean>(false);
    private readonly isLoadingEventsSubject = new Rx.BehaviorSubject<boolean>(false);
    private readonly isLoadingMessagesSubject = new Rx.BehaviorSubject<boolean>(false);
    private readonly queryUpdateSubject = new Rx.BehaviorSubject<QueryUpdate | null>(null);

    // Public functions

    public constructor(
        private readonly userRepository: UserRepository,
        private readonly eventRepository: EventRepository,
        private readonly messageRepository: MessageRepository,
    ) {
        super();
        this.subscribeToQueryUpdates();
    }

    public getCurrentUserInfo(): Rx.Observable<User> {
        return this.userRepository.getCurrentUserInfo();
    }

    public getListModes(currentUser: User): Record<string, string> {
        const modes: Record<string, string> = {};
        if (!currentUser) {
            return modes;
        }
        if (User.can(currentUser, UserPermission.SEE_ADMIN)) {
            modes[ListMode.ADMINS] = "Admins";
        }
        if (
            User.can(currentUser, UserPermission.SEE_CARETAKERS) ||
            User.can(currentUser, UserPermission.SEE_REGION_MANAGERS)
        ) {
            modes[ListMode.USERS] = "Zorgverleners";
        }
        modes[ListMode.PARTICIPANTS] = "Participanten";
        // TODO: Disabled since the backend is not ready https://innovattic.atlassian.net/browse/GGD-149
        // if (User.can(currentUser, UserPermission.SEE_EVENTS)) {
        //     modes[ListMode.EVENTS] = "Afspraken en events";
        // }
        if (User.can(currentUser, UserPermission.SEE_MESSAGES)) {
            modes[ListMode.MESSAGES] = "Berichten";
        }
        return modes;
    }

    public queryUpdated(region: StudyRegion | null, mode: ListMode, query: string): void {
        if (!this.userRepository.currentUser) {
            return;
        }
        if (mode == ListMode.EVENTS) {
            this.refreshEvents();
            return;
        }
        if (mode == ListMode.MESSAGES) {
            this.refreshMessages();
            return;
        }

        const roles = [];
        if (mode == ListMode.PARTICIPANTS) {
            roles.push(RoleId.PARTICIPANT);
        } else if (mode == ListMode.USERS) {
            if (User.can(this.userRepository.currentUser, UserPermission.SEE_REGION_MANAGERS)) {
                roles.push(RoleId.REGION_MANAGER);
            }
            if (User.can(this.userRepository.currentUser, UserPermission.SEE_CARETAKERS)) {
                roles.push(RoleId.CARETAKER);
            }
        } else if (mode == ListMode.ADMINS) {
            if (User.can(this.userRepository.currentUser, UserPermission.SEE_ADMIN)) {
                roles.push(RoleId.ADMIN);
            }
        }
        this.queryUpdateSubject.next({
            roles,
            query,
            region: mode != ListMode.PARTICIPANTS ? region || undefined : undefined,
        });
    }

    public downloadAllParticipantInfo(): Rx.Observable<Blob> {
        return this.userRepository.downloadAllParticipantInfo();
    }

    // Private functions

    private subscribeToQueryUpdates(): void {
        const sub = this.queryUpdateSubject.pipe(RxOperators.debounceTime(300)).subscribe(this.runQuery.bind(this));
        this.collectSubscription(sub);
    }

    private runQuery(update: QueryUpdate | null): void {
        if (update == null) {
            return;
        }
        if (this.isLoadingUsersSubject.value) {
            return;
        }
        const regions: (StudyRegion | null)[] = update.region ? [update.region] : [];
        // Region for admin users is null
        if (update.roles.includes(RoleId.ADMIN)) {
            regions?.push(null);
        }
        this.isLoadingUsersSubject.next(true);
        const subscription = this.userRepository.queryUsers(update.roles, update.query || null, regions).subscribe(
            (users) => {
                this.isLoadingUsersSubject.next(false);
                if (update != this.queryUpdateSubject.value) {
                    this.runQuery(this.queryUpdateSubject.value);
                } else {
                    this.usersSubject.next(users);
                }
            },
            (error) => {
                showError(error);
                this.isLoadingUsersSubject.next(false);
            },
        );
        this.collectSubscription(subscription);
    }

    private refreshEvents(): void {
        if (this.isLoadingEventsSubject.value) {
            return;
        }
        this.isLoadingEventsSubject.next(true);
        const subscription = this.eventRepository
            .getEventsForRegion(this.userRepository.currentUser!.region!)
            .subscribe(
                (events) => {
                    this.isLoadingEventsSubject.next(false);
                    this.eventsSubject.next(events);
                },
                (error) => {
                    showError(error);
                    this.isLoadingEventsSubject.next(false);
                },
            );
        this.collectSubscription(subscription);
    }

    private refreshMessages(): void {
        if (this.isLoadingMessagesSubject.value) {
            return;
        }
        this.isLoadingMessagesSubject.next(true);
        const subscription = this.messageRepository.getMessages().subscribe(
            (messages) => {
                this.isLoadingMessagesSubject.next(false);
                this.messagesSubject.next(messages);
            },
            (error) => {
                showError(error);
                this.isLoadingMessagesSubject.next(false);
            },
        );
        this.collectSubscription(subscription);
    }
}
