import styled from "styled-components";

export const HeadlineContainer = styled.div`
    h1 {
        font-size: 40px;
    }
    p {
        font-size: 17px;
    }
    h3 {
        font-size: 20px;
    }
`;
