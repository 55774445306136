import React from "react";
import { RoundButton } from "../appearance";
import { RoundInput } from "../appearance";
import styled from "styled-components";
import { BaseSubscriptionHandlerComponent } from "../BaseSubscriptionHandlerComponent";
import { TYPES } from "../../di/Types";
import DI from "../../di/DI";
import { showError, showSuccess } from "../../utils/MessageUtils";
import { ReactComponent as Logo } from "../../../src/res/images/logo_large.svg";
import { Colors } from "../appearance";
import { DeleteAccountViewModel } from "./deleteAccountViewModel";
import i18n from "i18next";

const Container = styled.div`
    display: flex;
    background-color: ${Colors.background};
    flex-direction: column;
    flex: 1;
    width: 100%;
    align-items: center;
    justify-content: center;
    h2 {
        margin-top: 0;
        margin-bottom: 32px;
        font-size: 28px;
    }
    p {
        padding: 0;
        margin: -17px 0 25px 0;
    }
    canvas {
        margin: auto;
    }
`;

const FormContainer = styled.form`
    display: flex;
    background-color: ${Colors.lightBackground};
    border-radius: 12px;
    width: 80%;
    max-width: 480px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 40px;
    margin-top: 45px;
    div {
        width: 100%;
        margin: 8px 0;
    }
    svg {
        margin-bottom: 45px;
    }
    button {
        width: 100%;
        margin-top: 8px;
    }
`;

enum Mode {
    LOGIN,
    DELETE_ACCOUNT,
}

interface LoginState {
    isBusy: boolean;
    mode: Mode;
}

export class DeleteAccount extends BaseSubscriptionHandlerComponent<Record<string, unknown>, LoginState> {
    // Properties

    private viewModel: DeleteAccountViewModel = DI.get(TYPES.DeleteAccountViewModel);
    private readonly usernameInput: React.RefObject<RoundInput>;
    private readonly passwordInput: React.RefObject<RoundInput>;

    // Public functions

    public constructor(props: Record<string, unknown>) {
        super(props);
        this.usernameInput = React.createRef();
        this.passwordInput = React.createRef();
        this.state = {
            isBusy: false,
            mode: Mode.LOGIN,
        };
    }

    public componentDidMount(): void {
        this.subscribeToObservables();
    }

    public componentWillUnmount(): void {
        super.componentWillUnmount();
        this.viewModel.unsubscribeFromObservables();
    }

    public render(): React.ReactNode {
        return (
            <Container>
                <Logo />
                {this.state.mode === Mode.LOGIN && this.renderLoginForm()}
                {this.state.mode === Mode.DELETE_ACCOUNT && this.renderRequestDeleteAccount()}
            </Container>
        );
    }

    // Private functions

    private renderLoginForm(): React.ReactNode {
        return (
            <FormContainer action={"#"}>
                <h2>{i18n.t("deleteAccount.title") as string}</h2>
                <p>{i18n.t("deleteAccount.description") as string}</p>
                <RoundInput
                    ref={this.usernameInput}
                    title={i18n.t("deleteAccount.email")}
                    disabled={this.state.isBusy}
                />
                <RoundInput
                    ref={this.passwordInput}
                    title={i18n.t("deleteAccount.password")}
                    disabled={this.state.isBusy}
                    type={"password"}
                    autoComplete={"current-password"}
                />
                <RoundButton
                    isLoading={this.state.isBusy}
                    text={i18n.t("deleteAccount.checkCredentials")}
                    onClick={this.login.bind(this)}
                />
            </FormContainer>
        );
    }

    private renderRequestDeleteAccount(): React.ReactNode {
        return (
            <FormContainer action={"#"}>
                <RoundButton
                    isLoading={this.state.isBusy}
                    text={i18n.t("deleteAccount.requestDeleteAccount")}
                    onClick={this.confirmDeleteAccount.bind(this)}
                />
            </FormContainer>
        );
    }

    private login(event: React.MouseEvent): void {
        event.preventDefault();
        if (this.usernameInput.current == null || this.passwordInput.current == null) {
            return;
        }
        const username = this.usernameInput.current.value;
        const password = this.passwordInput.current.value;
        const subscription = this.viewModel.login(username, password).subscribe({ error: (error) => showError(error) });
        this.collectSubscription(subscription);
    }

    private confirmDeleteAccount(event: React.MouseEvent): void {
        event.preventDefault();
        const subscription = this.viewModel.confirmDeleteAccount().subscribe({
            error: (error) => showError(error),
            complete: () => showSuccess(i18n.t("deleteAccount.success")),
        });
        this.collectSubscription(subscription);
    }

    private subscribeToObservables(): void {
        this.assignToState(this.viewModel.isBusy, (isBusy) => ({ isBusy }));
        this.assignToState(this.viewModel.requestingDeleteAccount, (isRequesting) =>
            isRequesting ? { mode: Mode.DELETE_ACCOUNT } : null,
        );
    }
}
