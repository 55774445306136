import React from "react";
import { BaseSubscriptionHandlerComponent } from "../BaseSubscriptionHandlerComponent";
import { RouteComponentProps } from "react-router-dom";
import { TestLabAuthenticateViewModel } from "./TestLabAuthenticateViewModel";
import { TYPES } from "../../di/Types";
import DI from "../../di/DI";
import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";

interface Props extends RouteComponentProps<{ accessToken: string; eventId: string }> {}

export class TestLabAuthenticate extends BaseSubscriptionHandlerComponent<Props> {
    private readonly viewModel: TestLabAuthenticateViewModel = DI.get(TYPES.TestLabAuthenticateViewModel);
    private readonly container: React.RefObject<HTMLDivElement> = React.createRef();

    public constructor(props: Readonly<Props>) {
        super(props);
    }

    public componentDidMount(): void {
        Rx.concat(this.viewModel.login(this.props.match.params.accessToken), Rx.of(true))
            .pipe(RxOperators.mergeMap(() => this.viewModel.getTestlabInfo()))
            .subscribe((info) => this.submitForm(info.url, info.token));
    }

    public render(): React.ReactNode {
        return <div ref={this.container}></div>;
    }

    private submitForm(url: string, token: string): void {
        if (this.container.current) {
            const jwtField = document.createElement("input");
            jwtField.hidden = true;
            jwtField.name = "jwt";
            jwtField.value = token;
            jwtField.readOnly = true;
            const form = document.createElement("form");
            form.action = url;
            form.method = "POST";
            form.appendChild(jwtField);
            this.container.current.appendChild(form);
            form.submit();
        } else {
            console.error("Container is not mounted yet");
        }
    }
}
