import i18next, { StringMap, TOptions } from "i18next";

/* eslint-disable */

i18next.array = function <TInterpolationMap extends object = StringMap>(
    key: string,
    options?: TOptions<TInterpolationMap> | string,
): string[] {
    const o = options ?? {};
    return i18next.t<string[]>(key, { returnObjects: true, ...o });
};

i18next.object = function <T, TInterpolationMap extends object = StringMap>(
    key: string,
    options?: TOptions<TInterpolationMap> | string,
): T {
    const o = options ?? {};
    return i18next.t<any>(key, { returnObjects: true, ...o }) as T;
};

/* eslint-enable */

export default i18next;
