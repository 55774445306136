import React from "react";
import { RoundButton } from "../appearance/RoundButton";
import { RoundInput } from "../appearance/RoundInput";
import styled from "styled-components";
import { LoginViewModel } from "./LoginViewModel";
import { BaseSubscriptionHandlerComponent } from "../BaseSubscriptionHandlerComponent";
import { TYPES } from "../../di/Types";
import DI from "../../di/DI";
import { showError, showSuccess } from "../../utils/MessageUtils";
import { ReactComponent as Logo } from "../../../src/res/images/logo_large.svg";
import { Colors } from "../appearance/Colors";
import QRCode from "qrcode";
import { ButtonsContainer } from "../appearance/ButtonsContainer";
import { Link } from "../appearance/Link";
import { Path } from "../App";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    justify-content: center;
    h2 {
        margin-top: 0;
        margin-bottom: 32px;
        font-size: 28px;
    }
    p {
        padding: 0;
        margin: -17px 0 25px 0;
    }
    canvas {
        margin: auto;
    }
`;

const FormContainer = styled.form`
    display: flex;
    background-color: ${Colors.lightBackground};
    border-radius: 12px;
    width: 80%;
    max-width: 480px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 40px;
    margin-top: 45px;
    div {
        width: 100%;
        margin: 8px 0;
    }
    svg {
        margin-bottom: 45px;
    }
    button {
        width: 100%;
        margin-top: 8px;
    }
`;

enum Mode {
    LOGIN,
    SETUP_TFA,
    ENTER_TFA,
}

interface LoginState {
    isBusy: boolean;
    mode: Mode;
}

export class Login extends BaseSubscriptionHandlerComponent<Record<string, unknown>, LoginState> {
    // Properties

    private viewModel: LoginViewModel = DI.get(TYPES.LoginViewModel);
    private readonly usernameInput: React.RefObject<RoundInput>;
    private readonly passwordInput: React.RefObject<RoundInput>;
    private readonly verificationCodeInput: React.RefObject<RoundInput>;

    // Public functions

    public constructor(props: Record<string, unknown>) {
        super(props);
        this.usernameInput = React.createRef();
        this.passwordInput = React.createRef();
        this.verificationCodeInput = React.createRef();
        this.state = {
            isBusy: false,
            mode: Mode.LOGIN,
        };
    }

    public componentDidMount(): void {
        this.subscribeToObservables();
    }

    public componentWillUnmount(): void {
        super.componentWillUnmount();
        this.viewModel.unsubscribeFromObservables();
    }

    public render(): React.ReactNode {
        return (
            <Container>
                <Logo />
                {this.state.mode === Mode.LOGIN && this.renderLoginForm()}
                {this.state.mode === Mode.SETUP_TFA && this.renderSetupTFA()}
                {this.state.mode === Mode.ENTER_TFA && this.renderEnterTFA()}
            </Container>
        );
    }

    // Private functions

    private renderSetupTFA(): React.ReactNode {
        return (
            <FormContainer action={"#"}>
                <h2>Setup authenticatie</h2>
                <p>
                    Scan de QR-code met de Google Authenticator App. Vul vervolgens de 6-cijferige verificatiecode in.
                </p>
                <canvas id="canvas" />
                <RoundInput ref={this.verificationCodeInput} title={"Verificatiecode"} disabled={this.state.isBusy} />
                <ButtonsContainer>
                    <RoundButton
                        type="button"
                        text={"Annuleren"}
                        background={Colors.darkAccent}
                        textColor={Colors.background}
                        onClick={this.cancelSetupTFA.bind(this)}
                    />
                    <RoundButton isLoading={this.state.isBusy} text={"OK"} onClick={this.submitSetupTFA.bind(this)} />
                </ButtonsContainer>
            </FormContainer>
        );
    }

    private renderEnterTFA(): React.ReactNode {
        return (
            <FormContainer action={"#"}>
                <h2>Verificatie</h2>
                <p>Open de Google Authenticator App en vul de verificatiecode in.</p>
                <RoundInput ref={this.verificationCodeInput} title={"Verificatiecode"} disabled={this.state.isBusy} />
                <ButtonsContainer>
                    <RoundButton
                        type="button"
                        text={"Annuleren"}
                        background={Colors.darkAccent}
                        textColor={Colors.background}
                        onClick={this.cancelEnterTFA.bind(this)}
                    />
                    <RoundButton isLoading={this.state.isBusy} text={"OK"} onClick={this.submitEnterTFA.bind(this)} />
                </ButtonsContainer>
            </FormContainer>
        );
    }

    private renderLoginForm(): React.ReactNode {
        return (
            <FormContainer action={"#"}>
                <h2>Inloggen</h2>
                <RoundInput ref={this.usernameInput} title={"E-mailadres"} disabled={this.state.isBusy} />
                <RoundInput
                    ref={this.passwordInput}
                    title={"Wachtwoord"}
                    disabled={this.state.isBusy}
                    type={"password"}
                    autoComplete={"current-password"}
                />
                <RoundButton isLoading={this.state.isBusy} text={"Inloggen"} onClick={this.login.bind(this)} />
                <br />
                <Link to={Path.public.user.resetPassword}>Wachtwoord vergeten?</Link>
            </FormContainer>
        );
    }

    private login(event: React.MouseEvent): void {
        event.preventDefault();
        if (this.usernameInput.current == null || this.passwordInput.current == null) {
            return;
        }
        const username = this.usernameInput.current.value;
        const password = this.passwordInput.current.value;
        const subscription = this.viewModel.login(username, password).subscribe({ error: (error) => showError(error) });
        this.collectSubscription(subscription);
    }

    private submitSetupTFA(event: React.MouseEvent): void {
        event.preventDefault();
        if (this.verificationCodeInput.current == null) {
            return;
        }
        const subscription = this.viewModel.confirmTFA(parseInt(this.verificationCodeInput.current.value)).subscribe({
            complete: () => {
                this.logOut();
                showSuccess("Tweefactorauthenticatie(2FA) succesvol ingeschakeld, log opnieuw in");
            },
            error: (error) => {
                showError(error);
                this.logOut();
            },
        });
        this.collectSubscription(subscription);
    }

    private cancelSetupTFA(event: React.MouseEvent): void {
        event.preventDefault();
        this.logOut();
    }

    private submitEnterTFA(event: React.MouseEvent): void {
        event.preventDefault();
        if (this.verificationCodeInput.current == null) {
            return;
        }
        const subscription = this.viewModel.loginWithTFA(parseInt(this.verificationCodeInput.current.value)).subscribe({
            error: (error) => {
                showError(error || { message: "" });
                this.logOut();
            },
        });
        this.collectSubscription(subscription);
    }

    private cancelEnterTFA(event: React.MouseEvent): void {
        event.preventDefault();
        this.logOut();
    }

    private subscribeToObservables(): void {
        this.assignToState(this.viewModel.isBusy, (isBusy) => ({ isBusy }));
        this.assignToState(this.viewModel.tfaVerificationNeeded, (isNeeded) =>
            isNeeded ? { mode: Mode.ENTER_TFA } : null,
        );
        const subscription = this.viewModel.tfaSetupNeeded.subscribe((value) => {
            if (value) {
                this.setState({ mode: Mode.SETUP_TFA });
                setTimeout(() => {
                    QRCode.toCanvas(document.getElementById("canvas"), this.viewModel.tfaSetupUri!, (error) =>
                        showError(error),
                    );
                }, 0);
            }
        });
        this.collectSubscription(subscription);
    }

    private logOut(): void {
        this.updateStateOnComplete(this.viewModel.logout(), () => ({ mode: Mode.LOGIN }));
    }
}
