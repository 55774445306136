import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";

export interface LocalStorageRepository {
    getAll(): Map<string, string>;
    getValue<T>(key: string, defaultValue?: T): T | null;
    setValue<T>(key: string, value: T): void;
    removeValue(key: string): void;
    clearAll(): void;
    observeValue<T>(key: string): Rx.Observable<T | null>;
}

export interface StorageUpdate {
    key: string;
    value: unknown | null;
}

export class DefaultLocalStorageRepository implements LocalStorageRepository {
    // Properties

    private readonly updateSubject = new Rx.Subject<StorageUpdate>();

    // Public functions

    public getAll(): Map<string, string> {
        const items = new Map<string, string>();
        for (let i = 0; i < localStorage.length; i++) {
            const key = localStorage.key(i)!;
            items.set(key, localStorage.getItem(key)!);
        }
        return items;
    }

    public getValue<T>(key: string, defaultValue?: T | null | undefined): T | null {
        const resultString = localStorage.getItem(key);
        if (resultString == null) {
            return defaultValue || null;
        }
        return JSON.parse(resultString) as T | null;
    }

    public setValue<T>(key: string, value: T): void {
        localStorage.setItem(key, JSON.stringify(value));
        this.updateSubject.next({ key: key, value: value });
    }

    public removeValue(key: string): void {
        localStorage.removeItem(key);
        this.updateSubject.next({ key: key, value: null });
    }

    public clearAll(): void {
        let i = 0;
        while (i < localStorage.length) {
            const key = localStorage.key(i);
            if (key == null) {
                break;
            }
            this.updateSubject.next({ key: key, value: null });
            i++;
        }
        localStorage.clear();
    }

    public observeValue<T>(key: string): Rx.Observable<T | null> {
        const broadcastSubject = this.updateSubject.asObservable().pipe(
            RxOperators.filter((update) => update.key === key),
            RxOperators.map((p) => p.value as T | null),
            RxOperators.distinctUntilChanged(),
        );
        const initialValue = Rx.of(this.getValue<T>(key));
        return Rx.merge(broadcastSubject, initialValue);
    }
}
