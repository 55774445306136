import { UserPermission } from "./UserPermission";
import { UserInfoResponse } from "../../infrastructure/api/models";

export enum RoleId {
    ADMIN = "admin",
    REGION_MANAGER = "region_manager",
    CARETAKER = "caretaker",
    PARTICIPANT = "participant",
}

export class UserRole {
    // Static functions

    public static fromJson(model: UserInfoResponse): UserRole {
        const id = model.role.toLowerCase();
        return this.fromId(id as RoleId);
    }

    public static fromId(id: RoleId): UserRole {
        const permissions: UserPermission[] = [];
        if (id === RoleId.ADMIN) {
            permissions.push(UserPermission.ADD_REGION_MANAGER);
            permissions.push(UserPermission.SEE_REGION_MANAGERS);
            permissions.push(UserPermission.SEE_CARETAKERS);
            permissions.push(UserPermission.SEE_ADMIN);
            permissions.push(UserPermission.ADD_ADMIN);
            permissions.push(UserPermission.DOWNLOAD_STUDY_RESULTS);
            permissions.push(UserPermission.CHANGE_PARTICIPANT_EMAIL);
            permissions.push(UserPermission.SEE_MESSAGES);
            permissions.push(UserPermission.SEND_MESSAGES_TO_ALL);
        }
        if (id === RoleId.REGION_MANAGER) {
            permissions.push(UserPermission.SEE_CARETAKERS);
            permissions.push(UserPermission.ADD_CARETAKER);
            permissions.push(UserPermission.SEE_EVENTS);
            permissions.push(UserPermission.CHANGE_PARTICIPANT_EMAIL);
            permissions.push(UserPermission.SEE_MESSAGES);
            permissions.push(UserPermission.SEND_MESSAGES_TO_ALL);
        }
        if (id === RoleId.CARETAKER) {
            permissions.push(UserPermission.SEE_EVENTS);
            permissions.push(UserPermission.SEE_MESSAGES);
        }
        return new UserRole(id, permissions);
    }

    // Public functions

    public constructor(public readonly id: string, public readonly permissions: UserPermission[]) {}
}
