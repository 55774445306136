import { BaseViewModel } from "../BaseViewModel";
import { MessageRepository } from "../../domain/repositories";
import * as Rx from "rxjs";
import { Message, MessageSource } from "../../domain/model";

export class NewMessageViewModel extends BaseViewModel {
    public constructor(private readonly messageRepository: MessageRepository) {
        super();
    }

    public sendMessage(
        source: MessageSource,
        subject: string,
        body: string,
        participantId?: string,
    ): Rx.Observable<void> {
        const message = new Message("", subject, body, source, new Date());
        if (participantId) {
            return this.messageRepository.sendMessageToParticipant(message, participantId);
        } else {
            return this.messageRepository.sendMessageToAll(message);
        }
    }
}
