import styled from "styled-components";

export const SectionContainer = styled.div<{ textColor?: string }>`
    display: flex;
    align-items: center;
    justify-content: center;
    p {
        margin-top: 16px;
        margin-bottom: 24px;
        font-size: 20px;
        line-height: 1.5;
        color: ${(p) => p.textColor};
    }
    h1,
    h3,
    h2,
    a {
        margin: 0;
        color: ${(p) => p.textColor};
    }
    h1 {
        font-size: 40px;
    }
    h2 {
        font-size: 28px;
    }
    h3 {
        font-size: 20px;
    }
`;
