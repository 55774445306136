import React, { PropsWithChildren } from "react";
import { Modal, ModalProps, ModalState } from "./Modal";
import { RoundButton } from "./RoundButton";
import { Colors } from "./Colors";
import styled from "styled-components";

interface Props extends ModalProps {
    onClosed: (confirmed?: boolean) => void;
}

interface State extends ModalState {}

const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    button:first-child {
        margin-right: 10px;
    }
`;

export class AlertModal extends Modal<PropsWithChildren<Props>, State> {
    // Public functions

    constructor(props: PropsWithChildren<Props>) {
        super(props);
        this.state = { showing: false };
    }
    public renderContent(): React.ReactNode {
        return (
            <div>
                {this.props.children}
                <ButtonsContainer>
                    <RoundButton
                        type="button"
                        text={"Annuleren"}
                        background={Colors.darkAccent}
                        textColor={Colors.background}
                        onClick={() => {
                            this.close();
                        }}
                    />
                    <RoundButton
                        text={"OK"}
                        onClick={() =>
                            this.close(() => {
                                this.props.onClosed(true);
                            })
                        }
                    />
                </ButtonsContainer>
            </div>
        );
    }
}
