import { BaseViewModel } from "../BaseViewModel";
import { UserRepository } from "../../domain/repositories";
import * as Rx from "rxjs";

export class PasswordRecoveryViewModel extends BaseViewModel {
    public constructor(private readonly userRepository: UserRepository) {
        super();
    }

    public requestPasswordReset(email: string): Rx.Observable<void> {
        return this.userRepository.requestPasswordReset(email);
    }

    public resetPassword(id: string, token: string, password: string): Rx.Observable<void> {
        return this.userRepository.confirmPasswordReset(id, token, password);
    }
}
