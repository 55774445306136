import React from "react";
import styled from "styled-components";
import { BaseSubscriptionHandlerComponent } from "../BaseSubscriptionHandlerComponent";
import { Colors } from "../appearance/Colors";
import { Event, fullNameOf } from "../../domain/model";
import { hexColorWithAlpha } from "../../utils/ColorUtils";
import DI from "../../di/DI";
import { TYPES } from "../../di/Types";
import { DateFormatter } from "../../infrastructure/DateFormatter";
import { EventIcon } from "./EventIcon";
import { Link } from "../appearance/Link";
import { Path } from "../App";

const Container = styled.div`
    background-color: ${Colors.lightBackground};
    padding: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin: 10px 0px;
    border-radius: 16px;
    position: relative;
    justify-content: space-between;
`;

const LeadingContent = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const DescContainer = styled.div`
    margin-left: 12px;
    h4 {
        font-size: 15px;
        margin: 0;
    }
    p {
        margin: 0;
        font-size: 15px;
    }
`;

const TimeContainer = styled.div`
    color: ${hexColorWithAlpha(Colors.text, 0.75)};
    p {
        text-align: right;
        margin: 0;
        font-size: 15px;
    }
`;

interface Props {
    event: Event;
}

interface State {}

export class EventListRow extends BaseSubscriptionHandlerComponent<Props, State> {
    // Properties

    private readonly dateFormatter: DateFormatter = DI.get(TYPES.DateFormatter);

    // Public functions

    public constructor(props: Props) {
        super(props);
        this.state = {};
    }

    public render(): React.ReactNode {
        return (
            <Link to={Path.admin.participant.profile(this.props.event.participant!.id)}>
                <Container>
                    <LeadingContent>
                        <EventIcon event={this.props.event} />
                        <DescContainer>
                            <h4>{this.props.event.description}</h4>
                            {this.props.event.participant && <p>{fullNameOf(this.props.event.participant)}</p>}
                        </DescContainer>
                    </LeadingContent>

                    <TimeContainer>
                        {this.props.event.plannedDate ? (
                            <p>
                                {this.dateFormatter.formatTime(this.props.event.plannedDate)}
                                <br />
                                {this.dateFormatter.formatDateDay(this.props.event.plannedDate, true, true)}
                            </p>
                        ) : (
                            <p>_</p>
                        )}
                    </TimeContainer>
                </Container>
            </Link>
        );
    }
}
