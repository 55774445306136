import React from "react";
import styled from "styled-components";
import { Colors } from "./Colors";
import { ReactComponent as BackIcon } from "../../../src/res/images/back.svg";
import { NavLink } from "react-router-dom";

const Link = styled(NavLink)`
    padding: 20px 0;
    display: flex;
    flex-direction: row;
    cursor: pointer;
    position: relative;
    text-decoration: none;
    align-items: center;
    font-size: 15px;
    font-weight: bold;

    span {
        color: ${Colors.background};
        margin-left: 14px;
    }

    :hover {
        opacity: 0.7;
    }
`;

interface Props {
    title: string;
    to?: string;
}

export class BackLink extends React.Component<Props> {
    // Public functions

    public constructor(props: Props) {
        super(props);
    }

    generateBackUrl(): string {
        // When opening a page on new tab, history state is empty so back button will
        // navigate user to the previous state of browser history which can be anything.
        // This function will generate an url based on origin so we keep user inside the app.
        let url = window.location.href;
        if (url.charAt(url.length - 1) === "/") {
            url = url.slice(0, url.length - 1);
        }
        url = url.slice(0, url.lastIndexOf("/"));
        const backUrlWithoutOrigin = url.replace(window.location.origin, "");
        return backUrlWithoutOrigin.length ? backUrlWithoutOrigin : "/";
    }

    public render(): React.ReactNode {
        return (
            <Link
                to={this.props.to || this.generateBackUrl()}
                replace={!this.props.to}
                exact
                onClick={(e) => {
                    if (this.props.to) {
                        return;
                    }
                    if (history.state && this.generateBackUrl() !== "/") {
                        e.preventDefault();
                        history.go(-1);
                        return false;
                    }
                }}
            >
                <BackIcon />
                <span>{this.props.title}</span>
            </Link>
        );
    }
}
