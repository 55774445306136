import styled from "styled-components";
import { Colors } from "../appearance";

export const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    width: 97%;
    max-width: 480px;
    margin-top: 10px;
`;
export const ContentContainer = styled.div`
    display: flex;
    background-color: ${Colors.lightBackground};
    border-radius: 12px;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px;
    margin-top: 16px;
    margin-bottom: 100px;
    > h3 {
        font-size: 20px;
        margin-top: 0px;
        margin-bottom: 24px;
    }
    > p {
        font-size: 16px;
        margin: 0 0 20px 0;
    }
`;
