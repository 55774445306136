export interface RGB {
    r: number;
    g: number;
    b: number;
}

export function hexToRGB(hex: string): RGB {
    let c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split("");
        if (c.length === 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        c = ("0x" + c.join("")) as any;
        return { r: (c >> 16) & 255, g: (c >> 8) & 255, b: c & 255 };
    }
    throw new Error("Bad Hex");
}

export function hexColorWithAlpha(hex: string, alpha: number): string {
    const rgb = hexToRGB(hex);
    return `rgba(${rgb.r},${rgb.g},${rgb.b}, ${alpha})`;
}
