import React from "react";
import { ContentPageContainer } from "./content/ContentPageContainer";
import { Colors, HeadlineContainer, Mailto, ReverseText, translated, translatedParagraph } from "../appearance";
import { reverseString } from "../../utils/StringUtils";
import { AmsterdamPharmacy, StudyRegions } from "../../domain/model";
import { SectionContainer } from "./content/SectionContainer";
import styled from "styled-components";

const FieldsContainer = styled.div`
    display: flex;
    margin-bottom: 8px;
`;

const FieldTitle = styled.span`
    width: 40%;
`;

const ColumnsContainer = styled(SectionContainer)`
    p {
        font-size: 17px;
    }
    > div {
        width: 100%;
        display: flex;
        > div {
            display: flex;
            flex-direction: row;
            flex-flow: row wrap;
            justify-content: center;

            @media (max-width: 768px) {
                flex-direction: column;
                flex-flow: column wrap;
            }
            > div {
                padding-right: 36px;
                max-width: 50%;

                :nth-child(1) {
                    margin-bottom: 24px;
                }

                @media (max-width: 768px) {
                    padding-right: 12px;
                    max-width: 100%;
                }
            }
        }
    }
`;
ColumnsContainer.defaultProps = { textColor: Colors.text };

export class Contact extends React.Component {
    public render(): React.ReactNode {
        return (
            <ContentPageContainer>
                <HeadlineContainer>{translated("h1", "content.contact.title")}</HeadlineContainer>
                <HeadlineContainer>
                    {translated("h3", "content.contact.doYouHaveAGeneralQuestionTitle")}
                    {translatedParagraph(
                        "content.contact.doYouHaveAGeneralQuestionDesc",
                        reverseString(StudyRegions.AMSTERDAM.eziPrepEmailAddress),
                    )}
                </HeadlineContainer>
                <HeadlineContainer>
                    {translated("h3", "content.contact.doYouHaveARegionSpecificQuestionTitle")}
                    {translatedParagraph("content.contact.doYouHaveARegionSpecificQuestionDesc")}
                    {Object.values(StudyRegions).map((r) => (
                        <FieldsContainer key={r.id}>
                            <FieldTitle>{`GGD ${r.name}: `}</FieldTitle>
                            <Mailto email={r.eziPrepAppointmentsEmailAddress} obfuscate>
                                <ReverseText>{reverseString(r.eziPrepAppointmentsEmailAddress)}</ReverseText>
                            </Mailto>
                            <br />
                        </FieldsContainer>
                    ))}
                </HeadlineContainer>
                <HeadlineContainer>
                    {translated("h3", "content.contact.dcPharmacyContactTitle")}
                    {translatedParagraph(
                        "content.contact.dcPharmacyContactDesc",
                        reverseString(AmsterdamPharmacy.email),
                        AmsterdamPharmacy.address,
                        AmsterdamPharmacy.telephone,
                    )}
                </HeadlineContainer>
            </ContentPageContainer>
        );
    }
}
