import React, { PropsWithChildren } from "react";
import styled from "styled-components";
import { Colors } from "./Colors";
import { Toolbar } from "../toolbar/Toolbar";

const Container = styled.div`
    background-color: ${Colors.homeBackground};
    flex-direction: column;
    align-items: center;
    display: flex;
    flex: auto;
    overflow: auto;
`;

const Top = styled.div`
    position: relative;
    margin-bottom: 68px;
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface Props extends PropsWithChildren<any> {}

export class ContentContainer extends React.Component<Props> {
    // Public functions

    public render(): React.ReactNode {
        return (
            <Container>
                <Top id="top"></Top>
                <Toolbar />
                {this.props.children}
            </Container>
        );
    }
}
