import React from "react";
import styled from "styled-components";
import { RouteComponentProps } from "react-router-dom";
import { Event, EventStatus, EventType, fullNameOf, User, ValidationPattern } from "../../domain/model";
import { BaseSubscriptionHandlerComponent } from "../BaseSubscriptionHandlerComponent";
import { TYPES } from "../../di/Types";
import DI from "../../di/DI";
import { EventEditViewModel } from "./EventEditViewModel";
import { BackLink, Colors, InputFormatter, LoadingPanel, RoundButton, RoundInput } from "../appearance";
import { showError, showSuccess } from "../../utils/MessageUtils";
import { DateFormatter } from "../../infrastructure/DateFormatter";
import { Path } from "../App";
import { DAY } from "../../utils/DateTimeUtils";
import { PREP_NUMBER_OF_PILLS_IN_PACKAGE } from "../../domain/repositories";
import i18n from "i18next";

const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    width: 90%;
    max-width: 480px;
`;
const FormContainer = styled.form`
    display: flex;
    background-color: ${Colors.lightBackground};
    border-radius: 12px;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 40px;
    margin-top: 16px;
    margin-bottom: 100px;
    > h3 {
        font-size: 28px;
        margin-top: 0px;
        margin-bottom: 0px;
    }
    > p {
        margin-top: 3px;
    }
    > button {
        margin-top: 36px;
    }
    > button:last-child {
        margin-top: 20px;
    }
`;
const DateAndTimeContainer = styled.div`
    display: flex;
    div:first-child {
        flex: 2;
    }
    div:last-child {
        flex: 1;
        margin-left: 12px;
    }
`;
const FieldsContainer = styled.div`
    > div {
        margin-top: 26px;
    }
`;
const ActionButtonsContainer = styled.div`
    margin-top: 26px;
    display: flex;
    align-items: center;
    width: 100%;

    > * {
        flex: 1;

        :not(:first-child) {
            margin-left: 24px;
        }
    }
`;
const PrepOrderDateAndTimeContainer = styled.div`
    display: flex;
    flex-direction: column;
    div:nth-child(2) {
        margin-top: 16px;
        width: 60%;
    }
    span {
        width: 60%;
        text-align: center;
    }
`;

interface Props extends RouteComponentProps<{ userId: string; eventId: string }> {}
interface State {
    user: User | null;
    currentEvent: Event | null;
    events: Event[];
    isSubmitting: boolean;
    fieldErrors: (keyof State)[];
    // limeSurvey fields
    limeSurveyLink: string | null;
    // online/offline meeting fields
    meetingDate: string | null;
    meetingTime: string | null;
    videoLink: string | null;
    // check up fields
    checkupDate: string | null;
    checkupTime: string | null;
    // prep order fields
    prepOrderDate: string | null;
    prepOrderAmount: string | null;
    // dbs fields
    dbsDate: string | null;
}

export class EventEdit extends BaseSubscriptionHandlerComponent<Props, State> {
    // Properties

    private readonly viewModel: EventEditViewModel = DI.get(TYPES.EventEditViewModel);
    private readonly dateFormatter: DateFormatter = DI.get(TYPES.DateFormatter);
    private get isPlanning(): boolean {
        return (
            this.state.currentEvent?.status == EventStatus.CREATED ||
            this.state.currentEvent?.status == EventStatus.CANCELLED
        );
    }

    // Public functions

    public constructor(props: Props) {
        super(props);
        this.state = {
            user: null,
            currentEvent: null,
            events: [],
            isSubmitting: false,
            fieldErrors: [],
            limeSurveyLink: null,
            checkupDate: null,
            checkupTime: null,
            meetingDate: null,
            meetingTime: null,
            videoLink: null,
            prepOrderDate: null,
            prepOrderAmount: null,
            dbsDate: null,
        };
    }

    public componentDidMount(): void {
        this.assignToState(this.viewModel.fetchUser(this.props.match.params.userId), (user) => ({ user }));
        this.assignToState(this.viewModel.fetchEvents(this.props.match.params.userId), (events) => {
            const event = events.find((e) => e.id == this.props.match.params.eventId)!;
            let updates: Omit<State, keyof State> = {};
            const plannedDate = (event.plannedDate && this.dateFormatter.formatDayMonthYear(event.plannedDate)) || null;
            const plannedTime = (event.plannedDate && this.dateFormatter.formatHourMinute(event.plannedDate)) || null;
            switch (event.type) {
                case EventType.QUESTIONNAIRE:
                    updates = { limeSurveyLink: event.url };
                    break;
                case EventType.TESTLAB:
                    const testlabDate = event.plannedDate || this.getTestlabPrefilledDate(events, event.period || "");
                    updates = {
                        checkupDate: (testlabDate && this.dateFormatter.formatDayMonthYear(testlabDate)) || null,
                        checkupTime: (testlabDate && this.dateFormatter.formatHourMinute(testlabDate)) || null,
                    };
                    break;
                case EventType.ONLINE_MEETING:
                    updates = {
                        meetingDate: plannedDate,
                        meetingTime: plannedTime,
                        videoLink: event.url,
                    };
                    break;
                case EventType.OFFLINE_MEETING:
                    updates = {
                        meetingDate: plannedDate,
                        meetingTime: plannedTime,
                    };
                    break;
                case EventType.ORDER_PILLS:
                    updates = {
                        prepOrderDate: plannedDate,
                        prepOrderAmount: event.numberOfPills && event.numberOfPills / PREP_NUMBER_OF_PILLS_IN_PACKAGE,
                    };
                    break;
                case EventType.DBS:
                    updates = {
                        dbsDate: plannedDate,
                    };
                    break;
                default:
                    throw Error("not supported event type");
            }
            return { ...updates, events, currentEvent: event };
        });
    }

    public render(): React.ReactNode {
        const eventStatus = this.state.currentEvent?.status;
        return (
            <Container>
                <BackLink
                    title={this.state.user ? fullNameOf(this.state.user) : ""}
                    to={(this.state.user && Path.admin.participant.profile(this.state.user.id)) || Path.admin.root}
                />
                {this.state.user == null && <LoadingPanel />}
                {this.state.user && (
                    <FormContainer>
                        <h3>{this.getTitle()}</h3>
                        <p>{this.getDescription()}</p>
                        {this.renderFormFields()}
                        <ActionButtonsContainer>
                            {eventStatus && [EventStatus.PLANNED, EventStatus.CREATED].includes(eventStatus) && (
                                <RoundButton
                                    type={"button"}
                                    background={Colors.darkAccent}
                                    textColor={Colors.background}
                                    isLoading={this.state.isSubmitting}
                                    onClick={this.onCancelClick.bind(this)}
                                    text={"Annuleren"}
                                />
                            )}
                            {eventStatus == EventStatus.PLANNED && (
                                <RoundButton
                                    type={"button"}
                                    background={Colors.darkAccent}
                                    textColor={Colors.background}
                                    isLoading={this.state.isSubmitting}
                                    onClick={this.onCompleteClick.bind(this)}
                                    text={"Voltooien"}
                                />
                            )}
                            {eventStatus != EventStatus.COMPLETED && (
                                <RoundButton
                                    isLoading={this.state.isSubmitting}
                                    onClick={this.onSubmitClick.bind(this)}
                                    fillWidth={true}
                                    text={this.getSubmitButtonTitle()}
                                />
                            )}
                        </ActionButtonsContainer>
                    </FormContainer>
                )}
            </Container>
        );
    }

    // Private functions

    private renderFormFields(): React.ReactNode {
        switch (this.state.currentEvent?.type) {
            case EventType.QUESTIONNAIRE:
                return this.renderInput("Link naar Limesurvey vragenlijst", "limeSurveyLink", undefined, "https://...");
            case EventType.TESTLAB:
                return (
                    <FieldsContainer>
                        <DateAndTimeContainer>
                            {this.renderInput("Datum (dd-mm-jjjj)", "checkupDate", InputFormatter.DATE)}
                            {this.renderInput("Tijd (uu:mm)", "checkupTime", InputFormatter.TIME)}
                        </DateAndTimeContainer>
                    </FieldsContainer>
                );
            case EventType.ONLINE_MEETING:
                return (
                    <FieldsContainer>
                        <DateAndTimeContainer>
                            {this.renderInput("Datum (dd-mm-jjjj)", "meetingDate", InputFormatter.DATE)}
                            {this.renderInput("Tijd (uu:mm)", "meetingTime", InputFormatter.TIME)}
                        </DateAndTimeContainer>
                        {this.state.videoLink &&
                            this.renderInput(
                                "Link naar videogesprek",
                                "videoLink",
                                undefined,
                                "https://...",
                                true,
                                true,
                            )}
                    </FieldsContainer>
                );
            case EventType.OFFLINE_MEETING:
                return (
                    <DateAndTimeContainer>
                        {this.renderInput("Datum (dd-mm-jjjj)", "meetingDate", InputFormatter.DATE)}
                        {this.renderInput("Tijd (uu:mm)", "meetingTime", InputFormatter.TIME)}
                    </DateAndTimeContainer>
                );
            case EventType.ORDER_PILLS:
                return (
                    <PrepOrderDateAndTimeContainer>
                        {this.renderInput("Datum voor bestelling (dd-mm-jjjj)", "prepOrderDate", InputFormatter.DATE)}
                        {this.renderInput("Aantal pakketten", "prepOrderAmount")}
                        {ValidationPattern.number.test(this.state.prepOrderAmount || "") && (
                            <span>
                                {parseInt(this.state.prepOrderAmount!) * PREP_NUMBER_OF_PILLS_IN_PACKAGE} PrEP pillen
                            </span>
                        )}
                    </PrepOrderDateAndTimeContainer>
                );
            case EventType.DBS:
                return (
                    <DateAndTimeContainer>
                        {this.renderInput("Datum (dd-mm-jjjj)", "dbsDate", InputFormatter.DATE)}
                        <span>{/* This empty span is a hack to allow us to reuse the same container css style */}</span>
                    </DateAndTimeContainer>
                );
            default:
                return <span />;
        }
    }

    private renderInput<K extends keyof State>(
        title: string,
        stateKey: K,
        formatter?: InputFormatter,
        placeholder = "",
        fillWidth = true,
        disabled = false,
    ): React.ReactNode {
        return (
            <RoundInput
                formatter={formatter}
                autoComplete={"none"}
                placeholder={placeholder}
                errorOccurred={this.state.fieldErrors.includes(stateKey)}
                text={this.state[stateKey] as string | null}
                onTextChange={(value) => this.updateFields({ [stateKey]: value } as Pick<State, K>)}
                fillWidth={fillWidth}
                title={title}
                disabled={disabled}
            />
        );
    }

    private updateFields<K extends keyof State>(update: Pick<State, K>): void {
        this.setState({ fieldErrors: this.state.fieldErrors.filter((key) => !Object.keys(update).includes(key)) });
        this.setState(update);
    }

    private onCancelClick(e: React.MouseEvent): void {
        e.preventDefault();
        const updatedEvent = { ...this.state.currentEvent!, status: EventStatus.CANCELLED, plannedDate: null };
        this.updateEvent(updatedEvent);
    }

    private onCompleteClick(e: React.MouseEvent): void {
        e.preventDefault();
        const updatedEvent = { ...this.state.currentEvent!, status: EventStatus.COMPLETED };
        this.updateEvent(updatedEvent);
    }

    private onSubmitClick(e: React.MouseEvent): void {
        e.preventDefault();
        const validationResult = this.validateForm();
        if (!validationResult.result) {
            showError(new Error(validationResult.message || i18n.t("common.validationFailed")));
            return;
        }
        this.updateEvent(this.getUpdatedEvent());
    }

    private updateEvent(updatedEvent: Event): void {
        this.setState({ isSubmitting: true });
        const subscription = this.viewModel.updateEvent(updatedEvent).subscribe({
            complete: () => {
                showSuccess("Succesvol ingediend");
                this.props.history.push(Path.admin.participant.profile(this.state.user!.id));
            },
            error: (error) => {
                showError(error);
                this.setState({ isSubmitting: false });
            },
        });
        this.collectSubscription(subscription);
    }

    private getUpdatedEvent(): Event {
        let updates: Partial<Event> = {};
        switch (this.state.currentEvent?.type) {
            case EventType.QUESTIONNAIRE:
                updates = { url: this.state.limeSurveyLink! };
                if (this.isPlanning) {
                    Object.assign(updates, { plannedDate: new Date() });
                }
                break;
            case EventType.TESTLAB:
                const checkupPlannedDate = this.dateFormatter.getDateFromDayMonthYear(
                    `${this.state.checkupDate!} ${this.state.checkupTime!}`,
                );
                updates = { plannedDate: checkupPlannedDate };
                break;
            case EventType.ONLINE_MEETING:
                const plannedDate = this.dateFormatter.getDateFromDayMonthYear(
                    `${this.state.meetingDate!} ${this.state.meetingTime!}`,
                );
                updates = { url: this.state.videoLink, plannedDate };
                break;
            case EventType.ORDER_PILLS:
                const prepOrderDate = this.dateFormatter.getDateFromDayMonthYear(this.state.prepOrderDate!);
                updates = {
                    plannedDate: prepOrderDate,
                    numberOfPills: parseInt(this.state.prepOrderAmount!) * PREP_NUMBER_OF_PILLS_IN_PACKAGE,
                };
                break;
            case EventType.OFFLINE_MEETING:
                const offlineMeetingPlannedDate = this.dateFormatter.getDateFromDayMonthYear(
                    `${this.state.meetingDate!} ${this.state.meetingTime!}`,
                );
                updates = { plannedDate: offlineMeetingPlannedDate };
                break;
            case EventType.DBS:
                const dbsPlannedDate = this.dateFormatter.getDateFromDayMonthYear(this.state.dbsDate!);
                updates = { plannedDate: dbsPlannedDate };
                break;
        }
        if (this.isPlanning) {
            Object.assign(updates, { status: EventStatus.PLANNED });
        }
        return { ...this.state.currentEvent!, ...updates };
    }

    private validateForm(): { result: boolean; message?: string } {
        const fieldErrors: (keyof State)[] = [];
        let errorMessage: string | undefined = undefined;
        switch (this.state.currentEvent?.type) {
            case EventType.QUESTIONNAIRE:
                if (!ValidationPattern.link.test(this.state.limeSurveyLink || "")) {
                    fieldErrors.push("limeSurveyLink");
                }
                break;
            case EventType.TESTLAB:
                if (!ValidationPattern.date.test(this.state.checkupDate || "")) {
                    fieldErrors.push("checkupDate");
                } else {
                    const checkupPlannedDate = this.dateFormatter.getDateFromDayMonthYear(
                        `${this.state.checkupDate!} ${this.state.checkupTime!}`,
                    );
                    if (checkupPlannedDate < new Date()) {
                        fieldErrors.push("checkupDate");
                        fieldErrors.push("checkupTime");
                        errorMessage = "De datum kan niet in het verleden liggen";
                    }
                }
                if (!ValidationPattern.time.test(this.state.checkupTime || "")) {
                    fieldErrors.push("checkupTime");
                }
                break;
            case EventType.OFFLINE_MEETING:
            case EventType.ONLINE_MEETING:
                if (
                    this.state.currentEvent?.type !== EventType.OFFLINE_MEETING && // Don't check it for offline meeting
                    this.state.videoLink &&
                    !ValidationPattern.link.test(this.state.videoLink || "")
                ) {
                    fieldErrors.push("videoLink");
                }
                if (!ValidationPattern.date.test(this.state.meetingDate || "")) {
                    fieldErrors.push("meetingDate");
                }
                if (!ValidationPattern.time.test(this.state.meetingTime || "")) {
                    fieldErrors.push("meetingTime");
                }
                break;
            case EventType.ORDER_PILLS:
                if (!ValidationPattern.date.test(this.state.prepOrderDate || "")) {
                    fieldErrors.push("prepOrderDate");
                }
                if (
                    !ValidationPattern.number.test(this.state.prepOrderAmount || "") ||
                    parseInt(this.state.prepOrderAmount!) < 1
                ) {
                    fieldErrors.push("prepOrderAmount");
                }
                break;
            case EventType.DBS:
                if (!ValidationPattern.date.test(this.state.dbsDate || "")) {
                    fieldErrors.push("dbsDate");
                }
                break;
        }
        this.setState({ fieldErrors });
        return { result: fieldErrors.length === 0, message: errorMessage };
    }

    private getTestlabPrefilledDate(events: Event[], period: string): Date | null {
        // Default date for Testlab is two weeks before chek-up meeting
        const checkupDate = events.find((e) => e.period == period && e.type == EventType.ONLINE_MEETING)?.plannedDate;
        const date = checkupDate && new Date(checkupDate.getTime() - 14 * DAY);
        return date || null;
    }

    private getSubmitButtonTitle(): string {
        if (this.state.currentEvent == null) {
            return "";
        }
        switch (this.state.currentEvent.type) {
            case EventType.QUESTIONNAIRE:
                return this.isPlanning ? "Activeer onderzoeksvragenlijst" : "Wijzigen";
            case EventType.TESTLAB:
            case EventType.ONLINE_MEETING:
            case EventType.DBS:
                return this.isPlanning ? " Inplannen" : " Wijzigen";
            case EventType.ORDER_PILLS:
                return "Voeg PrEP bestelling toe";
            case EventType.OFFLINE_MEETING:
                return this.isPlanning ? "Inplannen" : "Wijzigen";
        }
    }
    private getTitle(): string {
        if (this.state.currentEvent == null) {
            return "";
        }
        switch (this.state.currentEvent.type) {
            case EventType.QUESTIONNAIRE:
                return this.isPlanning ? "Onderzoeksvragenlijst activeren" : "Onderzoeksvragenlijst";
            case EventType.TESTLAB:
            case EventType.ONLINE_MEETING:
                return this.state.currentEvent.description;
            case EventType.OFFLINE_MEETING:
            case EventType.ORDER_PILLS:
            case EventType.DBS:
                return this.state.currentEvent.description + (this.isPlanning ? " inplannen" : "");
        }
    }

    private getDescription(): string {
        if (this.state.currentEvent == null) {
            return "";
        }
        switch (this.state.currentEvent.type) {
            case EventType.TESTLAB:
                return "Wanneer je dit bezoek inpland zal er een link naar testlab gestuurd worden naar de participant";
            case EventType.ONLINE_MEETING:
            case EventType.OFFLINE_MEETING:
            case EventType.QUESTIONNAIRE:
            case EventType.ORDER_PILLS:
            case EventType.DBS:
                return "";
        }
    }
}
