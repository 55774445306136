import { BaseViewModel } from "../BaseViewModel";
import { SessionRepository } from "../../domain/repositories";
import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import { SessionState } from "../../domain/model";
import i18next from "i18next";

export class ToolbarViewModel extends BaseViewModel {
    // Properties

    public get isLoggedIn(): Rx.Observable<boolean> {
        return this.sessionRepository.state.pipe(RxOperators.map((s) => s === SessionState.LoggedIn));
    }

    // Public functions
    public constructor(private readonly sessionRepository: SessionRepository) {
        super();
    }

    public logout(): Rx.Observable<void> {
        return this.sessionRepository.logOut();
    }

    public changeLanguage(lang: string): void {
        i18next.changeLanguage(lang).then(() => {
            window.location.reload();
        });
    }
}
