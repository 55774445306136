import Cookies from "js-cookie";

// To understand the below date, see https://en.wikipedia.org/wiki/Year_2038_problem
const COOKIES_MAX_EXPIRATION_DATE = new Date(2147483647 * 1000);

const COOKIE_CONSENT_KEY = "cookie_consent_approved";

export enum CookieConsent {
    ALL = "all",
    FUNCTIONAL = "functional",
    UNDECIDED = "undecided",
}

export const getCookieConsent = (): CookieConsent => {
    const value = Cookies.get(COOKIE_CONSENT_KEY);
    if (!value) {
        return CookieConsent.UNDECIDED;
    }
    return value as CookieConsent;
};

export const storeCookieConsent = (consent: CookieConsent): string | undefined =>
    Cookies.set(COOKIE_CONSENT_KEY, consent, {
        expires: COOKIES_MAX_EXPIRATION_DATE,
    });
