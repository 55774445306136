import { Modal, ModalProps } from "../appearance/Modal";
import { Message } from "../../domain/model";
import { DateFormatter } from "../../infrastructure/DateFormatter";
import DI from "../../di/DI";
import { TYPES } from "../../di/Types";
import React from "react";
import { RoundButton } from "../appearance/RoundButton";

export interface MessagePopupProps extends ModalProps {
    messageObject: Message;
    onClosed: () => void;
}

export class MessagePopup extends Modal<MessagePopupProps> {
    // Properties

    private readonly dateFormatter: DateFormatter = DI.get(TYPES.DateFormatter);

    // Public functions

    constructor(props: MessagePopupProps) {
        super(props);
        this.state = { showing: false };
    }

    public renderContent(): React.ReactNode {
        return (
            <div style={{ maxWidth: 500 }}>
                <h3>{this.props.messageObject.subject}</h3>
                <p>{this.dateFormatter.formatFullDate(this.props.messageObject.date)}</p>
                <p>{this.props.messageObject.message}</p>
                <RoundButton text={"Sluiten"} onClick={() => this.close()} />
            </div>
        );
    }
}
