import {
    DefaultSessionRepository,
    SessionRepository,
    DefaultLocalStorageRepository,
    LocalStorageRepository,
    UserRepository,
    DefaultUserRepository,
    EventRepository,
    DefaultEventRepository,
    MessageRepository,
    DefaultMessageRepository,
} from "../domain/repositories";

import { API } from "../domain/API";
import { HomeViewModel } from "../components/home/HomeViewModel";
import { LoginViewModel } from "../components/login/LoginViewModel";
import { ToolbarViewModel } from "../components/toolbar/ToolbarViewModel";
import DI from "./DI";
import { TYPES } from "./Types";
import { RESTAPI } from "../infrastructure/api/RESTAPI";
import { ParticipantProfileViewModel } from "../components/participant/profile/ParticipantProfileViewModel";
import { ParticipantEditViewModel } from "../components/participant/edit/ParticipantEditViewModel";
import { NewUserViewModel } from "../components/user/NewUserViewModel";
import { UserProfileViewModel } from "../components/user/UserProfileViewModel";
import { DateFormatter, DefaultDateFormatter } from "../infrastructure/DateFormatter";
import { EventEditViewModel } from "../components/event/EventEditViewModel";
import { PasswordRecoveryViewModel } from "../components/password/PasswordRecoveryViewModel";
import { PrepOrderStartViewModel } from "../components/order/PrepOrderStartViewModel";
import { NewMessageViewModel } from "../components/message/NewMessageViewModel";
import { ApplyForStudyViewModel } from "../components/public/apply/ApplyForStudyViewModel";
import { DefaultOrderRepository, OrderRepository } from "../domain/repositories/OrderRepository";
import { TestLabAuthenticateViewModel } from "../components/testlab/TestLabAuthenticateViewModel";
import { DefaultUIConfigRepository, UIConfigRepository } from "../components/UIConfigRepository";
import { LandingPageViewModel } from "../components/public/landingPage/LandingPageViewModel";
import { ParticipationInfoViewModel } from "../components/public/participationInfo/ParticipationInfoViewModel";
import { LinksFooterViewModel } from "../components/public/content/LinksFooterViewModel";
import { AboutStudyViewModel } from "../components/public/aboutStudy/AboutStudyViewModel";
import { DeleteAccountViewModel } from "../components/deleteAccount/deleteAccountViewModel";

export interface DependencyProvider {
    setup(): void;
}

export class DefaultDependencyProvider implements DependencyProvider {
    // Public functions

    public setup(): void {
        this.setupSingletons();
        this.setupViewModels();
    }

    // Private functions

    private setupSingletons(): void {
        DI.bind<LocalStorageRepository>(TYPES.LocalStorageRepository).toConstantValue(
            new DefaultLocalStorageRepository(),
        );
        DI.bind<DateFormatter>(TYPES.DateFormatter).toConstantValue(new DefaultDateFormatter());
        DI.bind<API>(TYPES.API).toConstantValue(new RESTAPI(DI.get(TYPES.LocalStorageRepository)));
        DI.bind<SessionRepository>(TYPES.SessionRepository).toConstantValue(
            new DefaultSessionRepository(DI.get(TYPES.API)),
        );
        DI.bind<UserRepository>(TYPES.UserRepository).toConstantValue(
            new DefaultUserRepository(DI.get(TYPES.API), DI.get(TYPES.DateFormatter)),
        );
        DI.bind<EventRepository>(TYPES.EventRepository).toConstantValue(new DefaultEventRepository(DI.get(TYPES.API)));
        DI.bind<MessageRepository>(TYPES.MessageRepository).toConstantValue(
            new DefaultMessageRepository(DI.get(TYPES.API)),
        );
        DI.bind<OrderRepository>(TYPES.OrderRepository).toConstantValue(new DefaultOrderRepository(DI.get(TYPES.API)));
        DI.bind<UIConfigRepository>(TYPES.UIConfigRepository).toConstantValue(new DefaultUIConfigRepository());
    }

    private setupViewModels(): void {
        DI.bind<HomeViewModel>(TYPES.HomeViewModel).toDynamicValue(
            () =>
                new HomeViewModel(
                    DI.get<UserRepository>(TYPES.UserRepository),
                    DI.get<EventRepository>(TYPES.EventRepository),
                    DI.get<MessageRepository>(TYPES.MessageRepository),
                ),
        );
        DI.bind<LandingPageViewModel>(TYPES.LandingPageViewModel).toDynamicValue(
            () => new LandingPageViewModel(DI.get<UIConfigRepository>(TYPES.UIConfigRepository)),
        );
        DI.bind<AboutStudyViewModel>(TYPES.AboutStudyViewModel).toDynamicValue(
            () => new AboutStudyViewModel(DI.get<UIConfigRepository>(TYPES.UIConfigRepository)),
        );
        DI.bind<ParticipationInfoViewModel>(TYPES.ParticipationInfoViewModel).toDynamicValue(
            () => new ParticipationInfoViewModel(DI.get<UIConfigRepository>(TYPES.UIConfigRepository)),
        );
        DI.bind<LinksFooterViewModel>(TYPES.LinksFooterViewModel).toDynamicValue(
            () => new LinksFooterViewModel(DI.get<UIConfigRepository>(TYPES.UIConfigRepository)),
        );
        DI.bind<LoginViewModel>(TYPES.LoginViewModel).toDynamicValue(
            () => new LoginViewModel(DI.get<SessionRepository>(TYPES.SessionRepository)),
        );
        DI.bind<DeleteAccountViewModel>(TYPES.DeleteAccountViewModel).toDynamicValue(
            () => new DeleteAccountViewModel(DI.get<SessionRepository>(TYPES.SessionRepository)),
        );
        DI.bind<ToolbarViewModel>(TYPES.ToolbarViewModel).toDynamicValue(
            () => new ToolbarViewModel(DI.get<SessionRepository>(TYPES.SessionRepository)),
        );
        DI.bind<ParticipantProfileViewModel>(TYPES.ParticipantProfileViewModel).toDynamicValue(
            () =>
                new ParticipantProfileViewModel(
                    DI.get<UserRepository>(TYPES.UserRepository),
                    DI.get<EventRepository>(TYPES.EventRepository),
                    DI.get<MessageRepository>(TYPES.MessageRepository),
                ),
        );
        DI.bind<ParticipantEditViewModel>(TYPES.ParticipantEditViewModel).toDynamicValue(
            () =>
                new ParticipantEditViewModel(
                    DI.get<UserRepository>(TYPES.UserRepository),
                    DI.get<EventRepository>(TYPES.EventRepository),
                ),
        );
        DI.bind<NewUserViewModel>(TYPES.NewUserViewModel).toDynamicValue(
            () => new NewUserViewModel(DI.get<UserRepository>(TYPES.UserRepository)),
        );
        DI.bind<UserProfileViewModel>(TYPES.UserProfileViewModel).toDynamicValue(
            () => new UserProfileViewModel(DI.get<UserRepository>(TYPES.UserRepository)),
        );
        DI.bind<EventEditViewModel>(TYPES.EventEditViewModel).toDynamicValue(
            () =>
                new EventEditViewModel(
                    DI.get<UserRepository>(TYPES.UserRepository),
                    DI.get<EventRepository>(TYPES.EventRepository),
                ),
        );
        DI.bind<PasswordRecoveryViewModel>(TYPES.PasswordRecoveryViewModel).toDynamicValue(
            () => new PasswordRecoveryViewModel(DI.get<UserRepository>(TYPES.UserRepository)),
        );
        DI.bind<PrepOrderStartViewModel>(TYPES.PrepOrderStartViewModel).toDynamicValue(
            () =>
                new PrepOrderStartViewModel(
                    DI.get<UserRepository>(TYPES.UserRepository),
                    DI.get<SessionRepository>(TYPES.SessionRepository),
                    DI.get<EventRepository>(TYPES.EventRepository),
                    DI.get<OrderRepository>(TYPES.OrderRepository),
                ),
        );
        DI.bind<NewMessageViewModel>(TYPES.NewMessageViewModel).toDynamicValue(
            () => new NewMessageViewModel(DI.get<MessageRepository>(TYPES.MessageRepository)),
        );
        DI.bind<ApplyForStudyViewModel>(TYPES.ApplyForStudyViewModel).toDynamicValue(
            () => new ApplyForStudyViewModel(DI.get<UserRepository>(TYPES.UserRepository)),
        );
        DI.bind<TestLabAuthenticateViewModel>(TYPES.TestLabAuthenticateViewModel).toDynamicValue(
            () =>
                new TestLabAuthenticateViewModel(
                    DI.get<SessionRepository>(TYPES.SessionRepository),
                    DI.get<UserRepository>(TYPES.UserRepository),
                ),
        );
    }
}
