import React from "react";
import { ContentPageContainer } from "./content/ContentPageContainer";
import i18n from "i18next";
import { HeadlineContainer } from "../appearance/HeadlineContainer";
import { translatedParagraph } from "../appearance/Translated";

export class Disclaimer extends React.Component {
    public render(): React.ReactNode {
        return (
            <ContentPageContainer>
                <HeadlineContainer>
                    <h1>{i18n.t("content.disclaimer.title") as string}</h1>
                    {translatedParagraph("content.disclaimer.description")}
                </HeadlineContainer>
            </ContentPageContainer>
        );
    }
}
