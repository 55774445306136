import React from "react";
import styled from "styled-components";
import { BaseSubscriptionHandlerComponent } from "../BaseSubscriptionHandlerComponent";
import { Colors } from "../appearance/Colors";
import { fullNameOf, RoleId, User, UserStatus } from "../../domain/model";

const Container = styled.div`
    background-color: ${Colors.lightBackground};
    padding: 20px 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin: 10px 0px;
    border-radius: 16px;
    cursor: pointer;
    position: relative;
    h4 {
        font-size: 15px;
        margin: 0;
    }
    p {
        margin: 0;
        font-size: 15px;
    }
    span {
        padding: 4px 8px;
        color: ${Colors.text};
        background-color: #ffc670;
        position: absolute;
        border-radius: 25px;
        top: 16px;
        right: 16px;
    }
    :hover {
        opacity: 0.7;
    }
`;

interface Props {
    user: User;
}

interface State {
    status: string | null;
}

export class UserListRow extends BaseSubscriptionHandlerComponent<Props, State> {
    // Public functions

    public constructor(props: Props) {
        super(props);
        this.state = { status: this.getUserStatus() };
    }

    public render(): React.ReactNode {
        return (
            <Container>
                <h4>{fullNameOf(this.props.user)}</h4>
                <p>{this.props.user.email}</p>
                {this.state.status && <span>{this.state.status}</span>}
            </Container>
        );
    }

    private getUserStatus(): string | null {
        if (this.props.user.status == UserStatus.INACTIVE) {
            if (this.props.user.role.id == RoleId.PARTICIPANT) {
                return "Gestopt";
            } else {
                return "Verwijderd";
            }
        }
        if (this.props.user.role.id == RoleId.ADMIN) {
            return "Admin";
        }
        if (this.props.user.role.id == RoleId.REGION_MANAGER) {
            return "Superuser";
        }
        if (this.props.user.status == UserStatus.REGISTERED) {
            return "Nog niet geactiveerd";
        }

        return null;
    }
}
